import { useEffect, useState } from 'react'
import useFetch from '../../../CustomHooks/useFetch'
import { API_BASE_URL } from '../../../Config/Config'
import { verifyToken } from '../../..'
import CustomLoading from '../../../Components/CustomHandling/LoadingAndError'
import { Slide, toast } from 'react-toastify'
import PageHeaderAndFooter from '../../../Components/PageHeaderAndFooter/PageHeaderAndFooter'
import moment from 'moment'

const DeletedQuotes = () => {
  const showToast = (message, type = 'info', options = {}) => {
    const baseOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Slide,
      ...options,
    }

    switch (type) {
      case 'success':
        toast.success(message, baseOptions)
        break
      case 'warn':
        toast.warn(message, baseOptions)
        break
      case 'error':
        toast.error(message, baseOptions)
        break
      case 'info':
      default:
        toast.info(message, baseOptions)
        break
    }
  }

  const deletedQuotesURL = `${API_BASE_URL}/api/Booking/GetDeletedQuotes`
  const [token, setToken] = useState('')
  const [selectedDeletedQuote, setSelectedDeletedQuote] = useState(null)
  const [refreshDependency, setRefreshDependency] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [revertState, setRevertState] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const {
    data: deletedQuotes,
    loading,
    error,
  } = useFetch(deletedQuotesURL, refreshDependency)
  console.log(selectedDeletedQuote)

  const handleRevertQuote = async (e) => {
    e.preventDefault()

    const url = `${API_BASE_URL}/api/booking/RevertQuote/${selectedDeletedQuote}`

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })

      if (response.ok) {
        showToast('Quote Reverted successfully', 'success')

        // Refresh data
        setRefreshDependency((prev) => prev + 1)
        setSelectedDeletedQuote(null)
        setRevertState(false)
        setIsModalOpen(false)
      } else {
        showToast('Failed to Revert Quote.', 'warn')
      }
    } catch (error) {
      showToast(`Error reverting Quote: ${error.message}`, 'error')
    }
  }

  const handleOpenModal = (actionType, quoteID) => {
    setRevertState(false)

    if (actionType === 'revert') {
      setSelectedDeletedQuote(quoteID)
      setRevertState(true)
      setIsModalOpen(true)
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setRevertState(false)
  }

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]))
  }

  if (loading) return <CustomLoading />
  if (error) return <div>Error: {error}</div>

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_add={false}
        data={deletedQuotes}
        filterFields={['deletedQuoteCode', 'companyName']}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Customer Email</th>
                  <th scope="col">Created Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <>
                    {/* Main Row */}
                    <tr
                      key={data.quoteID}
                      className={`${
                        expandedRows.includes(index) ? 'table-info' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{startIndex + index + 1}</td>
                      <td>{data.fullName || 'N/A'}</td>
                      <td>{data.phoneNumber || 'N/A'}</td>
                      <td>{data.customerEmail || 'N/A'}</td>
                      <td>
                        {moment(data.createdDate).format(
                          'DD MMMM YYYY, HH:mm:ss A'
                        ) || 'N/A'}
                      </td>
                    </tr>

                    {/* Expandable Row */}
                    {expandedRows.includes(index) && (
                      <tr className="bg-light">
                        <td colSpan="9">
                          <div className="row g-2 p-3">
                            <div className="col-md-6">
                              <strong>Total Cost:</strong> £
                              {data.totalCost || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Flying From:</strong>{' '}
                              {data.flyingFrom || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Flying To:</strong>{' '}
                              {data.flyingTo || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Inbound Terminal:</strong>{' '}
                              {data.inBoundTerminalName || 'N/A'} /
                              {data.inBoundTerminalCost || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Outbound Terminal:</strong>{' '}
                              {data.outBoundTerminalName || 'N/A'} /
                              {data.outBoundTerminalCost || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Airport Name:</strong>{' '}
                              {data.airportName || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Product Name:</strong>{' '}
                              {data.productName || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Product Price:</strong> £
                              {data.productPrice || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Discount Code:</strong>{' '}
                              {data.discountCode || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Car Clean:</strong>{' '}
                              {data.carCleanName || 'N/A'} - £
                              {data.carCleanPrice || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>eCharge:</strong>{' '}
                              {data.eChargeName || 'N/A'} - £
                              {data.eChargePrice || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Vehicle Make:</strong>{' '}
                              {data.vehicleMakeName ||
                                data.vehicleMakeOther ||
                                'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Vehicle Model:</strong>{' '}
                              {data.vehicleModelName ||
                                data.vehicleModelOther ||
                                'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Vehicle Color:</strong>{' '}
                              {data.vehicleColorName ||
                                data.vehicleColorOther ||
                                'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Registration Number:</strong>{' '}
                              {data.registrationNumber || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>Start Date:</strong>{' '}
                              {moment(data.startDateTime).format(
                                'DD MMMM YYYY, hh:mm A'
                              ) || 'N/A'}
                            </div>
                            <div className="col-md-6">
                              <strong>End Date:</strong>{' '}
                              {moment(data.endDateTime).format(
                                'DD MMMM YYYY, hh:mm A'
                              ) || 'N/A'}
                            </div>
                            <div className="d-flex gap-2 mt-2">
                              <button
                                className="btn btn_modal_sec"
                                onClick={() =>
                                  handleOpenModal('revert', data.quoteID)
                                }
                              >
                                Revert Quote
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? 'show' : ''}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? 'block' : 'none',
              background: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={handleCloseModal}
          >
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {/* {userID ? 'Update deletedQuote name' : 'Create deletedQuote name'} */}
                    {revertState ? 'Delete' : ''} User
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleCloseModal}
                  />
                </div>
                <div className="modal-body row">
                  <div className="mb-3 ">Do you want to revert this Quote?</div>
                </div>
                <div className="modal-footer">
                  <button
                    className={revertState ? 'btn btn_modal' : ''}
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                  <button
                    className={revertState ? 'btn btn_modal_sec' : ''}
                    type="button"
                    onClick={handleRevertQuote}
                  >
                    {revertState ? 'Delete' : ''}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default DeletedQuotes
