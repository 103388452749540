import React from 'react'
import './LoadingAndError.css'

export default function CustomLoading({ text = true, data = 'Fetching' }) {
  return (
    <>
      <div className="loading-container">
        <div className="spinner"></div>
        {text && <h1>{data}</h1>}
      </div>
    </>
  )
}

export function CustomModalLoading({ data }) {
  return (
    <>
      <div className="modal-loading-container">
        <div className="modal-spinner"></div>
        <h1>Fetching {data}</h1>
      </div>
    </>
  )
}

export function Error() {
  return (
    <>
      <div className="error-message">
        <h1>Error...</h1>
      </div>
    </>
  )
}
export const LoginLoader = () => {
  return (
    <div className="modal-overlay">
      {/* <div className="modal"> */}
      <div className="spinner"></div>
      {/* </div> */}
    </div>
  )
}
