import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../Components/Navbar/Logo";
import Back from "./Back";
import NavbarLink from "../Components/NabarLink/NavbarLink";

function GarargeNavbar() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 p-0">
          <>
            <div
              id="bdSidebar"
              className="for-width sidebar-bg-color d-flex flex-column text-white offcanvas-md offcanvas-start"
            >
              <Logo />
              <ul className="mynav nav nav-pills flex-column mb-auto p-3">
                {/* Go Back Link */}
                <NavbarLink
                  name="Go Back"
                  logo={<i className="fa-solid fa-arrow-left"></i>}
                  to="/"
                />
                {/* Active Cars */}
                <NavbarLink
                  name="Active Cars"
                  logo={<i className="fa-solid fa-car"></i>}
                  to="/garage/active-cars"
                />
                {/* Completed Cars */}
                <NavbarLink
                  name="Completed Cars"
                  logo={<i className="fa-solid fa-list"></i>}
                  to="/garage/completed-cars"
                />
                {/* Delayed Cars */}
                <NavbarLink
                  name="Delayed Cars"
                  logo={<i className="fa-solid fa-asterisk"></i>}
                  to="/garage/delayed-cars"
                />
                {/* Yards */}
                <NavbarLink
                  name="Yards"
                  logo={<i className="fa-solid fa-warehouse"></i>}
                  to="/garage/yards"
                />
              </ul>

              <hr />
              <div className="Privacy-terms d-flex align-items-center p-3">
                <i className="fa-solid fa-shield-halved me-2"></i>
                <p className="mb-0">
                  {/* <a href="#" className="text-white text-decoration-none">
              Terms of Service
            </a> */}
                  <NavLink
                    to="news-letter/terms-of-services"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    {" "}
                    - Terms Of Services
                  </NavLink>
                  {/* <a href="#" className="text-white text-decoration-none">
              - Privacy Policy
            </a> */}
                  {/* <li> */}
                  <NavLink
                    to="news-letter/privacy-policy"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    - Privacy Policy
                  </NavLink>
                  {/* </li> */}
                </p>
              </div>
            </div>

            <div className="right-bg-contant">
              <div className="p-2 d-md-none d-flex text-white sidebar-bg-color forsmallscreen">
                <a
                  href="#"
                  className="text-white"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#bdSidebar"
                >
                  <i className="fa-solid fa-bars"></i>
                </a>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default GarargeNavbar;
