import React from 'react'
import './Navbar.css'
import { NavLink } from 'react-router-dom'
import Logo from './Logo.jsx'

const Navbar = () => {
  return (
    <>
      <div
        id="bdSidebar"
        className="for-width sidebar-bg-color d-flex flex-column text-white offcanvas-md offcanvas-start"
      >
        <Logo />

        <ul className="mynav nav nav-pills flex-column mb-auto p-3">
          <li className="nav-item">
            <NavLink to="/" className="btn-3 text-decoration-none Active">
              <i className="fa-solid fa-gauge"></i>Dashboards
            </NavLink>
          </li>

          <li className="nav-item pt-2">
            <div className="row d-block">
              <NavLink
                to={'/bookings/quotes'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-regular fa-calendar-check"></i>Bookings
                </div>
              </NavLink>
            </div>
          </li>

          <li className="nav-item ">
            <div className="row d-block">
              <NavLink
                to={'/customer-bookings/quotes'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i class="fa-solid fa-user-tag"></i>Customer Bookings
                </div>
              </NavLink>
            </div>
          </li>

          <li className="nav-item ">
            <div className="row d-block">
              <NavLink
                to={'/driver-bookings/driver-received-bookings'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i class="fa-solid fa-person-military-pointing fa-flip-horizontal"></i>
                  Driver Bookings
                </div>
              </NavLink>
            </div>
          </li>

          <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/admin-portal/booking-slots'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-solid fa-address-book"></i>Admin Portal
                </div>
              </NavLink>
            </div>
          </li>

          <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/garage/active-cars'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-solid fa-car"></i>Garage
                </div>
              </NavLink>
            </div>
          </li>

          {/* <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/communication-tab/inbox'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-regular fa-message"></i>Communication Tab
                </div>
              </NavLink>
            </div>
          </li> */}

          <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/user-management/active-users'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-solid fa-user"></i>User Management
                </div>
              </NavLink>
            </div>
          </li>

          <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/airport/airports'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-solid fa-plane-up"></i>Airport
                </div>
              </NavLink>
            </div>
          </li>

          <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/vehicle-configuration/vehicle-make'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-solid fa-screwdriver-wrench"></i>
                  Vehicle Configuration
                </div>
              </NavLink>
            </div>
          </li>

          {/* <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/services'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-solid fa-toolbox"></i>Services
                </div>
              </NavLink>
            </div>
          </li> */}

          {/* <li className="nav-item">
            <div className="row d-block">
              <NavLink
                to={'/'}
                className="btn-3 btn-toggle d-inline-flex align-items-center rounded border-0 text-decoration-none"
              >
                <div className="col-lg-12 col-md-12 col-12">
                  <i className="fa-regular fa-credit-card"></i>Billing Method
                </div>
              </NavLink>
            </div>
          </li> */}
        </ul>

        <hr />

        <div className="Privacy-terms d-flex align-items-center p-3">
          <i className="fa-solid fa-shield-halved me-2"></i>
          <p className="mb-0">
            {/* <a href="#" className="text-white text-decoration-none">
              Terms of Service
            </a> */}
            <NavLink
              to="news-letter/terms-of-services"
              className="link-body-emphasis d-inline-flex text-decoration-none rounded"
            >
              {({ isActive }) => (
                <span className={isActive ? 'active-navlink' : ''}>
                  {' '}
                  - Terms Of Services
                </span>
              )}
            </NavLink>
            {/* <a href="#" className="text-white text-decoration-none">
              - Privacy Policy
            </a> */}
            {/* <li> */}
            <NavLink
              to="news-letter/privacy-policy"
              className="link-body-emphasis d-inline-flex text-decoration-none rounded"
            >
              {({ isActive }) => (
                <span className={isActive ? 'active-navlink' : ''}>
                  {' '}
                  - Privacy Policy
                </span>
              )}
            </NavLink>
            {/* </li> */}
          </p>
        </div>
      </div>

      <div className="right-bg-contant">
        <div className="p-2 d-md-none d-flex text-white sidebar-bg-color forsmallscreen">
          <a
            href="#"
            className="text-white"
            data-bs-toggle="offcanvas"
            data-bs-target="#bdSidebar"
          >
            <i className="fa-solid fa-bars"></i>
          </a>
        </div>
      </div>
    </>
  )
}

export default Navbar
