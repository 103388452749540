import { useEffect, useState } from 'react'
import useFetch from '../../../CustomHooks/useFetch'
import { API_BASE_URL, AUTH_TOKEN } from '../../../Config/Config'
import { verifyToken } from '../../..'
import CustomLoading, {
  CustomModalLoading,
} from '../../../Components/CustomHandling/LoadingAndError'
import { Slide, toast } from 'react-toastify'
import PageHeaderAndFooter from '../../../Components/PageHeaderAndFooter/PageHeaderAndFooter'

const LandUnBooks = () => {
  const showToast = (message, type = 'info', options = {}) => {
    const baseOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: 'light',
      transition: Slide,
      ...options,
    }

    switch (type) {
      case 'success':
        toast.success(message, baseOptions)
        break
      case 'warn':
        toast.warn(message, baseOptions)
        break
      case 'error':
        toast.error(message, baseOptions)
        break
      case 'info':
      default:
        toast.info(message, baseOptions)
        break
    }
  }
  //

  const landUnBooksURL = `${API_BASE_URL}/api/booking/GetDispatchingUnAssignedBookings`
  const driversURL = `${API_BASE_URL}/api/user/GetUserByRoleName/Driver`
  const [token, setToken] = useState('')
  const [userData, setUserData] = useState({
    parkingEnd: '',
    bookingID: null,
    inboundTerminalID: '',
    outboundTerminalID: '',
    outboundFlightNo: '',
  })
  const [refreshDependency, setRefreshDependency] = useState(0)
  const [inboundTerminals, setInboundTerminals] = useState([])
  const [outboundTerminals, setOutboundTerminals] = useState([])
  const [modalLoading, setModalLoading] = useState(false)
  const [driverID, setDriverID] = useState()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editState, setEditState] = useState(false)
  const [assignState, setAssignState] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const {
    data: landUnBooks,
    loading,
    error,
  } = useFetch(landUnBooksURL, refreshDependency)

  const { data: drivers } = useFetch(driversURL, refreshDependency)

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleEditLandUnBook = async (e) => {
    e.preventDefault()

    const updateLandUnBook = {
      // usersID: userData.bookingID || null,
      endDateTime: userData.parkingEnd || null,
      inboundTerminalID: userData.inboundTerminalID || null,
      outboundTerminalID: userData.outboundTerminalID || null,
      // outboundFlightNo: userData.outboundFlightNo || null,
    }

    console.log(updateLandUnBook)

    const url = `${API_BASE_URL}/api/dispatchedAssignedBooking/Update/${userData.bookingID}`

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(updateLandUnBook),
      })

      const result = await response.json()

      if (response.ok) {
        showToast('User successfully updated.', 'success')

        // Reset states and refresh
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setEditState(false)
      } else {
        // Warning toasts for errors
        Object.keys(result.errors).forEach((key) => {
          result.errors[key].forEach((message) => {
            showToast(`${key}: ${message}`, 'warn')
          })
        })
      }
    } catch (error) {
      // Error toast for network issues or unexpected errors
      showToast(`${error.message}`, 'error')
    }
  }

  const handleAssignLandUnBook = async (e) => {
    e.preventDefault()

    const url = `${API_BASE_URL}/api/Booking/AssignDispatchingDriver`
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          DriverID: driverID,
          BookingID: userData.bookingID,
          CreatedDate: null,
        }),
      })

      const updateLandUnBook = await response.json()
      if (response.ok) {
        const action = assignState ? 'assigned' : 'blocked'
        showToast(
          `Driver '${updateLandUnBook.customerName}' ${action}`,
          'success'
        )
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setAssignState(false)
        setExpandedRows([])
      } else {
        showToast('Failed to Assign Driver.', 'warn')
      }
    } catch (error) {
      showToast(`${error.message}`, 'error')
    }
  }

  const fetchTerminalsData = async (airportID) => {
    setModalLoading(true)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/web/GetAllbyAirPort/${airportID}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: AUTH_TOKEN,
          },
        }
      )

      if (response.ok) {
        const data = await response.json()
        setInboundTerminals(data.filter((t) => t.terminalType === 'InBound'))
        setOutboundTerminals(data.filter((t) => t.terminalType === 'OutBound'))
      } else {
        showToast('Failed to fetch terminals', 'warn')
      }
    } catch (error) {
      showToast('Error fetching terminals', 'error')
    } finally {
      setModalLoading(false)
    }
  }

  const populateAndOpenModal = (actionType, bookingID) => {
    const selectedData = landUnBooks.find((mod) => mod.bookingID === bookingID)
    console.log(selectedData)

    if (!selectedData) return

    setUserData({
      parkingEnd: selectedData.endDateTime,
      bookingID: selectedData.bookingID,
      inboundTerminalID: selectedData.inboundTerminalID,
      outboundTerminalID: selectedData.outboundTerminalID,
      outboundFlightNo: selectedData.outboundFlightNo,
    })
    if (actionType === 'edit') {
      fetchTerminalsData(selectedData.airportID)
      setEditState(actionType === 'edit')
    }
    setAssignState(actionType === 'assign')
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setEditState(false)
    setAssignState(false)
  }

  const handleTerminalChange = (e, type) => {
    const selectedId = parseInt(e.target.value) // Ensure it's a number
    const terminalList =
      type === 'outbound' ? outboundTerminals : inboundTerminals

    const selectedTerminal = terminalList.find(
      (terminal) => terminal.terminalID === selectedId
    )

    setUserData((prevData) => ({
      ...prevData,
      [`${type}TerminalID`]: selectedTerminal?.terminalID || '',
    }))
  }

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]))
  }

  if (loading) return <CustomLoading />
  if (error) return <div>Error: {error}</div>

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New User"
        btn_add={false}
        data={landUnBooks}
        filterFields={['airportName']}
        itemsPerPage={15}
        renderContent={(paginatedData) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Airport Name</th>
                  <th scope="col">Start Date Time</th>
                  <th scope="col">End Date Time</th>
                  <th scope="col">Quote Code</th>
                  <th scope="col">Outbound Terminal Name</th>
                  <th scope="col">Inbound Terminal Name</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <>
                    <tr
                      key={data.bookingID}
                      className={`${
                        expandedRows.includes(index) ? 'table-info' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{index + 1}</td>
                      <td>{data.airportName || 'N/A'}</td>
                      <td>{data.startDateTime || 'N/A'}</td>
                      <td>{data.endDateTime || 'N/A'}</td>
                      <td>{data.quoteCode || 'N/A'}</td>
                      <td>{data.outboundTerminalName || 'N/A'}</td>
                      <td>{data.inboundTerminalName || 'N/A'}</td>
                      <td>{data.customerName || 'N/A'}</td>
                      <td>{data.customerEmail || 'N/A'}</td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr className="bg-light">
                        <td colSpan="9">
                          <div className="row g-2 p-3">
                            <div className="col-md-4">
                              <strong>Total Cost: </strong>{' '}
                              {data.totalCost || 'N/A'}
                            </div>
                            <div className="col-md-4">
                              <strong>Phone No: </strong>{' '}
                              {data.phoneNumber || 'N/A'}
                            </div>{' '}
                            <div className="col-md-4">
                              <strong>Company Name: </strong>{' '}
                              {data.companyName || 'N/A'}
                            </div>{' '}
                            <div className="col-md-4">
                              <strong>Promotions: </strong>{' '}
                              {data.isPromotions ? 'yes' : 'no'}
                            </div>{' '}
                            <div className="col-md-4">
                              <strong>Flying From: </strong>{' '}
                              {data.flyingFrom || 'N/A'}
                            </div>
                            <div className="col-md-4">
                              <strong> Flying To: </strong>{' '}
                              {data.flyingTo || 'N/A'}
                            </div>
                            <div className="col-md-4">
                              <strong>Payment Status: </strong>{' '}
                              {data.paymentStatus || 'N/A'}
                            </div>
                            <div className="col-md-4">
                              <strong>Outbound Flight No: </strong>{' '}
                              {data.outboundFlightNo || 'N/A'}
                            </div>
                            <div className="col-md-4">
                              <strong>Inbound Flight No : </strong>{' '}
                              {data.inboundFlightNo || 'N/A'}
                            </div>{' '}
                            <div className="col-md-4">
                              <strong>Product Price: </strong>{' '}
                              {data.productPrice || 'N/A'}
                            </div>
                          </div>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn_modal"
                              onClick={() =>
                                populateAndOpenModal('edit', data.bookingID)
                              }
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn_modal_sec"
                              onClick={() =>
                                populateAndOpenModal('assign', data.bookingID)
                              }
                            >
                              Assign
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? 'show' : ''}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? 'block' : 'none',
              background: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={handleCloseModal}
          >
            {modalLoading ? <CustomModalLoading /> : ''}
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  editState
                    ? handleEditLandUnBook
                    : assignState
                    ? handleAssignLandUnBook
                    : ''
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {/* {bookingID ? 'Update landUnBook name' : 'Create landUnBook name'} */}
                      {editState
                        ? 'Update Booking'
                        : assignState
                        ? 'Assign Driver'
                        : ''}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body row">
                    {editState ? (
                      <>
                        <div className="mb-3 col-lg-6">
                          <label htmlFor="parkingEnd" className="form-label">
                            Parking End Date
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            name="parkingEnd"
                            value={userData.parkingEnd}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-3 col-lg-6">
                          <label
                            htmlFor="outboundTerminalID"
                            className="form-label"
                          >
                            Outbound Flight No
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="outboundFlightNo"
                            value={userData.outboundFlightNo}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-3 col-lg-6">
                          <label
                            htmlFor="inboundTerminalID"
                            className="form-label"
                          >
                            Inbound Terminals
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) => handleTerminalChange(e, 'inbound')} // Pass the type
                            value={userData.inboundTerminalID || ''} // Use the appropriate key
                          >
                            <option value="" disabled>
                              Inbound Terminal
                            </option>
                            {inboundTerminals?.map((terminal) => (
                              <option
                                key={terminal.terminalID}
                                value={terminal.terminalID}
                              >
                                {terminal.terminalName}{' '}
                                {terminal.terminalCost &&
                                  '£' + terminal.terminalCost}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3 col-lg-6">
                          <label
                            htmlFor="outboundTerminalID"
                            className="form-label"
                          >
                            Outbound Terminals
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) =>
                              handleTerminalChange(e, 'outbound')
                            } // Pass the type
                            value={userData.outboundTerminalID || ''} // Use the appropriate key
                          >
                            <option value="" disabled>
                              Outbound Terminal
                            </option>
                            {outboundTerminals?.map((terminal) => (
                              <option
                                key={terminal.terminalID}
                                value={terminal.terminalID}
                              >
                                {terminal.terminalName}{' '}
                                {terminal.terminalCost &&
                                  '£' + terminal.terminalCost}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : (
                      <div className="mb-3 ">
                        <label
                          htmlFor="outboundTerminalID"
                          className="form-label"
                        >
                          Select Driver
                        </label>
                        <select
                          name="faqPerPage"
                          onChange={(e) => setDriverID(e.target.value)}
                          className=" form-control"
                        >
                          <option selected disabled>
                            Select Driver
                          </option>
                          {drivers.map((driver) => (
                            <>
                              <option key={driver.userID} value={driver.userID}>
                                {driver.firstName} {driver.lastName}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        editState
                          ? 'btn btn_modal_sec'
                          : assignState
                          ? 'btn btn_modal'
                          : ''
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        editState
                          ? 'btn btn_modal'
                          : assignState
                          ? 'btn btn_modal_sec'
                          : ''
                      }
                      type="submit"
                    >
                      {editState ? 'Update' : assignState ? 'Assign' : ''}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default LandUnBooks
