import { useState, useRef, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import './DriverReceivingSlip.css'
import './DriverReceivingSlip2.css'
import { API_BASE_URL } from '../../../Config/Config'
import { useNavigate, useParams } from 'react-router-dom'
import { Slide, toast } from 'react-toastify'

import { verifyToken } from '../../..'
import CustomLoading from '../../../Components/CustomHandling/LoadingAndError'

const DriverReceivingSlip = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const showToast = (message, type = 'info', options = {}) => {
    const baseOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: 'light',
      transition: Slide,
      ...options,
    }

    switch (type) {
      case 'success':
        toast.success(message, baseOptions)
        break
      case 'warn':
        toast.warn(message, baseOptions)
        break
      case 'error':
        toast.error(message, baseOptions)
        break
      case 'info':
      default:
        toast.info(message, baseOptions)
        break
    }
  }
  const { bookingID } = useParams()
  const sigCanvasRef = useRef({
    first: null,
    second: null,
    third: null,
  })

  const [token, setToken] = useState('')
  const [formData, setFormData] = useState({
    BookingID: '',
    SSCP: '',
    Entry: '',
    Exit: '',
    AllBelongingsRemoved: null,
    Mileage: '',
    BatteryPercentage: '',
    FuelPercentage: '',
    CarOverallCondition: null,
    InteriorCondition: null,
    ExteriorCondition: null,
    Dicky: '',
    Bumper: '',
    Roof: '',
    LeftFrontDoor: '',
    RightFrontDoor: '',
    LeftBackDoor: '',
    RightBackDoor: '',
    FrontLeftTyre: '',
    FrontRightTyre: '',
    BackLeftTyre: '',
    BackRightTyre: '',
    Bonut: '',
    IAgreeTerms: null,
    Notes: '',
    ActualMeetTime: '',
    MetBy: '',
    firstSignature: '',
    secondSignature: '',
    thirdSignature: '',
  })

  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleSignature = (slotKey) => {
    const signatureCanvas = sigCanvasRef.current[slotKey]

    if (signatureCanvas) {
      const signatureData = signatureCanvas
        .getTrimmedCanvas()
        .toDataURL('image/png')

      // Save the signature in the form data
      setFormData((prev) => ({
        ...prev,
        [`${slotKey}Signature`]: signatureData,
      }))

      return signatureData // Return the signature data
    } else {
      console.log(`No canvas found for ${slotKey}`)
      return null // Return null if no canvas exists
    }
  }

  // Clear specific signature by slot key
  const handleClearSignature = (key) => {
    const signatureCanvas = sigCanvasRef.current[key]
    signatureCanvas.clear()
    setFormData((prev) => ({
      ...prev,
      [`${key}Signature`]: null,
    }))
  }

  const handleSubmit = async () => {
    // Get the signature data for each slot
    setLoading(true)
    const first = handleSignature('first')
    const second = handleSignature('second')
    const third = handleSignature('third')

    const response = await fetch(
      `${API_BASE_URL}/api/driver/AddReceivingVehicleDetail`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token, // Fix typo here (Authorization instead of Authrorization)
        },
        body: JSON.stringify({
          BookingID: bookingID,
          SSCP: formData.SSCP,
          Entry: formData.Entry,
          Exit: formData.Exit,
          Initials: first ? first : '', // Default to empty string if no signature
          AllBelongingsRemoved: formData.AllBelongingsRemoved,
          Mileage: formData.Mileage,
          BatteryPercentage: formData.BatteryPercentage,
          FuelPercentage: formData.FuelPercentage,
          CarOverallCondition: formData.CarOverallCondition,
          InteriorCondition: formData.InteriorCondition,
          ExteriorCondition: formData.ExteriorCondition,
          Dicky: formData.Dicky,
          Bumper: formData.Bumper,
          Roof: formData.Roof,
          LeftFrontDoor: formData.LeftFrontDoor,
          RightFrontDoor: formData.RightFrontDoor,
          LeftBackDoor: formData.LeftBackDoor,
          RightBackDoor: formData.RightBackDoor,
          FrontLeftTyre: formData.FrontLeftTyre,
          FrontRightTyre: formData.FrontRightTyre,
          BackLeftTyre: formData.BackLeftTyre,
          BackRightTyre: formData.BackRightTyre,
          Bonut: formData.Bonut,
          IAgreeTerms: formData.IAgreeTerms,
          Signature: second ? second : '', // Default to empty string if no signature
          Notes: formData.Notes,
          SecondInitials: third ? third : '', // Default to empty string if no signature
          ActualMeetTime: formData.ActualMeetTime,
          MetBy: formData.MetBy,
        }),
      }
    )

    const result = await response.json()
    if (response.ok) {
      setLoading(false)
      navigate('/bookings/meet-assigned-bookings')
      console.log(result)
    } else {
      Object.keys(result.errors).forEach((key) => {
        result.errors[key].forEach((message) => {
          showToast(`${key}: ${message}`, 'warn')
        })
      })
      setLoading(false)
    }
  }
  if (loading) return <CustomLoading text={false} />

  return (
    <>
      <div className="main-container-detail">
        <div className="main-detail-option">
          <div className="detail-option-container">
            <div className="detail-options-section">
              <div className="detail-option">
                <label className="detail-option-label">
                  Met By: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="MetBy"
                  value={formData.MetBy}
                  onChange={(e) => handleChange(e)}
                  type="datetime-local"
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Actual Meet Time: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="ActualMeetTime"
                  type="datetime-local"
                  value={formData.ActualMeetTime}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  SSCP: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="SSCP"
                  type="text"
                  value={formData.SSCP}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Entry: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="Entry"
                  type="datetime-local"
                  value={formData.Entry}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Exit: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="Exit"
                  type="datetime-local"
                  value={formData.Exit}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="detail-option">
                <label className="detail-option-label">
                  All Personal Items Removed:{' '}
                  <input
                    className="initials"
                    type="checkbox"
                    name="AllBelongingsRemoved"
                    checked={formData.AllBelongingsRemoved}
                    onChange={(e) => handleChange(e)}
                  />
                </label>
              </div>
              <div className="detail-option d-block">
                <label className="detail-option-label">
                  Initials: <span className="booking-required"> *</span>
                </label>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    className: 'sigCanvas h-100 w-100',
                  }}
                  ref={(el) => (sigCanvasRef.current.first = el)}
                />
                <button
                  className="btn btn_modal d-block"
                  onClick={() => handleClearSignature('first')}
                >
                  Clear Signature
                </button>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Mileage: <span className="booking-required">*</span>
                </label>
                <input
                  className="detail-option-select"
                  name="Mileage"
                  type="text"
                  value={formData.Mileage}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Front Left Tire:</label>
                <select
                  className="detail-option-select"
                  name="FrontLeftTyre"
                  value={formData.FrontLeftTyre}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
            </div>
            <div className="detail-options-section">
              <div className="detail-option">
                <label className="detail-option-label">Front Left Door:</label>
                <select
                  className="detail-option-select"
                  name="LeftFrontDoor"
                  value={formData.LeftFrontDoor}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Back Left Tire:</label>
                <select
                  className="detail-option-select"
                  name="BackLeftTyre"
                  value={formData.BackLeftTyre}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Back Left Door:</label>
                <select
                  className="detail-option-select"
                  name="LeftBackDoor"
                  value={formData.LeftBackDoor}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>

              <div className="detail-option">
                <label className="detail-option-label">Front Bonnet:</label>
                <select
                  className="detail-option-select"
                  name="Bonut"
                  value={formData.Bonut}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Front Bumper:</label>
                <select
                  className="detail-option-select"
                  name="Bumper"
                  value={formData.Bumper}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Back Right Tire:</label>
                <select
                  className="detail-option-select"
                  name="BackRightTyre"
                  value={formData.BackRightTyre}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Back Right Door:</label>
                <select
                  className="detail-option-select"
                  name="RightBackDoor"
                  value={formData.RightBackDoor}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">The Roof:</label>
                <select
                  className="detail-option-select"
                  name="Roof"
                  value={formData.Roof}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Back Side Boot:</label>
                <select
                  className="detail-option-select"
                  name="Dicky"
                  value={formData.Dicky}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Back Rear Bumper:</label>
                <select
                  className="detail-option-select"
                  name="BackRearBumper"
                  value={formData.BackRearBumper}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Right Front Tire:</label>
                <select
                  className="detail-option-select"
                  name="FrontRightTyre"
                  value={formData.FrontRightTyre}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  <option value="scratch">Scratch</option>
                  <option value="dent">Dent</option>
                  <option value="broken">Broken</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">Battery Level:</label>
                <select
                  className="detail-option-select"
                  name="BatteryPercentage"
                  value={formData.BatteryPercentage}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  {[...Array(11).keys()].map((i) => (
                    <option key={i} value={i * 10}>
                      {i * 10}%
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="detail-options-section">
              <div className="detail-option">
                <label className="detail-option-label">Petrol Level:</label>
                <select
                  className="detail-option-select"
                  name="FuelPercentage"
                  value={formData.FuelPercentage}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">None</option>
                  {[...Array(11).keys()].map((i) => (
                    <option key={i} value={i * 10}>
                      {i * 10}%
                    </option>
                  ))}
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Over All Condition:
                </label>
                <select
                  className="detail-option-select"
                  name="CarOverallCondition"
                  value={formData.CarOverallCondition}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" disabled>
                    Select an Option
                  </option>
                  <option value="none">none</option>

                  <option value="Poor">Poor</option>
                  <option value="Good">Good</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Clean">Clean</option>
                  <option value="Dirty">Dirty</option>
                  <option value="Very Dirty">Very Dirty</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Exterior Condition:
                </label>
                <select
                  className="detail-option-select"
                  name="ExteriorCondition"
                  value={formData.ExteriorCondition}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" disabled>
                    Select an Option
                  </option>
                  <option value="none">none</option>
                  <option value="Poor">Poor</option>
                  <option value="Good">Good</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Clean">Clean</option>
                  <option value="Dirty">Dirty</option>
                  <option value="Very Dirty">Very Dirty</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Interior Condition:
                </label>
                <select
                  className="detail-option-select"
                  name="InteriorCondition"
                  value={formData.InteriorCondition}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" disabled>
                    Select an Option
                  </option>
                  <option value="none">none</option>

                  <option value="Poor">Poor</option>
                  <option value="Good">Good</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Clean">Clean</option>
                  <option value="Dirty">Dirty</option>
                  <option value="Very Dirty">Very Dirty</option>
                </select>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Agree On Terms:{' '}
                  <input
                    className="initials"
                    type="checkbox"
                    name="IAgreeTerms"
                    checked={formData.IAgreeTerms}
                    onChange={(e) => handleChange(e)}
                  />
                </label>
              </div>
              <div className="detail-option d-block">
                <label className="detail-option-label">
                  Initials: <span className="booking-required"> *</span>
                </label>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    className: 'sigCanvas h-100 w-100',
                  }}
                  ref={(el) => (sigCanvasRef.current.second = el)}
                />
                <button
                  className="btn btn_modal d-block"
                  onClick={() => handleClearSignature('second')}
                >
                  Clear Signature
                </button>
              </div>
              <div className="detail-option">
                <label className="detail-option-label">
                  Notes: <span className="booking-required"> *</span>
                </label>
                <textarea
                  name="Notes"
                  className="detail-option-select"
                  value={formData.Notes}
                  onChange={(e) => handleChange(e)}
                ></textarea>
              </div>
              <div className="detail-option d-block">
                <label className="detail-option-label">
                  Customer Sign: <span className="booking-required"> *</span>
                </label>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    className: 'sigCanvas h-100 w-100',
                  }}
                  ref={(el) => (sigCanvasRef.current.third = el)}
                />
                <button
                  className="btn btn_modal d-block"
                  onClick={() => handleClearSignature('third')}
                >
                  Clear Signature
                </button>
              </div>
            </div>
          </div>
          <button className="btn btn_modal d-block" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  )
}

export default DriverReceivingSlip
