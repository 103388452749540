// import React, { useEffect, useState } from 'react'
// import { useLoaderData } from 'react-router-dom'
// import { toast, Slide } from 'react-toastify'
// import { API_BASE_URL } from '../../../Config/Config'
// import { verifyToken } from '../../../index.js'
// import useFetch from '../../../CustomHooks/useFetch'
// function BookingSlots() {
//   const [token, setToken] = useState('')
//   const apiURLBookingSlots = `${API_BASE_URL}/api/BookingPrice/GetAllBookingPrices`

//   useEffect(() => {
//     const fetchToken = async () => {
//       const authToken = await verifyToken()
//       setToken(authToken)
//     }
//     fetchToken()
//   }, [])

//   const data = useLoaderData()
//   const [bookingSlots, setBookingSlots] = useState(data)
//   const [selectedBookingSlots, setSelectedBookingSlots] = useState([])
//   const [expandedRows, setExpandedRows] = useState([])
//   console.log(bookingSlots)
//   const [basicRate, setBasicRate] = useState('')
//   const [firstDays, setFirstDays] = useState('')
//   const [rateForFirstDays, setRateForFirstDays] = useState('')
//   const [rateAfterFirstDays, setRateAfterFirstDays] = useState('')
//   const [bookingSlotStartDate, setBookingSlotStartDate] = useState('')
//   const [bookingSlotEndDate, setBookingSlotEndDate] = useState('')

//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [isEditMode, setIsEditMode] = useState(false)
//   const [searchTerm, setSearchTerm] = useState('')
//   const [fromDate, setFromDate] = useState('')
//   const [toDate, setToDate] = useState('')
//   const [currentPage, setCurrentPage] = useState(1)
//   const [itemsPerPage, setItemPerPage] = useState(15)
//   const [serialNumber, setSerialNumber] = useState(
//     (currentPage - 1) * itemsPerPage
//   )
//   const totalBookingSlots = bookingSlots.length

//   const { triggerData: triggerDataBookingSlots } = useFetch(
//     apiURLBookingSlots,
//     token,
//     selectedBookingSlots
//   )
//   // console.log(bookingSlots)

//   useEffect(() => {
//     if (triggerDataBookingSlots) {
//       setBookingSlots(triggerDataBookingSlots)
//     }
//   }, [bookingSlots, selectedBookingSlots, triggerDataBookingSlots])

//   const handleCreateValidation = () => {
//     const newStartDate = new Date(bookingSlotStartDate)
//     const newEndDate = new Date(bookingSlotEndDate)

//     const conflictingSlots = bookingSlots.filter((slot) => {
//       const existingStartDate = new Date(slot.startingDate)
//       const existingEndDate = new Date(slot.endingDate)

//       // Check if the new slot overlaps with any existing slot
//       return (
//         (newStartDate >= existingStartDate &&
//           newStartDate <= existingEndDate) ||
//         (newEndDate >= existingStartDate && newEndDate <= existingEndDate) ||
//         (newStartDate <= existingStartDate && newEndDate >= existingEndDate)
//       )
//     })

//     if (conflictingSlots.length > 0) {
//       const conflictingDates = conflictingSlots
//         .map((slot) => `From ${slot.startingDate} to ${slot.endingDate}`)
//         .join(', ')

//       // Show alert for conflicting dates
//       // alert(
//       //   `The selected date range conflicts with the following slot(s): ${conflictingDates}`
//       // )
//       toast.error(
//         `The selected date range conflicts with the following slot(s): ${conflictingDates}`,
//         {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         }
//       )
//       return false // Prevent further actions if there's a conflict
//     }

//     return true // No conflict, proceed with creation
//   }

//   const handleCreateBookingSlot = async (e) => {
//     e.preventDefault()
//     if (!handleCreateValidation()) {
//       return // Exit if validation fails
//     }
//     const newBookingSlot = {
//       bookingPriceID: null,
//       startingDate: bookingSlotStartDate,
//       endingDate: bookingSlotEndDate,
//       basicRate: basicRate,
//       firstDays: firstDays,
//       rateForFirstDays: rateForFirstDays,
//       rateAfterFirstDays: rateAfterFirstDays,
//     }

//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/api/BookingPrice/CreateOrUpdateBookingPrice`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token,
//           },
//           body: JSON.stringify(newBookingSlot),
//         }
//       )

//       const createdBookingSlot = await response.json()
//       console.log(createdBookingSlot)
//       if (response.ok) {
//         toast.success(`BookingSlot '${createdBookingSlot.basicRate}' created`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//         resetModal()
//       } else {
//         toast.warn(`Failed to create BookingSlot.`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//       }
//     } catch (error) {
//       toast.error(`${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   const handleAddButtonClick = () => {
//     setIsModalOpen(true)
//   }

//   const resetModal = () => {
//     setIsModalOpen(false)
//     setIsEditMode(false)
//     setSelectedBookingSlots([])
//     setBasicRate('')
//     setFirstDays('')
//     setRateForFirstDays('')
//     setRateAfterFirstDays('')
//     setBookingSlotStartDate('')
//     setBookingSlotEndDate('')
//   }

//   const handleBackdropClick = (e) => {
//     if (e.target.classList.contains('modal')) {
//       resetModal()
//     }
//   }

//   const handleEditBookingSlot = async (e) => {
//     e.preventDefault()
//     const selectedBookingSlot = bookingSlots.find(
//       (bookingSlot) => bookingSlot.bookingPriceID === selectedBookingSlots[0]
//     )

//     const updatedBookingSlot = {
//       ...selectedBookingSlot,
//       bookingPriceID: selectedBookingSlot.bookingPriceID,
//       startingDate: bookingSlotStartDate,
//       endingDate: bookingSlotEndDate,
//       basicRate: basicRate,
//       firstDays: firstDays,
//       rateForFirstDays: rateForFirstDays,
//       rateAfterFirstDays: rateAfterFirstDays,
//     }

//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/api/BookingPrice/CreateOrUpdateBookingPrice`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token,
//           },
//           body: JSON.stringify(updatedBookingSlot),
//         }
//       )

//       if (response.ok) {
//         toast.success(
//           `BookingSlot '${selectedBookingSlot.basicRate}' updated`,
//           {
//             position: 'top-right',
//             autoClose: false,
//             closeOnClick: true,
//             draggable: true,
//             progress: true,
//             theme: 'light',
//             transition: Slide,
//           }
//         )
//         resetModal()
//       } else {
//         toast.warn(`Failed to update BookingSlot.`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//       }
//     } catch (error) {
//       toast.error(`Error updating BookingSlot: ${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   const handleEditButtonClick = () => {
//     const selectedBookingSlot = bookingSlots.find(
//       (bookingSlot) => bookingSlot.bookingPriceID === selectedBookingSlots[0]
//     )
//     console.log(selectedBookingSlot)

//     setBasicRate(selectedBookingSlot.basicRate)
//     setFirstDays(selectedBookingSlot.firstDays)
//     setRateForFirstDays(selectedBookingSlot.rateForFirstDays)
//     setRateAfterFirstDays(selectedBookingSlot.rateAfterFirstDays)
//     setBookingSlotStartDate(selectedBookingSlot.startingDate)
//     setBookingSlotEndDate(selectedBookingSlot.endingDate)

//     setIsEditMode(true)
//     setIsModalOpen(true)
//   }

//   const handleDetailsBookingSlot = () => {
//     const selectedBookingSlot = bookingSlots.find(
//       (bookingSlot) => bookingSlot.bookingPriceID === selectedBookingSlots[0]
//     )
//     setBasicRate(selectedBookingSlot.basicRate)
//     setFirstDays(selectedBookingSlot.firstDays)
//     setRateForFirstDays(selectedBookingSlots.rateForFirstDays)
//     setRateAfterFirstDays(selectedBookingSlot.rateAfterFirstDays)
//     setBookingSlotStartDate(selectedBookingSlot.startingDate)
//     setBookingSlotEndDate(selectedBookingSlot.endingDate)
//   }

//   const handleDetailBookingSlot = (singleBookingSlot) => {
//     const selectedBookingSlot = bookingSlots.find(
//       (bookingSlot) => bookingSlot.bookingPriceID === singleBookingSlot
//     )

//     setBasicRate(selectedBookingSlot.basicRate)
//     setFirstDays(selectedBookingSlot.firstDays)
//     setRateForFirstDays(selectedBookingSlots.rateForFirstDays)
//     setRateAfterFirstDays(selectedBookingSlot.rateAfterFirstDays)
//     setBookingSlotStartDate(selectedBookingSlot.startingDate)
//     setBookingSlotEndDate(selectedBookingSlot.endingDate)
//   }

//   // const handleDeleteBookingSlot = async () => {
//   //   const selectedBookingSlot = { bookingPriceID: selectedBookingSlots }

//   //   try {
//   //     const response = await fetch(
//   //       `${API_BASE_URL}/api/Booking Slots/BulkDeleteBookingSlot`,
//   //       {
//   //         method: 'POST',
//   //         headers: {
//   //           'Content-Type': 'application/json',
//   //           Authorization: token,
//   //         },
//   //         body: JSON.stringify(selectedBookingSlot),
//   //       }
//   //     )

//   //     if (response.ok) {
//   //       toast.success(`Selected BookingSlots deleted`, {
//   //         position: 'top-right',
//   //         autoClose: false,
//   //         closeOnClick: true,
//   //         draggable: true,
//   //         progress: true,
//   //         theme: 'light',
//   //       })
//   //       setSelectedBookingSlots([])
//   //     }
//   //   } catch (error) {
//   //     toast.error(`Error deleting BookingSlots: ${error}`, {
//   //       position: 'top-right',
//   //       autoClose: false,
//   //       closeOnClick: true,
//   //       draggable: true,
//   //       progress: true,
//   //       theme: 'light',
//   //     })
//   //   }
//   //   setIsModalOpen(false)
//   //   setBasicRate('')
//   //   setFirstDays('')
//   //   setRateForFirstDays('')
//   // }

//   // const handleDeleteButtonClick = () => {
//   //   const selectedBookingSlot = bookingSlots.find(
//   //     (bookingSlot) => bookingSlot.bookingPriceID === selectedBookingSlots[0]
//   //   )
//   //   setBasicRate(selectedBookingSlot.basicRate)
//   // }

//   // const handleToggleRateAfterFirstDays = async (isActive) => {
//   //   try {
//   //     const selectedBookingSlot = {
//   //       bookingPriceID: selectedBookingSlots,
//   //       isActive: isActive,
//   //     }
//   //     const response = await fetch(
//   //       `${API_BASE_URL}/api/Booking Slots/BulkActiveBookingSlot`,
//   //       {
//   //         method: 'PUT',
//   //         headers: {
//   //           'Content-Type': 'application/json',
//   //           Authorization: token,
//   //         },
//   //         body: JSON.stringify(selectedBookingSlot),
//   //       }
//   //     )

//   //     if (response.ok) {
//   //       toast.success(`Selected BookingSlots updated`, {
//   //         position: 'top-right',
//   //         autoClose: false,
//   //         closeOnClick: true,
//   //         draggable: true,
//   //         progress: true,
//   //         theme: 'light',
//   //         transition: Slide,
//   //       })

//   //       setSelectedBookingSlots([])
//   //       setIsActiveButtonEnabled(false)
//   //     }
//   //   } catch (error) {
//   //     toast.error(`Error updating BookingSlot: ${error}`, {
//   //       position: 'top-right',
//   //       autoClose: false,
//   //       closeOnClick: true,
//   //       draggable: true,
//   //       progress: true,
//   //       theme: 'light',
//   //       transition: Slide,
//   //     })
//   //   }
//   // }

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value)
//   }

//   const filteredBookingSlots = bookingSlots.filter((bookingSlot) => {
//     // const matchesSearchTerm =
//     //   bookingSlot.basicRate?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     //   bookingSlot.rateForFirstDays
//     //     ?.toLowerCase()
//     //     .includes(searchTerm.toLowerCase()) ||
//     //   bookingSlot.firstDays?.toLowerCase().includes(searchTerm.toLowerCase())

//     const bookingSlotDate = new Date(bookingSlot.createdDate)

//     const matchesDateRange = (() => {
//       const fromDateObj = fromDate ? new Date(fromDate) : null
//       const toDateObj = toDate ? new Date(toDate) : null

//       const formatDate = (date) => {
//         if (!date) return null
//         const day = date.getDate().toString().padStart(2, '0')
//         const month = (date.getMonth() + 1).toString().padStart(2, '0')
//         const year = date.getFullYear()
//         return `${day}/${month}/${year}`
//       }

//       const formattedBookingSlotDate = formatDate(bookingSlotDate)
//       const formattedFromDate = formatDate(fromDateObj)
//       const formattedToDate = formatDate(toDateObj)

//       if (fromDateObj && toDateObj) {
//         return (
//           formattedBookingSlotDate >= formattedFromDate &&
//           formattedBookingSlotDate <= formattedToDate
//         ) // Inclusive range
//       } else if (fromDateObj) {
//         return formattedBookingSlotDate === formattedFromDate
//       }

//       return true
//     })()

//     // return matchesSearchTerm && matchesDateRange
//     return matchesDateRange
//   })

//   const handleResetFilter = () => {
//     setFromDate('')
//     setToDate('')
//   }

//   const handleCheckboxChange = (bookingPriceID) => {
//     setSelectedBookingSlots((prevSelected) => {
//       let newSelection
//       newSelection = prevSelected.includes(bookingPriceID)
//         ? prevSelected.filter((id) => id !== bookingPriceID)
//         : [...prevSelected, bookingPriceID]
//       return newSelection
//     })
//   }

//   const totalPages = Math.ceil(
//     filteredBookingSlots.length / Number(itemsPerPage)
//   )
//   const startIndex = (currentPage - 1) * Number(itemsPerPage)

//   const endIndex = startIndex + Number(itemsPerPage)

//   const currentBookingSlots = filteredBookingSlots
//     .reverse()
//     .slice(startIndex, endIndex)

//   const handleSerialNumberChange = (index) => {
//     setCurrentPage(index + 1)
//     setSerialNumber(index * Number(itemsPerPage))
//   }

//   const calculateDisplayRange = () => {
//     return `${currentBookingSlots.length}/${totalBookingSlots}`
//   }

//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => {
//         const newPage = prevPage - 1
//         setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
//         return newPage
//       })
//     }
//   }

//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage((prevPage) => {
//         const newPage = prevPage + 1
//         setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
//         return newPage
//       })
//     }
//   }
//   const handleRowClick = (index) => {
//     setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]))
//   }

//   return (
//     <>
//       <div className="section-1">
//         <div className="row">
//           <div className="col-lg-4 col-md-12">
//             <div className="left">
//               <div className="d-flex flex-row flex-wrap">
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     title="Add New"
//                     className="btn btn-light details-btnn"
//                     onClick={handleAddButtonClick}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Booking"
//                     >
//                       <i className="fa-solid fa-plus" type="button"></i>
//                     </span>
//                   </button>
//                 </div>{' '}
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     onClick={handleEditButtonClick}
//                     title="Edit"
//                     disabled={selectedBookingSlots.length !== 1}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Edit"
//                     >
//                       <i className="fa-solid fa-pencil"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     onClick={handleDetailsBookingSlot}
//                     title="Details"
//                     disabled={selectedBookingSlots.length !== 1}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Details"
//                     >
//                       <i
//                         className="fa-solid fa-credit-card"
//                         title="Pay Now"
//                       ></i>
//                     </span>
//                   </button>

//                   <div
//                     id="DetailsCard"
//                     tabindex="-1"
//                     aria-labelledby="CardDetailsModalLabel"
//                     className={`modal fade ${
//                       isModalOpen ? 'show d-block' : ''
//                     }`}
//                     onClick={handleBackdropClick}
//                     aria-hidden={!isModalOpen}
//                     style={
//                       isModalOpen
//                         ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                         : {}
//                     }
//                   >
//                     <div
//                       className="modal-dialog modal-dialog-scrollable"
//                       onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
//                     >
//                       <div className="modal-content">
//                         <div className="modal-header">
//                           <h1
//                             className="modal-title fs-5"
//                             id="DetailsModalLabel"
//                           >
//                             Card Details
//                           </h1>
//                           <button
//                             type="button"
//                             className="btn-close"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           ></button>
//                         </div>
//                         <div className="modal-body">
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Basic Rate: '}</p>
//                             <p className="ms-2 mb-0">{basicRate}</p>
//                           </div>
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'First Days : '}</p>
//                             <p className="ms-2 mb-0">{firstDays}</p>
//                           </div>
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">
//                               {'Rate For First Days: '}
//                             </p>
//                             <p className="ms-2 mb-0">{rateForFirstDays}</p>
//                           </div>
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">
//                               {'Rate After First Days: '}
//                             </p>
//                             <p className="ms-2 mb-0">{rateAfterFirstDays}</p>
//                           </div>
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Starting Date: '}</p>
//                             <p className="ms-2 mb-0">{bookingSlotStartDate}</p>
//                           </div>{' '}
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Ending Date: '}</p>
//                             <p className="ms-2 mb-0">{bookingSlotEndDate}</p>
//                           </div>
//                         </div>

//                         <div className="modal-footer">
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           >
//                             Close
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     data-bs-toggle="modal"
//                     data-bs-target="#DeleteModal"
//                     title="Delete"
//                     disabled={selectedBookingSlots.length === 0}
//                     onClick={handleDeleteButtonClick}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-trash"></i>
//                     </span>
//                   </button>

//                   <div
//                     id="DeleteModal"
//                     tabindex="-1"
//                     aria-labelledby="DeleteModalLabel"
//                     className={`modal fade ${
//                       isModalOpen ? 'show d-block' : ''
//                     }`}
//                     onClick={handleBackdropClick}
//                     aria-hidden={!isModalOpen}
//                     style={
//                       isModalOpen
//                         ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                         : {}
//                     }
//                   >
//                     <div className="modal-dialog modal-dialog-scrollable">
//                       <div className="modal-content">
//                         <div className="modal-header">
//                           <h1
//                             className="modal-title fs-5"
//                             id="DeleteModalLabel"
//                           >
//                             Confirmation
//                           </h1>
//                           <button
//                             type="button"
//                             className="btn-close"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           ></button>
//                         </div>

//                         <div className="modal-body">
//                           <div className="mb-3">
//                             <label className="fs-5">
//                               Do you want to delete{' '}
//                               {selectedBookingSlots.length === 1
//                                 ? ` '${basicRate}' record?`
//                                 : `${selectedBookingSlots.length} records?`}{' '}
//                             </label>
//                             <br />
//                           </div>
//                         </div>

//                         <div className="modal-footer">
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           >
//                             No
//                           </button>
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={handleDeleteBookingSlot}
//                           >
//                             Yes
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     title="Mark as active"
//                     onClick={() => handleToggleRateAfterFirstDays(true)}
//                     disabled={
//                       (selectedBookingSlots.length === 0 &&
//                         !isActiveButtonEnabled) ||
//                       bookingSlots
//                         .filter((bookingSlot) =>
//                           selectedBookingSlots.includes(
//                             bookingSlot.bookingPriceID
//                           )
//                         )
//                         .every((bookingSlot) => bookingSlot.isActive)
//                     }
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-toggle-on"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     title="Mark as inactive"
//                     onClick={() => handleToggleRateAfterFirstDays(false)}
//                     disabled={
//                       (selectedBookingSlots.length === 0 &&
//                         !isActiveButtonEnabled) ||
//                       bookingSlots
//                         .filter((bookingSlot) =>
//                           selectedBookingSlots.includes(
//                             bookingSlot.bookingPriceID
//                           )
//                         )
//                         .every((bookingSlot) => !bookingSlot.isActive)
//                     }
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-toggle-off"></i>
//                     </span>
//                   </button>
//                 </div> */}
//               </div>

//               <div
//                 id="BookingSlotmodelMake"
//                 tabIndex="-1"
//                 aria-labelledby="BookingSlotModalLabel"
//                 className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
//                 onClick={handleBackdropClick}
//                 aria-hidden={!isModalOpen}
//                 style={
//                   isModalOpen
//                     ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                     : {}
//                 }
//               >
//                 <div
//                   className="modal-dialog modal-dialog-scrollable"
//                   onClick={(e) => e.stopPropagation()}
//                 >
//                   <div className="modal-content">
//                     <form
//                       id="add-new-user-form"
//                       onSubmit={
//                         isEditMode
//                           ? handleEditBookingSlot
//                           : handleCreateBookingSlot
//                       }
//                     >
//                       <div className="modal-header">
//                         <h5 className="modal-title" id="BookingSlotModalLabel">
//                           {isEditMode
//                             ? 'Edit Booking Slots Details'
//                             : 'Add New Booking Slots Details'}
//                         </h5>
//                         <button
//                           type="button"
//                           className="btn-close"
//                           onClick={resetModal}
//                         ></button>
//                       </div>
//                       <div className="modal-body">
//                         <div className="mb-3">
//                           <label htmlFor="txtMake" className="form-label">
//                             Basic Rate
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={basicRate}
//                             onChange={(e) => setBasicRate(e.target.value)}
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             First Days
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={firstDays}
//                             onChange={(e) => setFirstDays(e.target.value)}
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Rate For First Days
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={rateForFirstDays}
//                             onChange={(e) =>
//                               setRateForFirstDays(e.target.value)
//                             }
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Rate After First Days
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={rateAfterFirstDays}
//                             onChange={(e) =>
//                               setRateAfterFirstDays(e.target.value)
//                             }
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Starting Date
//                           </label>
//                           <input
//                             type="datetime-local"
//                             className="form-control"
//                             value={bookingSlotStartDate}
//                             onChange={(e) =>
//                               setBookingSlotStartDate(e.target.value)
//                             }
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Ending Date
//                           </label>
//                           <input
//                             type="datetime-local"
//                             className="form-control"
//                             value={bookingSlotEndDate}
//                             onChange={(e) =>
//                               setBookingSlotEndDate(e.target.value)
//                             }
//                             min={bookingSlotStartDate}
//                             required
//                           />
//                         </div>
//                       </div>
//                       <div className="modal-footer">
//                         <button
//                           type="button"
//                           className="cancel-btn"
//                           onClick={resetModal}
//                         >
//                           Close
//                         </button>
//                         <button type="submit" className="btn site-btn">
//                           {isEditMode
//                             ? 'Update Booking Slots'
//                             : 'Create Booking Slots'}
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-2 col-md-12 mt-2">
//             <div className="input-box ">
//               <input
//                 type="search"
//                 className="form-control"
//                 placeholder="Search..."
//                 value={searchTerm}
//                 onChange={handleSearchChange}
//               />
//             </div>
//           </div>

//           <div className="col-lg-6 col-md-12">
//             <div className="right mb-3 d-flex justify-content-between align-items-center">
//               <label className="mx-1">From: </label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={fromDate}
//                 onChange={(e) => {
//                   const newFromDate = e.target.value
//                   setFromDate(newFromDate)

//                   // Check if toDate is less than the new fromDate
//                   if (toDate && new Date(toDate) < new Date(newFromDate)) {
//                     setToDate('') // Clear toDate if it's less than fromDate
//                   }
//                 }}
//               />
//               <label className="mx-1">To: </label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={toDate}
//                 onChange={(e) => setToDate(e.target.value)}
//                 disabled={!fromDate}
//                 min={fromDate}
//               />
//               <div>
//                 <div className="p-2" title="Reset Date">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn"
//                     onClick={handleResetFilter}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Booking"
//                     >
//                       <i className="fa-solid fa-filter-circle-xmark"></i>
//                     </span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="table-responsive table-scroll">
//           <table className="table table-hover table-bordered table-sm align-middle">
//             <thead className="table-dark">
//               <tr>
//                 <th className="faqs-table-row-d1">
//                   <input
//                     type="checkbox"
//                     checked={
//                       selectedBookingSlots.length === bookingSlots.length
//                     }
//                     onChange={() =>
//                       setSelectedBookingSlots(
//                         selectedBookingSlots.length === bookingSlots.length
//                           ? []
//                           : bookingSlots.map(
//                               (bookingSlot) => bookingSlot.bookingPriceID
//                             )
//                       )
//                     }
//                   />
//                 </th>
//                 <th scope="col">Sr No.</th>
//                 <th scope="col">Basic Rate</th>
//                 <th scope="col">First Days</th>
//                 <th scope="col">Rate For First Days</th>
//                 <th scope="col">Rate After First Days</th>
//                 <th scope="col">Start Date Time</th>
//                 <th scope="col">End Date Time</th>
//                 <th scope="col">Created Date</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentBookingSlots?.map((bookingSlot, index) => (
//                 <>
//                   <tr
//                     key={bookingSlot.bookingPriceID}
//                     className={`${
//                       expandedRows.includes(index) ? 'table-info' : ''
//                     }`}
//                     style={{ cursor: 'pointer' }}
//                     onClick={() => handleRowClick(index)}
//                   >
//                     <td>
//                       <input
//                         type="checkbox"
//                         checked={selectedBookingSlots.includes(
//                           bookingSlot.bookingPriceID
//                         )}
//                         onChange={(e) =>
//                           handleCheckboxChange(
//                             bookingSlot.bookingPriceID,
//                             index,
//                             e
//                           )
//                         }
//                       />
//                     </td>
//                     <td>{serialNumber + index + 1}</td>

//                     <td>{bookingSlot.basicRate || 'N/A'}</td>
//                     <td>{bookingSlot.firstDays || 'N/A'}</td>
//                     <td>{bookingSlot.rateForFirstDays || 'N/A'}</td>
//                     <td>{bookingSlot.rateAfterFirstDays || 'N/A'}</td>
//                     <td>{bookingSlot.startingDate || 'N/A'}</td>
//                     <td>{bookingSlot.endingDate || 'N/A'}</td>
//                     <td>{bookingSlot.createdDate || 'N/A'}</td>
//                   </tr>
//                 </>
//               ))}
//             </tbody>
//           </table>
//         </div>

//                                 {/* {expandedRows.includes(index) && (
//                                   <tr className="bg-light">
//                                     <td colSpan="9"> */}
//                                 {/* <div className="row g-2 p-3">
//                                         <div className="col-md-3">
//                                           <strong>Total Cost:</strong>{' '}
//                                           {quote.totalCost || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Flying From:</strong>{' '}
//                                           {quote.flyingFrom || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Flying To:</strong>{' '}
//                                           {quote.flyingTo || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Inbound Terminal Name:</strong>{' '}
//                                           {quote.inBoundTerminalName || ' N/A'} /
//                                           {quote.inBoundTerminalCost || ' N/A'}
//                                         </div>

//                                         <div className="col-md-3">
//                                           <strong>Outbound Terminal Name:</strong>{' '}
//                                           {quote.outBoundTerminalName || ' N/A'} /
//                                           {quote.outBoundTerminalCost || ' N/A'}
//                                         </div>

//                                         <div className="col-md-3">
//                                           <strong>Airport Name:</strong>{' '}
//                                           {quote.airportName || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Product Price:</strong> £
//                                           {quote.productPrice || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Discount Code:</strong>{' '}
//                                           {quote.discountCode || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Source:</strong> {quote.source || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Car Clean Name:</strong>{' '}
//                                           {quote.carCleanName || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Car Clean Price:</strong> £
//                                           {quote.carCleanPrice || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>eCharge Name:</strong>{' '}
//                                           {quote.eChargeName || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>eCharge Price:</strong> £
//                                           {quote.eChargePrice || 'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Vehicle Make:</strong>{' '}
//                                           {quote.vehicleMakeName ||
//                                             quote.vehicleMakeOther ||
//                                             'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Vehicle Model:</strong>{' '}
//                                           {quote.vehicleModelName ||
//                                             quote.vehicleModelOther ||
//                                             'N/A'}
//                                         </div>
//                                         <div className="col-md-3">
//                                           <strong>Vehicle Color:</strong>{' '}
//                                           {quote.vehicleColorName ||
//                                             quote.vehicleColorOther ||
//                                             'N/A'}
//                                         </div>
//                                         <div className="col-md-4 d-flex gap-2">
//                                           <a
//                                             href={`http://localhost:3001?quoteCode=${quote.quoteCode}`}
//                                             className="btn btn-success btn-sm"
//                                           >
//                                             Complete Quote
//                                           </a>
//                                           <button
//                                             className="btn btn-danger btn-sm"
//                                             data-bs-toggle="modal"
//                                             data-bs-target="#DeleteModal"
//                                             onClick={(e) =>
//                                               handleCheckboxChange(quote.quoteID)
//                                             }
//                                           >
//                                             Delete Quote
//                                           </button>
//                                         </div>
//                                       </div> */}
//                                 {/* </td>
//                                   </tr>
//                                 )} */}

//         {/* <div className="table-responsive table-scroll">
//           <table className="table-faq">
//             <thead>
//               <tr>
//                 <th className="faqs-table-row-d1">
//                   <input
//                     type="checkbox"
//                     checked={
//                       selectedBookingSlots.length === bookingSlots.length
//                     }
//                     onChange={() =>
//                       setSelectedBookingSlots(
//                         selectedBookingSlots.length === bookingSlots.length
//                           ? []
//                           : bookingSlots.map(
//                               (bookingSlot) => bookingSlot.bookingPriceID
//                             )
//                       )
//                     }
//                   />
//                 </th>
//                 <th scope="col" className="font faqs-table-row-d2">
//                   Sr No.
//                 </th>
//                 <th scope="col" className="faqs-table-row-d3">
//                   Basic Rate
//                 </th>
//                 <th scope="col" className="faqs-table-row-d4">
//                   First Days
//                 </th>
//                 <th scope="col" className="faqs-table-row-d4">
//                   Rate For First Days
//                 </th>
//                 <th scope="col" className="faqs-table-row-d3">
//                   Rate After First Days
//                 </th>
//                 <th scope="col" className="faqs-table-row-d4">
//                   Starting Date
//                 </th>
//                 <th scope="col" className="faqs-table-row-d3">
//                   Ending Date
//                 </th>{' '}
//                 <th scope="col" className="faqs-table-row-d4">
//                   Created Date
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentBookingSlots.map((bookingSlot, index) => (
//                 <tr
//                   className="faqs-table-row "
//                   title={`${
//                     bookingSlot.isActive ? 'Active Item' : 'Inactive Item'
//                   }`}
//                   key={bookingSlot.bookingPriceID}
//                   style={
//                     bookingSlot.isActive ? { backgroundColor: '#D3D3D3' } : {}
//                   }
//                 >
//                   <td>
//                     <input
//                       type="checkbox"
//                       checked={selectedBookingSlots.includes(
//                         bookingSlot.bookingPriceID
//                       )}
//                       onChange={(e) =>
//                         handleCheckboxChange(
//                           bookingSlot.bookingPriceID,
//                           index,
//                           e
//                         )
//                       }
//                     />
//                   </td>
//                   <td>{serialNumber + index + 1}</td>
//                   <td
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     className="table-btn"
//                     onClick={() =>
//                       handleDetailBookingSlot(bookingSlot.bookingPriceID)
//                     }
//                   >
//                     {bookingSlot.basicRate}
//                   </td>
//                   <td
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     className="table-btn"
//                     onClick={() =>
//                       handleDetailBookingSlot(bookingSlot.bookingPriceID)
//                     }
//                   >
//                     {bookingSlot.firstDays}
//                   </td>
//                   <td>{bookingSlot.rateForFirstDays}</td>
//                   <td> {bookingSlot.rateAfterFirstDays}</td>
//                   <td> {bookingSlot.startingDate}</td>
//                   <td> {bookingSlot.endingDate}</td>

//                   <td>{bookingSlot.createdDate}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div> */}

//         <nav aria-label="Page navigation example">
//           <div className="pagination-container">
//             <div className="pagination-left">
//               <div className="items-per-page-label">Items per page:</div>
//               <select
//                 name="faqPerPage"
//                 onChange={(e) => setItemPerPage(e.target.value)}
//                 className="items-per-page-select form-control"
//               >
//                 <option value="15">15</option>
//                 <option value="30">30</option>
//                 <option value="45">45</option>
//                 <option value="60">60</option>
//                 <option value="75">75</option>
//                 <option value="90">90</option>
//               </select>
//             </div>

//             <div className="pagination-right">
//               <div className="faq-display-range">
//                 Showing BookingSlots: {calculateDisplayRange()}
//               </div>
//               <ul className="pagination-list pagination ">
//                 <li
//                   className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
//                 >
//                   <button
//                     className="page-link"
//                     onClick={handlePrevious}
//                     disabled={currentPage === 1}
//                   >
//                     Previous
//                   </button>
//                 </li>

//                 {[...Array(totalPages)].map((_, index) => (
//                   <li
//                     className={`page-item ${
//                       currentPage === index + 1 ? 'active' : ''
//                     }`}
//                     key={index}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => handleSerialNumberChange(index)}
//                     >
//                       {index + 1}
//                     </button>
//                   </li>
//                 ))}

//                 <li
//                   className={`page-item ${
//                     currentPage === totalPages ? 'disabled' : ''
//                   }`}
//                 >
//                   <button
//                     className="page-link"
//                     onClick={handleNext}
//                     disabled={currentPage === totalPages}
//                   >
//                     Next
//                   </button>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </nav>
//       </div>
//     </>
//   )
// }

// export default BookingSlots
// export const getAllBookingSlots = async () => {
//   const response = await fetch(`${API_BASE_URL}`)

//   return response.json()
// }

import { useEffect, useState } from "react";
import useFetch from "../../../CustomHooks/useFetch";
import { API_BASE_URL } from "../../../Config/Config";
import { verifyToken } from "../../..";
import CustomLoading from "../../../Components/CustomHandling/LoadingAndError";
import { Slide, toast } from "react-toastify";
import PageHeaderAndFooter from "../../../Components/PageHeaderAndFooter/PageHeaderAndFooter";
import { Link } from "react-router-dom";

const BookingSlots = () => {
  const showToast = (message, type = "info", options = {}) => {
    const baseOptions = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: "light",
      transition: Slide,
      ...options,
    };

    switch (type) {
      case "success":
        toast.success(message, baseOptions);
        break;
      case "warn":
        toast.warn(message, baseOptions);
        break;
      case "error":
        toast.error(message, baseOptions);
        break;
      case "info":
      default:
        toast.info(message, baseOptions);
        break;
    }
  };

  const bookingSlotsURL = `${API_BASE_URL}/api/BookingPrice/GetAllBookingPrices`;
  const rolesURL = `${API_BASE_URL}/api/UserRoles/GetAllRoles`;
  const [token, setToken] = useState("");

  const [userData, setUserData] = useState({
    bookingSlotName: "",
    userId: null,
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    // promotion: '',
    roleId: null,
  });
  // const [roles, setRoles] = useState([])
  const [refreshDependency, setRefreshDependency] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createState, setCreateState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [blockState, setBlockState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken();
      setToken(authToken);
    };
    fetchToken();
  }, []);

  const {
    data: bookingSlots,
    loading,
    error,
  } = useFetch(bookingSlotsURL, refreshDependency);

  const { data: roles } = useFetch(rolesURL, refreshDependency);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleValidateUser = () => {
    if (userData.password === userData.confirmPassword) {
      return true;
    } else {
      showToast(`Password does not match`, "error");
      return false;
    }
  };
  const handleCreateOrEditBookingSlot = async (e) => {
    e.preventDefault();

    const newBookingSlot = {
      usersID: userData.userId || null,
      userName: userData.bookingSlotName || null,
      password: editState ? "dummydata1234" : userData.password,
      firstName: userData.firstName || null,
      lastName: userData.lastName || null,
      email: userData.email || null,
      phoneNo: userData.phoneNo || null,
      userTypeID: 0,
      companyName: userData.companyName || null,
      promotion: true,
      createdDate: null,
      createdBy: null,
      modifiedDate: null,
      modifiedBy: null,
      deletedDate: null,
      deletedBy: null,
      discountCode: null,
      roleId: userData.roleId || null,
    };

    const url = `${API_BASE_URL}/api/user/CreateUser`;
    if (editState || handleValidateUser()) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(newBookingSlot),
        });

        const result = await response.json();

        if (response.ok) {
          showToast(
            createState
              ? "User successfully created."
              : "User successfully updated.",
            "success"
          );

          // Reset states and refresh
          setRefreshDependency((prev) => prev + 1);
          setIsModalOpen(false);
          setCreateState(false);
          setEditState(false);
        } else {
          // Warning toasts for errors
          Object.keys(result.errors).forEach((key) => {
            result.errors[key].forEach((message) => {
              showToast(`${key}: ${message}`, "warn");
            });
          });
        }
      } catch (error) {
        // Error toast for network issues or unexpected errors
        showToast(`${error.message}`, "error");
      }
    }
  };

  const handleUpdateBookingSlot = async (e) => {
    e.preventDefault();
    const url = `${API_BASE_URL}/api/user/UpdateUserStatus`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          userID: userData.userId,
          isActive: null,
          isDeleted: deleteState ? true : null,
          isBlocked: blockState ? true : null,
        }),
      });

      const updateBookingSlot = await response.json();
      console.log(updateBookingSlot);
      if (response.ok) {
        console.log(updateBookingSlot);

        const action = deleteState ? "deleted" : "blocked";
        showToast(`User '${userData.firstName}' ${action}`, "success");
        setRefreshDependency((prev) => prev + 1);
        setIsModalOpen(false);
        setDeleteState(false);
        setBlockState(false);
        setExpandedRows([]);
      } else {
        showToast("Failed to Delete User.", "warn");
      }
    } catch (error) {
      showToast(`${error.message}`, "error");
    }
  };
  // console.log(bookingSlots)

  // const handleOpenModal = (actionType, userID) => {
  //   const selectedBookingSlot = bookingSlots.find((mod) => mod.userID === userID)
  //   console.log(selectedBookingSlot)

  //   const resetUserData = () => ({
  //     bookingSlotName: '',
  //     userId: null,
  //     password: '',
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     phoneNo: '',
  //     companyName: '',
  //     roleId: null,
  //   })

  //   const populateUserData = (user) => ({
  //     bookingSlotName: user.userName,
  //     userId: user.userID,
  //     password: '',
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     email: user.email,
  //     phoneNo: user.phoneNo.trim(),
  //     companyName: user.companyName,
  //     roleId: user.roleID,
  //   })

  //   // Reset modal states before setting new ones
  //   setCreateState(false)
  //   setEditState(false)
  //   setDeleteState(false)
  //   setIsModalOpen(false)

  //   switch (actionType) {
  //     case 'create':
  //       setUserData(resetUserData())
  //       setCreateState(true)
  //       setIsModalOpen(true)
  //       break

  //     case 'edit':
  //       if (selectedBookingSlot) {
  //         setUserData(populateUserData(selectedBookingSlot))
  //         setEditState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for editing')
  //       }
  //       break

  //     case 'block':
  //       if (selectedBookingSlot) {
  //         setUserData(populateUserData(selectedBookingSlot))
  //         setBlockState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for editing')
  //       }
  //       break

  //     case 'delete':
  //       if (selectedBookingSlot) {
  //         setUserData(populateUserData(selectedBookingSlot))
  //         setDeleteState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for deletion')
  //       }
  //       break

  //     default:
  //       console.error('Invalid action type')
  //       setCreateState(false)
  //       setEditState(false)
  //       setDeleteState(false)
  //       setIsModalOpen(false)
  //       break
  //   }
  // }
  const handleOpenModal = (actionType, userID) => {
    const selectedBookingSlot = bookingSlots.find(
      (mod) => mod.userID === userID
    );

    const resetUserData = () => ({
      bookingSlotName: "",
      userId: null,
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      companyName: "",
      roleId: null,
    });

    const populateUserData = (user) => ({
      bookingSlotName: user.userName,
      userId: user.userID,
      password: "",
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNo: user.phoneNo.trim(),
      companyName: user.companyName,
      roleId: user.roleID,
    });

    // Reset modal states
    setCreateState(false);
    setEditState(false);
    setBlockState(false);
    setDeleteState(false);
    setIsModalOpen(false);

    const handleAction = (stateSetter) => {
      if (selectedBookingSlot) {
        setUserData(populateUserData(selectedBookingSlot));
        stateSetter(true);
        setIsModalOpen(true);
      }
    };

    switch (actionType) {
      case "create":
        setUserData(resetUserData());
        setCreateState(true);
        setIsModalOpen(true);
        break;

      case "edit":
        handleAction(setEditState);
        break;

      case "block":
        handleAction(setBlockState);
        break;

      case "delete":
        handleAction(setDeleteState);
        break;

      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCreateState(false);
    setEditState(false);
    setBlockState(false);
    setDeleteState(false);
  };

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]));
  };

  if (loading) return <CustomLoading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New BookingSlot"
        handleOpenModal={() => handleOpenModal("create", null)}
        data={bookingSlots}
        filterFields={["startingDate", "companyName", "userName"]}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Starting Date</th>
                  <th scope="col">Ending Date</th>
                  <th scope="col">Basic Rate</th>
                  <th scope="col">First Day</th>
                  <th scope="col">Rate For First Day</th>
                  <th scope="col">Rate After First Days</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <>
                    <tr
                      key={data.userID}
                      className={`${
                        expandedRows.includes(index) ? "table-info" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{startIndex + index + 1}</td>
                      <td>{data.startingDate || "N/A"}</td>
                      <td>{data.endingDate || "N/A"}</td>
                      <td>{data.basicRate || "N/A"}</td>
                      <td>{data.firstDays || "N/A"}</td>
                      <td>{data.rateForFirstDays || "N/A"}</td>
                      <td>{data.rateAfterFirstDays || "N/A"}</td>
                      <td>{data.createdDate || "N/A"}</td>
                      <td className="d-flex gap-2">
                        <button
                          className="btn btn_modal"
                          onClick={() => handleOpenModal("edit", data.userID)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn_modal_sec"
                          onClick={() => handleOpenModal("delete", data.userID)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? "show" : ""}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? "block" : "none",
              background: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={handleCloseModal}
          >
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  createState || editState
                    ? handleCreateOrEditBookingSlot
                    : deleteState || blockState
                    ? handleUpdateBookingSlot
                    : ""
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {/* {userID ? 'Update bookingSlot name' : 'Create bookingSlot name'} */}
                      {createState
                        ? "Create"
                        : editState
                        ? "Update"
                        : deleteState
                        ? "Delete"
                        : blockState
                        ? "Block"
                        : ""}{" "}
                      User
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body row">
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="bookingSlotName" className="form-label">
                        User Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="bookingSlotName"
                        value={userData.bookingSlotName}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>

                    {createState ? (
                      <>
                        <div className="mb-3 col-lg-6">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>

                        <div className="mb-3 col-lg-6">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={userData.firstName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={userData.lastName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="phoneNo" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNo"
                        value={userData.phoneNo}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="roleId" className="form-label">
                        User Types
                      </label>
                      <select
                        className="form-control"
                        name="roleId"
                        value={userData.roleId || ""}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      >
                        <option value="" disabled>
                          Select User Type
                        </option>
                        {roles.map((role) => (
                          <option key={role.roleId} value={role.roleId}>
                            {role.roleName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="companyName" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        value={userData.companyName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        createState
                          ? "btn btn_modal_sec"
                          : editState
                          ? "btn btn_modal_sec"
                          : blockState || deleteState
                          ? "btn btn_modal"
                          : ""
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        createState
                          ? "btn btn_modal"
                          : editState
                          ? "btn btn_modal"
                          : blockState || deleteState
                          ? "btn btn_modal_sec"
                          : ""
                      }
                      type="submit"
                    >
                      {createState
                        ? "Create"
                        : editState
                        ? "Update"
                        : blockState
                        ? "Block"
                        : deleteState
                        ? "Delete"
                        : ""}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookingSlots;
