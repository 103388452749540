import React from 'react'
import BookingNavbar from '../Navbars/BookingNavbar'
import Header from '../Components/Header/Header'
import { Outlet } from 'react-router-dom'

function BookingsLayout() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 col-md-3 p-0">
          <BookingNavbar />
        </div>
        <div className="col-lg-9 col-md-9 col-12">
          <Header />
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default BookingsLayout
