// import React, { useEffect, useState } from 'react'
// import { Link, useLoaderData } from 'react-router-dom'
// import { toast, Slide } from 'react-toastify'
// import { API_BASE_URL } from '../../../Config/Config'
// import { verifyToken } from '../../../index.js'
// function VehicleMakes() {
//   const [token, setToken] = useState('')
//   const data = useLoaderData()
//   const [vehicleMakes, setVehicleMakes] = useState(data)
//   const [selectedVehicleMakes, setSelectedVehicleMakes] = useState([])

//   const [vehicleMakeName, setVehicleMakeName] = useState('')
//   const [vehicleMakeType, setVehicleMakeType] = useState('')
//   const [vehicleMakeDescription, setVehicleMakeDescription] = useState('')
//   const [vehicleMakeStatus, setVehicleMakeStatus] = useState(true)

//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [isEditMode, setIsEditMode] = useState(false)
//   const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
//   const [searchTerm, setSearchTerm] = useState('')
//   const [fromDate, setFromDate] = useState('')
//   const [toDate, setToDate] = useState('')
//   const [currentPage, setCurrentPage] = useState(1)
//   const [itemsPerPage, setItemPerPage] = useState(15)
//   const [serialNumber, setSerialNumber] = useState(
//     (currentPage - 1) * itemsPerPage
//   )
//   const totalVehicleMakes = vehicleMakes.length

//   useEffect(() => {
//     const fetchToken = async () => {
//       const authToken = await verifyToken()
//       setToken(authToken)
//     }
//     fetchToken()
//   }, [])

//   function handleStatusChange(e) {
//     setVehicleMakeStatus(e.target.value === 'true')
//   }

//   const handleCreateVehicleMake = async (e) => {
//     e.preventDefault()
//     const newVehicleMake = {
//       VehicleMakerName: vehicleMakeName,
//       VehicleMakerType: vehicleMakeType,
//       VehicleMakerDescription: vehicleMakeDescription,
//       isActive: true,
//     }

//     console.log(newVehicleMake)

//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/api/Vehicle/CreateMakeDetails`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token,
//           },
//           body: JSON.stringify(newVehicleMake),
//         }
//       )

//       const createdVehicleMake = await response.json()
//       setVehicleMakes((prevVehicleMakes) => [
//         ...prevVehicleMakes,
//         createdVehicleMake,
//       ])
//       console.log(createdVehicleMake)
//       if (response.ok) {
//         toast.success(
//           `VehicleMake '${createdVehicleMake.vehicleMakeName}' created`,
//           {
//             position: 'top-right',
//             autoClose: false,
//             closeOnClick: true,
//             draggable: true,
//             progress: true,
//             theme: 'light',
//             transition: Slide,
//           }
//         )
//         resetModal()
//       } else {
//         toast.warn(`Failed to create VehicleMake.`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//       }
//     } catch (error) {
//       toast.error(`${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   const handleAddButtonClick = () => {
//     setIsModalOpen(true)
//   }

//   const resetModal = () => {
//     setIsModalOpen(false)
//     setIsEditMode(false)
//     setSelectedVehicleMakes([])
//     setVehicleMakeName('')
//     setVehicleMakeType('')
//     setVehicleMakeDescription('')
//     setVehicleMakeStatus(true)
//   }

//   const handleBackdropClick = (e) => {
//     if (e.target.classList.contains('modal')) {
//       resetModal()
//     }
//   }

//   const handleEditVehicleMake = async (e) => {
//     e.preventDefault()
//     const selectedVehicleMake = vehicleMakes.find(
//       (vehicleMake) => vehicleMake.vehicleMakeID === selectedVehicleMakes[0]
//     )

//     const updatedVehicleMake = {
//       ...selectedVehicleMake,
//       VehicleMakerName: vehicleMakeName,
//       VehicleMakerType: vehicleMakeType,
//       VehicleMakerDescription: vehicleMakeDescription,
//       // vehicleMakeName,
//       // vehicleMakeType,
//       // vehicleMakeDescription,
//       isActive: vehicleMakeStatus,
//     }

//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/api/Vehicle/UpdateVehicleDetails/${selectedVehicleMake.vehicleMakeID}`,
//         {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token,
//           },
//           body: JSON.stringify(updatedVehicleMake),
//         }
//       )

//       if (response.ok) {
//         const updatedVehicleMakeData = await response.json()
//         setVehicleMakes((prevVehicleMakes) =>
//           prevVehicleMakes.map((vehicleMake) =>
//             vehicleMake.vehicleMakeID === updatedVehicleMakeData.vehicleMakeID
//               ? updatedVehicleMakeData
//               : vehicleMake
//           )
//         )
//         console.log(updatedVehicleMake)
//         console.log(vehicleMakes)
//         toast.success(
//           `VehicleMake '${selectedVehicleMake.vehicleMakeName}' updated`,
//           {
//             position: 'top-right',
//             autoClose: false,
//             closeOnClick: true,
//             draggable: true,
//             progress: true,
//             theme: 'light',
//             transition: Slide,
//           }
//         )
//         resetModal()
//       } else {
//         toast.warn(`Failed to update VehicleMake.`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//       }
//     } catch (error) {
//       toast.error(`Error updating VehicleMake: ${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   const handleEditButtonClick = () => {
//     const selectedVehicleMake = vehicleMakes.find(
//       (vehicleMake) => vehicleMake.vehicleMakeID === selectedVehicleMakes[0]
//     )
//     setVehicleMakeName(selectedVehicleMake.vehicleMakeName)
//     setVehicleMakeType(selectedVehicleMake.vehicleMakeType)
//     setVehicleMakeDescription(selectedVehicleMake.vehicleMakeDescription)
//     setVehicleMakeStatus(selectedVehicleMake.isActive)

//     setIsEditMode(true)
//     setIsModalOpen(true)
//   }

//   const handleDetailsVehicleMake = () => {
//     const selectedVehicleMake = vehicleMakes.find(
//       (vehicleMake) => vehicleMake.vehicleMakeID === selectedVehicleMakes[0]
//     )
//     setVehicleMakeName(selectedVehicleMake.vehicleMakeName)
//     setVehicleMakeType(selectedVehicleMake.vehicleMakeType)
//     setVehicleMakeDescription(selectedVehicleMake.vehicleMakeDescription)
//     setVehicleMakeStatus(selectedVehicleMake.isActive)
//   }

//   const handleDetailVehicleMake = (singleVehicleMake) => {
//     const selectedVehicleMake = vehicleMakes.find(
//       (vehicleMake) => vehicleMake.vehicleMakeID === singleVehicleMake
//     )
//     setVehicleMakeName(selectedVehicleMake.vehicleMakeName)
//     setVehicleMakeType(selectedVehicleMake.vehicleMakeType)
//     setVehicleMakeDescription(selectedVehicleMake.vehicleMakeDescription)
//     setVehicleMakeStatus(selectedVehicleMake.isActive)
//   }

//   const handleDeleteVehicleMake = async () => {
//     const selectedVehicleMake = { vehicleMakeID: selectedVehicleMakes }

//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/api/VehicleMake/BulkDelete`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token,
//           },
//           body: JSON.stringify(selectedVehicleMake),
//         }
//       )

//       if (response.ok) {
//         setVehicleMakes((prevVehicleMakes) =>
//           prevVehicleMakes.filter(
//             (vehicleMake) =>
//               !selectedVehicleMakes.includes(vehicleMake.vehicleMakeID)
//           )
//         )
//         toast.success(`Selected VehicleMakes deleted`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//         })
//         setSelectedVehicleMakes([])
//       }
//     } catch (error) {
//       toast.error(`Error deleting VehicleMakes: ${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//       })
//     }
//     setIsModalOpen(false)
//     setVehicleMakeName('')
//     setVehicleMakeType('')
//     setVehicleMakeDescription('')
//   }

//   const handleDeleteButtonClick = () => {
//     const selectedVehicleMake = vehicleMakes.find(
//       (vehicleMake) => vehicleMake.vehicleMakeID === selectedVehicleMakes[0]
//     )
//     setVehicleMakeName(selectedVehicleMake.vehicleMakeName)
//   }

//   const handleToggleVehicleMakeStatus = async (isActive) => {
//     try {
//       const validVehicleMakes = selectedVehicleMakes.filter((id) => {
//         const vehicleMake = vehicleMakes.find(
//           (vehicleMake) => vehicleMake.vehicleMakeID === id
//         )
//         return vehicleMake.vehicleMakeName && vehicleMake.vehicleMakeDescription
//       })
//       const selectedVehicleMake = {
//         vehicleMakeID: selectedVehicleMakes,
//         isActive: isActive,
//       }
//       const response = await fetch(
//         `${API_BASE_URL}/api/Vehicle/BulkActiveVehicleDetails`,
//         {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token,
//           },
//           body: JSON.stringify(selectedVehicleMake),
//         }
//       )

//       if (response.ok) {
//         setVehicleMakes((prevVehicleMakes) =>
//           prevVehicleMakes.map((vehicleMake) =>
//             validVehicleMakes.includes(vehicleMake.vehicleMakeID)
//               ? { ...vehicleMake, isActive }
//               : vehicleMake
//           )
//         )
//         toast.success(`Selected VehicleMakes updated`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })

//         setSelectedVehicleMakes([])
//         setIsActiveButtonEnabled(false)
//       }
//     } catch (error) {
//       toast.error(`Error updating VehicleMake: ${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value)
//   }

//   const filteredVehicleMakes = vehicleMakes.filter((vehicleMake) => {
//     const matchesSearchTerm =
//       vehicleMake.vehicleMakeName
//         ?.toLowerCase()
//         .includes(searchTerm.toLowerCase()) ||
//       vehicleMake.vehicleMakeType
//         ?.toLowerCase()
//         .includes(searchTerm.toLowerCase()) ||
//       vehicleMake.vehicleMakeDescription
//         ?.toLowerCase()
//         .includes(searchTerm.toLowerCase())

//     const vehicleMakeDate = new Date(vehicleMake.createdDate)

//     const matchesDateRange = (() => {
//       const fromDateObj = fromDate ? new Date(fromDate) : null
//       const toDateObj = toDate ? new Date(toDate) : null

//       const formatDate = (date) => {
//         if (!date) return null
//         const day = date.getDate().toString().padStart(2, '0')
//         const month = (date.getMonth() + 1).toString().padStart(2, '0')
//         const year = date.getFullYear()
//         return `${day}/${month}/${year}`
//       }

//       const formattedVehicleMakeDate = formatDate(vehicleMakeDate)
//       const formattedFromDate = formatDate(fromDateObj)
//       const formattedToDate = formatDate(toDateObj)

//       if (fromDateObj && toDateObj) {
//         return (
//           formattedVehicleMakeDate >= formattedFromDate &&
//           formattedVehicleMakeDate <= formattedToDate
//         ) // Inclusive range
//       } else if (fromDateObj) {
//         return formattedVehicleMakeDate === formattedFromDate
//       }

//       return true
//     })()

//     return matchesSearchTerm && matchesDateRange
//   })

//   const handleResetFilter = () => {
//     setFromDate('')
//     setToDate('')
//   }

//   const handleCheckboxChange = (vehicleMakeId) => {
//     setSelectedVehicleMakes((prevSelected) => {
//       let newSelection
//       newSelection = prevSelected.includes(vehicleMakeId)
//         ? prevSelected.filter((id) => id !== vehicleMakeId)
//         : [...prevSelected, vehicleMakeId]
//       return newSelection
//     })
//   }

//   const totalPages = Math.ceil(
//     filteredVehicleMakes.length / Number(itemsPerPage)
//   )
//   const startIndex = (currentPage - 1) * Number(itemsPerPage)

//   const endIndex = startIndex + Number(itemsPerPage)

//   const currentVehicleMakes = filteredVehicleMakes
//     .reverse()
//     .slice(startIndex, endIndex)

//   const handleSerialNumberChange = (index) => {
//     setCurrentPage(index + 1)
//     setSerialNumber(index * Number(itemsPerPage))
//   }

//   const calculateDisplayRange = () => {
//     return `${currentVehicleMakes.length}/${totalVehicleMakes}`
//   }

//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => {
//         const newPage = prevPage - 1
//         setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
//         return newPage
//       })
//     }
//   }

//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage((prevPage) => {
//         const newPage = prevPage + 1
//         setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
//         return newPage
//       })
//     }
//   }

//   return (
//     <>
//       <div className="section-1">
//         <div className="row">
//           <div className="col-lg-4 col-md-12">
//             <div className="left">
//               <div className="d-flex flex-row flex-wrap">
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     title="Add New"
//                     className="btn btn-light details-btnn"
//                     onClick={handleAddButtonClick}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Booking"
//                     >
//                       <i className="fa-solid fa-plus" type="button"></i>
//                     </span>
//                   </button>
//                 </div>{' '}
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     onClick={handleEditButtonClick}
//                     title="Edit"
//                     disabled={selectedVehicleMakes.length !== 1}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Edit"
//                     >
//                       <i className="fa-solid fa-pencil"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     onClick={handleDetailsVehicleMake}
//                     title="Details"
//                     disabled={selectedVehicleMakes.length !== 1}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Details"
//                     >
//                       <i
//                         className="fa-solid fa-credit-card"
//                         title="Pay Now"
//                       ></i>
//                     </span>
//                   </button>

//                   <div
//                     id="DetailsCard"
//                     tabindex="-1"
//                     aria-labelledby="CardDetailsModalLabel"
//                     className={`modal fade ${
//                       isModalOpen ? 'show d-block' : ''
//                     }`}
//                     onClick={handleBackdropClick}
//                     aria-hidden={!isModalOpen}
//                     style={
//                       isModalOpen
//                         ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                         : {}
//                     }
//                   >
//                     <div
//                       className="modal-dialog modal-dialog-scrollable"
//                       onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
//                     >
//                       <div className="modal-content">
//                         <div className="modal-header">
//                           <h1
//                             className="modal-title fs-5"
//                             id="DetailsModalLabel"
//                           >
//                             Card Details
//                           </h1>
//                           <button
//                             type="button"
//                             className="btn-close"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           ></button>
//                         </div>
//                         <div className="modal-body">
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Vehicle Make Name: '}</p>
//                             <p className="ms-2 mb-0">{vehicleMakeName}</p>
//                           </div>

//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Vehicle Make Type: '}</p>
//                             <p className="ms-2 mb-0">{vehicleMakeType}</p>
//                           </div>
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">
//                               {'Vehicle Make Description: '}
//                             </p>
//                             <p className="ms-2 mb-0">
//                               {vehicleMakeDescription}
//                             </p>
//                           </div>

//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">
//                               {'Vehicle Make Status: '}
//                             </p>
//                             <p className="ms-2 mb-0">
//                               {vehicleMakeStatus ? 'True' : 'False'}
//                             </p>
//                           </div>
//                         </div>

//                         <div className="modal-footer">
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           >
//                             Close
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     data-bs-toggle="modal"
//                     data-bs-target="#DeleteModal"
//                     title="Delete"
//                     disabled={selectedVehicleMakes.length === 0}
//                     onClick={handleDeleteButtonClick}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-trash"></i>
//                     </span>
//                   </button>

//                   <div
//                     id="DeleteModal"
//                     tabindex="-1"
//                     aria-labelledby="DeleteModalLabel"
//                     className={`modal fade ${
//                       isModalOpen ? 'show d-block' : ''
//                     }`}
//                     onClick={handleBackdropClick}
//                     aria-hidden={!isModalOpen}
//                     style={
//                       isModalOpen
//                         ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                         : {}
//                     }
//                   >
//                     <div className="modal-dialog modal-dialog-scrollable">
//                       <div className="modal-content">
//                         <div className="modal-header">
//                           <h1
//                             className="modal-title fs-5"
//                             id="DeleteModalLabel"
//                           >
//                             Confirmation
//                           </h1>
//                           <button
//                             type="button"
//                             className="btn-close"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           ></button>
//                         </div>

//                         <div className="modal-body">
//                           <div className="mb-3">
//                             <label className="fs-5">
//                               Do you want to delete{' '}
//                               {selectedVehicleMakes.length === 1
//                                 ? ` '${vehicleMakeName}' record?`
//                                 : `${selectedVehicleMakes.length} records?`}{' '}
//                             </label>
//                             <br />
//                           </div>
//                         </div>

//                         <div className="modal-footer">
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           >
//                             No
//                           </button>
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={handleDeleteVehicleMake}
//                           >
//                             Yes
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     title="Mark as active"
//                     onClick={() => handleToggleVehicleMakeStatus(true)}
//                     disabled={
//                       (selectedVehicleMakes.length === 0 &&
//                         !isActiveButtonEnabled) ||
//                       vehicleMakes
//                         .filter((vehicleMake) =>
//                           selectedVehicleMakes.includes(
//                             vehicleMake.vehicleMakeID
//                           )
//                         )
//                         .every((vehicleMake) => vehicleMake.isActive)
//                     }
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-toggle-on"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     title="Mark as inactive"
//                     onClick={() => handleToggleVehicleMakeStatus(false)}
//                     disabled={
//                       (selectedVehicleMakes.length === 0 &&
//                         !isActiveButtonEnabled) ||
//                       vehicleMakes
//                         .filter((vehicleMake) =>
//                           selectedVehicleMakes.includes(
//                             vehicleMake.vehicleMakeID
//                           )
//                         )
//                         .every((vehicleMake) => !vehicleMake.isActive)
//                     }
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-toggle-off"></i>
//                     </span>
//                   </button>
//                 </div>
//               </div>

//               <div
//                 id="VehicleMakemodelMake"
//                 tabIndex="-1"
//                 aria-labelledby="VehicleMakeModalLabel"
//                 className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
//                 onClick={handleBackdropClick}
//                 aria-hidden={!isModalOpen}
//                 style={
//                   isModalOpen
//                     ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                     : {}
//                 }
//               >
//                 <div
//                   className="modal-dialog modal-dialog-scrollable"
//                   onClick={(e) => e.stopPropagation()}
//                 >
//                   <div className="modal-content">
//                     <form
//                       id="add-new-user-form"
//                       onSubmit={
//                         isEditMode
//                           ? handleEditVehicleMake
//                           : handleCreateVehicleMake
//                       }
//                     >
//                       <div className="modal-header">
//                         <h5 className="modal-title" id="VehicleMakeModalLabel">
//                           {isEditMode
//                             ? 'Edit VehicleMake'
//                             : 'Add New VehicleMake'}
//                         </h5>
//                         <button
//                           type="button"
//                           className="btn-close"
//                           onClick={resetModal}
//                         ></button>
//                       </div>
//                       <div className="modal-body">
//                         <div className="mb-3">
//                           <label htmlFor="txtMake" className="form-label">
//                             Vehicle Make Name
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={vehicleMakeName}
//                             onChange={(e) => setVehicleMakeName(e.target.value)}
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Vehicle Make Type
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={vehicleMakeType}
//                             onChange={(e) => setVehicleMakeType(e.target.value)}
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Vehicle Make Description
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={vehicleMakeDescription}
//                             onChange={(e) =>
//                               setVehicleMakeDescription(e.target.value)
//                             }
//                             required
//                           />
//                         </div>

//                         <div className="mb-3 active-inactive-all">
//                           <div>
//                             <input
//                               type="radio"
//                               id="active"
//                               name="vehicleMakeStatus"
//                               value="true"
//                               onChange={handleStatusChange}
//                               checked={vehicleMakeStatus === true}
//                             />
//                             <label htmlFor="active">Active</label>
//                           </div>
//                           <div>
//                             <input
//                               type="radio"
//                               id="inactive"
//                               name="vehicleMakeStatus"
//                               value="false"
//                               onChange={handleStatusChange}
//                               checked={vehicleMakeStatus === false}
//                             />
//                             <label htmlFor="inactive">Inactive</label>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="modal-footer">
//                         <button
//                           type="button"
//                           className="cancel-btn"
//                           onClick={resetModal}
//                         >
//                           Close
//                         </button>
//                         <button type="submit" className="btn site-btn">
//                           {isEditMode
//                             ? 'Update VehicleMake'
//                             : 'Create VehicleMake'}
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-2 col-md-12 mt-2">
//             <div className="input-box ">
//               <input
//                 type="search"
//                 className="form-control"
//                 placeholder="Search..."
//                 value={searchTerm}
//                 onChange={handleSearchChange}
//               />
//             </div>
//           </div>

//           <div className="col-lg-6 col-md-12">
//             <div className="right mb-3 d-flex justify-content-between align-items-center">
//               <label className="mx-1">From: </label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={fromDate}
//                 onChange={(e) => {
//                   const newFromDate = e.target.value
//                   setFromDate(newFromDate)

//                   // Check if toDate is less than the new fromDate
//                   if (toDate && new Date(toDate) < new Date(newFromDate)) {
//                     setToDate('') // Clear toDate if it's less than fromDate
//                   }
//                 }}
//               />
//               <label className="mx-1">To: </label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={toDate}
//                 onChange={(e) => setToDate(e.target.value)}
//                 disabled={!fromDate}
//                 min={fromDate}
//               />
//               <div>
//                 <div className="p-2" title="Reset Date">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn"
//                     onClick={handleResetFilter}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Booking"
//                     >
//                       <i className="fa-solid fa-filter-circle-xmark"></i>
//                     </span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="table-responsive table-scroll">
//           <table className="table-faq">
//             <thead>
//               <tr>
//                 <th className="faqs-table-row-d1">
//                   <input
//                     type="checkbox"
//                     checked={
//                       selectedVehicleMakes.length === vehicleMakes.length
//                     }
//                     onChange={() =>
//                       setSelectedVehicleMakes(
//                         selectedVehicleMakes.length === vehicleMakes.length
//                           ? []
//                           : vehicleMakes.map(
//                               (vehicleMake) => vehicleMake.vehicleMakeID
//                             )
//                       )
//                     }
//                   />
//                 </th>
//                 <th scope="col" className="font faqs-table-row-d2">
//                   Sr No.
//                 </th>
//                 <th scope="col" className="faqs-table-row-d3">
//                   Vehicle Make Name
//                 </th>
//                 <th scope="col" className="faqs-table-row-d4">
//                   Vehicle Make Type
//                 </th>{' '}
//                 <th scope="col" className="faqs-table-row-d4">
//                   Vehicle Make Description
//                 </th>{' '}
//                 <th scope="col" className="faqs-table-row-d4">
//                   Created Date
//                 </th>
//                 <th scope="col" className="faqs-table-row-d4">
//                   Vehicle Models
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentVehicleMakes.map((vehicleMake, index) => (
//                 <tr
//                   className="faqs-table-row"
//                   title={`${
//                     vehicleMake.isActive ? 'Active Item' : 'Inactive Item'
//                   }`}
//                   key={vehicleMake.vehicleMakeID}
//                   style={
//                     vehicleMake.isActive ? { backgroundColor: '#D3D3D3' } : {}
//                   }
//                 >
//                   <td>
//                     <input
//                       type="checkbox"
//                       checked={selectedVehicleMakes.includes(
//                         vehicleMake.vehicleMakeID
//                       )}
//                       onChange={(e) =>
//                         handleCheckboxChange(
//                           vehicleMake.vehicleMakeID,
//                           index,
//                           e
//                         )
//                       }
//                     />
//                   </td>
//                   <td>{serialNumber + index + 1}</td>
//                   <td
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     className="table-btn"
//                     onClick={() =>
//                       handleDetailVehicleMake(vehicleMake.vehicleMakeID)
//                     }
//                   >
//                     {vehicleMake.vehicleMakeName}
//                   </td>
//                   <td
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     className="table-btn"
//                     onClick={() =>
//                       handleDetailVehicleMake(vehicleMake.vehicleMakeID)
//                     }
//                   >
//                     {vehicleMake.vehicleMakeType}
//                   </td>
//                   <td>{vehicleMake.vehicleMakeDescription}</td>
//                   <td>{vehicleMake.createdDate}</td>
//                   <td key={vehicleMake.vehicleMakeID}>
//                     <Link
//                       // to={`/vehicle-configuration/vehicleMake/${vehicleMake.vehicleMakeID}/vehicle-models`}
//                       to={`/vehicle-configuration/vehicle-make/${vehicleMake.vehicleMakeID}/vehicle-models`}

//                       // vehicle-make/:vehicleMakeID/vehicle-models
//                     >
//                       <i className="fa-solid fa-pencil text-black"></i>
//                     </Link>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         <nav aria-label="Page navigation example">
//           <div className="pagination-container">
//             <div className="pagination-left">
//               <div className="items-per-page-label">Items per page:</div>
//               <select
//                 name="faqPerPage"
//                 onChange={(e) => setItemPerPage(e.target.value)}
//                 className="items-per-page-select form-control"
//               >
//                 <option value="15">15</option>
//                 <option value="30">30</option>
//                 <option value="45">45</option>
//                 <option value="60">60</option>
//                 <option value="75">75</option>
//                 <option value="90">90</option>
//               </select>
//             </div>

//             <div className="pagination-right">
//               <div className="faq-display-range">
//                 Showing VehicleMakes: {calculateDisplayRange()}
//               </div>
//               <ul className="pagination-list pagination ">
//                 <li
//                   className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
//                 >
//                   <button
//                     className="page-link"
//                     onClick={handlePrevious}
//                     disabled={currentPage === 1}
//                   >
//                     Previous
//                   </button>
//                 </li>

//                 {[...Array(totalPages)].map((_, index) => (
//                   <li
//                     className={`page-item ${
//                       currentPage === index + 1 ? 'active' : ''
//                     }`}
//                     key={index}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => handleSerialNumberChange(index)}
//                     >
//                       {index + 1}
//                     </button>
//                   </li>
//                 ))}

//                 <li
//                   className={`page-item ${
//                     currentPage === totalPages ? 'disabled' : ''
//                   }`}
//                 >
//                   <button
//                     className="page-link"
//                     onClick={handleNext}
//                     disabled={currentPage === totalPages}
//                   >
//                     Next
//                   </button>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </nav>
//       </div>
//     </>
//   )
// }

// export default VehicleMakes

import { useEffect, useState } from 'react'
import useFetch from '../../../CustomHooks/useFetch'
import { API_BASE_URL } from '../../../Config/Config'
import { verifyToken } from '../../..'
import CustomLoading from '../../../Components/CustomHandling/LoadingAndError'
import { Slide, toast } from 'react-toastify'
import PageHeaderAndFooter from '../../../Components/PageHeaderAndFooter/PageHeaderAndFooter'
import { Link } from 'react-router-dom'
import moment from 'moment'

const VehicleMakes = () => {
  const showToast = (message, type = 'info', options = {}) => {
    const baseOptions = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: 'light',
      transition: Slide,
      ...options,
    }

    switch (type) {
      case 'success':
        toast.success(message, baseOptions)
        break
      case 'warn':
        toast.warn(message, baseOptions)
        break
      case 'error':
        toast.error(message, baseOptions)
        break
      case 'info':
      default:
        toast.info(message, baseOptions)
        break
    }
  }

  const vehicleMakesURL = `${API_BASE_URL}/api/Vehicle/GetAllDashMake`
  const [token, setToken] = useState('')

  const [vehicleMake, setVehicleMake] = useState({
    vehicleMakeName: '',
    type: '',
    password: '',
    isActive: null,
  })
  const [refreshDependency, setRefreshDependency] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [createState, setCreateState] = useState(false)
  const [editState, setEditState] = useState(false)
  const [blockState, setBlockState] = useState(false)
  const [deleteState, setDeleteState] = useState(false)

  const [expandedRows, setExpandedRows] = useState([])

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const {
    data: vehicleMakes,
    loading,
    error,
  } = useFetch(vehicleMakesURL, refreshDependency)

  console.log(vehicleMakes)

  const handleChange = (e) => {
    const { name, value } = e.target
    setVehicleMake((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleCreateOrEditVehicleMake = async (e) => {
    e.preventDefault()

    const newVehicleMake = {
      usersID: vehicleMake.userId || null,
      userName: vehicleMake.vehicleMakeName || null,
      password: editState ? 'dummydata1234' : vehicleMake.password,
      firstName: vehicleMake.firstName || null,
      lastName: vehicleMake.lastName || null,
      type: vehicleMake.type || null,
      phoneNo: vehicleMake.phoneNo || null,
    }

    const url = `${API_BASE_URL}/api/user/CreateUser`

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(newVehicleMake),
      })

      const result = await response.json()

      if (response.ok) {
        showToast(
          createState
            ? 'User successfully created.'
            : 'User successfully updated.',
          'success'
        )

        // Reset states and refresh
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setCreateState(false)
        setEditState(false)
      } else {
        // Warning toasts for errors
        Object.keys(result.errors).forEach((key) => {
          result.errors[key].forEach((message) => {
            showToast(`${key}: ${message}`, 'warn')
          })
        })
      }
    } catch (error) {
      // Error toast for network issues or unexpected errors
      showToast(`${error.message}`, 'error')
    }
  }

  const handleUpdateVehicleMake = async (e) => {
    e.preventDefault()
    const url = `${API_BASE_URL}/api/user/UpdateUserStatus`
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          userID: vehicleMake.userId,
          isActive: null,
          isDeleted: deleteState ? true : null,
          isBlocked: blockState ? true : null,
        }),
      })

      const updateVehicleMake = await response.json()
      console.log(updateVehicleMake)
      if (response.ok) {
        console.log(updateVehicleMake)

        const action = deleteState ? 'deleted' : 'blocked'
        showToast(`User '${vehicleMake.firstName}' ${action}`, 'success')
        setRefreshDependency((prev) => prev + 1)
        setIsModalOpen(false)
        setDeleteState(false)
        setBlockState(false)
        setExpandedRows([])
      } else {
        showToast('Failed to Delete User.', 'warn')
      }
    } catch (error) {
      showToast(`${error.message}`, 'error')
    }
  }

  const handleOpenModal = (actionType, userID) => {
    const selectedVehicleMake = vehicleMakes.find(
      (mod) => mod.userID === userID
    )

    const resetVehicleMake = () => ({
      vehicleMakeName: '',
      userId: null,
      password: '',
      firstName: '',
      lastName: '',
      type: '',
      phoneNo: '',
      companyName: '',
      roleId: null,
    })

    const populateVehicleMake = (user) => ({
      vehicleMakeName: user.vehicleMakeName,
      type: user.type,
      password: '',
    })

    // Reset modal states
    setCreateState(false)
    setEditState(false)
    setBlockState(false)
    setDeleteState(false)
    setIsModalOpen(false)

    const handleAction = (stateSetter) => {
      if (selectedVehicleMake) {
        setVehicleMake(populateVehicleMake(selectedVehicleMake))
        stateSetter(true)
        setIsModalOpen(true)
      }
    }

    switch (actionType) {
      case 'create':
        setVehicleMake(resetVehicleMake())
        setCreateState(true)
        setIsModalOpen(true)
        break

      case 'edit':
        handleAction(setEditState)
        break

      case 'block':
        handleAction(setBlockState)
        break

      case 'delete':
        handleAction(setDeleteState)
        break

      default:
        break
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setCreateState(false)
    setEditState(false)
    setBlockState(false)
    setDeleteState(false)
  }

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]))
  }

  if (loading) return <CustomLoading />
  if (error) return <div>Error: {error}</div>

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New Vehicle Make"
        handleOpenModal={() => handleOpenModal('create', null)}
        data={vehicleMakes}
        filterFields={['vehicleMakeName', 'companyName', 'userName']}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Vehicle Make Name</th>
                  <th scope="col">Vehicle Make Type</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <>
                    <tr
                      key={data.userID}
                      className={`${
                        expandedRows.includes(index) ? 'table-info' : ''
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{startIndex + index + 1}</td>
                      <td>{data.vehicleMakeName || 'N/A'}</td>
                      <td>{data.vehicleMakeType || 'N/A'}</td>
                      <td>
                        {moment(data.createdDate).format(
                          'DD MMMM YYYY, HH:mm:ss A'
                        ) || 'N/A'}
                      </td>
                      <td className="d-flex gap-2">
                        <button
                          className="btn btn_modal"
                          onClick={() => handleOpenModal('edit', data.userID)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn_modal_sec"
                          onClick={() => handleOpenModal('delete', data.userID)}
                        >
                          Delete
                        </button>
                        <button
                          className={`${
                            data.isActive
                              ? 'btn btn_modal_active'
                              : 'btn btn_modal_inactive'
                          }`}
                          onClick={() => handleOpenModal('block', data.userID)}
                        >
                          {data.isActive ? 'InActive' : 'Active'}
                        </button>
                        <Link
                          to={`/vehicle-configuration/vehicle-make/${data.vehicleMakeID}/vehicle-models`}
                          className="btn btn_modal"
                        >
                          Vehicle Model
                        </Link>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? 'show' : ''}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? 'block' : 'none',
              background: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={handleCloseModal}
          >
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  createState || editState
                    ? handleCreateOrEditVehicleMake
                    : deleteState || blockState
                    ? handleUpdateVehicleMake
                    : ''
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : deleteState
                        ? 'Delete'
                        : blockState
                        ? 'Block'
                        : ''}{' '}
                      User
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body row">
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="vehicleMakeName" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="vehicleMakeName"
                        value={vehicleMake.vehicleMakeName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="type" className="form-label">
                        Type
                      </label>
                      <input
                        type="type"
                        className="form-control"
                        name="type"
                        value={vehicleMake.type}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="password" className="form-label">
                        Status
                      </label>
                      <select
                        className="form-select"
                        name="isActive"
                        value={vehicleMake.isActive}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        createState
                          ? 'btn btn_modal_sec'
                          : editState
                          ? 'btn btn_modal_sec'
                          : blockState || deleteState
                          ? 'btn btn_modal'
                          : ''
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        createState
                          ? 'btn btn_modal'
                          : editState
                          ? 'btn btn_modal'
                          : blockState || deleteState
                          ? 'btn btn_modal_sec'
                          : ''
                      }
                      type="submit"
                    >
                      {createState
                        ? 'Create'
                        : editState
                        ? 'Update'
                        : blockState
                        ? 'Block'
                        : deleteState
                        ? 'Delete'
                        : ''}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default VehicleMakes
