import React, { useState } from 'react'
import './../Forms/login.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { API_BASE_DOMAIN, API_BASE_URL, AUTH_TOKEN } from '../../Config/Config'
import Cookies from 'js-cookie'
import { LoginLoader } from '../../Components/CustomHandling/LoadingAndError'
export default function Login() {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate() // You need to call useNavigate as a function here

  async function handleLogin(e) {
    e.preventDefault()
    setLoading(true)

    if (!userName || !password) {
      setLoading(false)
      alert('Please fill in both username and password.')
      return
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/User/Login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: AUTH_TOKEN,
        },
        body: JSON.stringify({
          userName: userName,
          password: password,
        }),
      })
      const data = await response.json()
      // if (!response.ok) {
      //   throw new Error('Invalid credentials')
      // }
      console.log(data)
      if (response.ok) {
        Cookies.set(
          'userSession',
          JSON.stringify({
            userName: data.userNaame,
            password: data.password,
            tokenID: data.tokenID,
            authToken: data.jwtToken,
            userId: data.userID,
          })
        )

        navigate('/') // Call navigate to redirect to the homepage after successful login
        setLoading(false)
      } else {
        setLoading(false)
        alert('Invalid credentials')
      }
    } catch (err) {
      setLoading(false)
      alert('Invalid credentials. Please try again.')
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <>
      {loading && <LoginLoader />}
      {/* {loading && < />} */}
      <form onSubmit={handleLogin}>
        <div className="login-container">
          <div className="login-form">
            <h2>Login</h2>

            <label htmlFor="exampleInputEmail1" className="form-label">
              <b>Username:</b>
              <input
                type="text"
                className="form-control login-form-new"
                placeholder="Enter your username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </label>

            <label htmlFor="exampleInputEmail1" className="form-label">
              <b>Password:</b>
              <input
                type={passwordVisible ? 'text' : 'password'}
                className="form-control login-form-new"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
            </label>
            <div>
              <p>
                <NavLink to="/forget-password">Forget Password? </NavLink>
              </p>

              <p>
                Not a member? <NavLink to="/signup">Register</NavLink>
              </p>
            </div>
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
