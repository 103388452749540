// import React, { useEffect, useState } from 'react'
// import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
// import { toast, Slide, ToastContainer } from 'react-toastify'
// import { API_BASE_URL } from '../../../Config/Config'
// import Cookies from 'js-cookie'

// function Terminals() {
//   const { terminalID } = useParams()
//   const navigate = useNavigate()
//   // const data = useLoaderData()

//   const [terminals, setTerminals] = useState([])
//   const [selectedTerminals, setSelectedTerminals] = useState([])

//   const [terminalName, setTerminalName] = useState('')
//   const [terminalType, setTerminalType] = useState('')
//   const [terminalDescription, setTerminalDescription] = useState('')
//   const [terminalCode, setTerminalCode] = useState('')
//   const [terminalStatus, setTerminalStatus] = useState(true)

//   const [isModalOpen, setIsModalOpen] = useState(false)
//   const [isEditMode, setIsEditMode] = useState(false)
//   const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
//   const [searchTerm, setSearchTerm] = useState('')
//   const [fromDate, setFromDate] = useState('')
//   const [toDate, setToDate] = useState('')
//   const [currentPage, setCurrentPage] = useState(1)
//   const [itemsPerPage, setItemPerPage] = useState(15)
//   const [token, setToken] = useState({})
//   const [serialNumber, setSerialNumber] = useState(
//     (currentPage - 1) * itemsPerPage
//   )
//   const totalTerminals = terminals.length

//   useEffect(() => {
//     const verifyToken = async () => {
//       const cookieValue = Cookies.get('userSession')
//       let parsedCookie
//       parsedCookie = JSON.parse(cookieValue)
//       const { tokenID, authToken, userId } = parsedCookie
//       setToken({ tokenID, authToken, userId })
//     }
//     verifyToken()
//   }, [])

//   useEffect(() => {
//     const fetchTerminals = async () => {
//       try {
//         const response = await fetch(
//           `${API_BASE_URL}/api/Terminal/GetAllbyTerminal/${terminalID}`
//         )
//         if (!response.ok) {
//           throw new Error('Network response was not ok')
//         }

//         const data = await response.json()
//         const inbounds = data.filter(
//           (terminal) => terminal.terminalType === 'InBound'
//         )
//         const outbounds = data.filter(
//           (terminal) => terminal.terminalType === 'OutBound'
//         )

//         setTerminals([...inbounds, ...outbounds])
//       } catch (error) {
//         console.error('There was a problem with the fetch operation:', error)
//       }
//     }
//     fetchTerminals()
//   }, [terminalID])

//   function handleBack() {
//     navigate(-1)
//   }

//   const handleSelectChange = (event) => {
//     const selectedValue = event.target.value
//     setTerminalType(selectedValue)
//   }
//   function handleStatusChange(e) {
//     setTerminalStatus(e.target.value === 'true')
//   }

//   // Handle Terminal creation
//   const handleCreateTerminal = async (e) => {
//     e.preventDefault()
//     const newTerminal = {
//       terminalName,
//       terminalType,
//       terminalDescription,
//       terminalCode,
//       isActive: terminalStatus,
//       isDeleted: false,
//     }
//     console.log(newTerminal)

//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/api/Terminal/CreateTerminalDetails/${terminalID}`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token.authToken,
//           },
//           body: JSON.stringify(newTerminal),
//         }
//       )

//       if (response.ok) {
//         const createdTerminal = await response.json()
//         setTerminals((prevTerminals) => [...prevTerminals, createdTerminal])
//         toast.success(`Terminal '${createdTerminal.terminalName}' created`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//         resetModal()
//       } else {
//         toast.warn(`Failed to create Terminal.`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//       }
//     } catch (error) {
//       toast.error(`${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   // Show modal for adding new Terminal
//   const handleAddButtonClick = () => {
//     setIsModalOpen(true)
//   }

//   // Reset modal state
//   const resetModal = () => {
//     setIsModalOpen(false)
//     setIsEditMode(false)
//     setSelectedTerminals([])

//     setTerminalName('')
//     setTerminalType('')
//     setTerminalDescription('')
//     setTerminalCode('')

//     setTerminalStatus(true)
//   }

//   // Handle backdrop click to close modal
//   const handleBackdropClick = (e) => {
//     if (e.target.classList.contains('modal')) {
//       resetModal()
//     }
//   }

//   // Handle Terminal update
//   const handleEditTerminal = async (e) => {
//     e.preventDefault()
//     const selectedTerminal = terminals.find(
//       (terminal) => terminal.terminalID === selectedTerminals[0]
//     )

//     console.log(selectedTerminal)
//     const updatedTerminal = {
//       ...selectedTerminal,
//       terminalName,
//       terminalType,
//       terminalDescription,
//       terminalCode,
//       isActive: terminalStatus,
//       isDeleted: false,
//     }

//     try {
//       const response = await fetch(
//         `${API_BASE_URL}/api/Terminal/UpdateTerminalDetails/${selectedTerminal.terminalID}`,
//         {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: token.authToken,
//           },
//           body: JSON.stringify(updatedTerminal),
//         }
//       )

//       if (response.ok) {
//         const updatedTerminalData = await response.json()
//         setTerminals((prevTerminals) =>
//           prevTerminals.map((terminal) =>
//             terminal.terminalID === updatedTerminalData.terminalID
//               ? updatedTerminalData
//               : terminal
//           )
//         )
//         console.log(updatedTerminal)
//         console.log(terminals)
//         toast.success(`Terminal '${selectedTerminal.terminalName}' updated`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//         resetModal()
//       } else {
//         toast.warn(`Failed to update Terminal.`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })
//       }
//     } catch (error) {
//       toast.error(`Error updating Terminal: ${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   // Show modal for editing Terminal
//   const handleEditButtonClick = () => {
//     const selectedTerminal = terminals.find(
//       (terminal) => terminal.terminalID === selectedTerminals[0]
//     )
//     console.log(selectedTerminal)
//     setTerminalName(selectedTerminal.terminalName)
//     setTerminalType(selectedTerminal.terminalType)
//     setTerminalDescription(selectedTerminal.terminalDescription)
//     setTerminalCode(selectedTerminal.terminalCode)
//     setTerminalStatus(selectedTerminal.isActive)
//     setIsEditMode(true)
//     setIsModalOpen(true)
//   }

//   // handle for Details Terminal
//   const handleDetailsTerminal = () => {
//     const selectedTerminal = terminals.find(
//       (terminal) => terminal.terminalID === selectedTerminals[0]
//     )
//     setTerminalName(selectedTerminal.terminalName)
//     setTerminalType(selectedTerminal.terminalType)
//     setTerminalDescription(selectedTerminal.terminalDescription)
//     setTerminalCode(selectedTerminal.terminalCode)

//     setTerminalStatus(selectedTerminal.isActive)
//   }

//   const handleDetailTerminal = (singleTerminal) => {
//     const selectedTerminal = terminals.find(
//       (terminal) => terminal.terminalID === singleTerminal
//     )
//     setTerminalName(selectedTerminal.terminalName)
//     setTerminalType(selectedTerminal.terminalType)
//     setTerminalDescription(selectedTerminal.terminalDescription)
//     setTerminalCode(selectedTerminal.terminalCode)

//     setTerminalStatus(selectedTerminal.isActive)
//   }

//   // Handle Terminal deletion
//   const handleDeleteTerminal = async () => {
//     const selectedTerminal = { terminalID: selectedTerminals }
//     console.log(selectedTerminal)
//     try {
//       const response = await fetch(`${API_BASE_URL}/api/Terminal/BulkDelete`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: token.authToken,
//         },
//         body: JSON.stringify(selectedTerminal),
//       })

//       if (response.ok) {
//         setTerminals((prevTerminals) =>
//           prevTerminals.filter(
//             (terminal) => !selectedTerminals.includes(terminal.terminalID)
//           )
//         )
//         toast.success(`Selected Terminals deleted`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//         })
//         setSelectedTerminals([])
//       }
//     } catch (error) {
//       toast.error(`Error deleting Terminals: ${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//       })
//     }
//     setIsModalOpen(false)
//     setTerminalName('')
//     setTerminalType('')
//     setTerminalDescription('')
//     setTerminalCode('')
//   }

//   // Show modal for deleting Terminal
//   const handleDeleteButtonClick = () => {
//     const selectedTerminal = terminals.find(
//       (terminal) => terminal.terminalID === selectedTerminals[0]
//     )
//     setTerminalName(selectedTerminal.terminalName)
//   }

//   // Handle Terminal activation/deactivation
//   const handleToggleTerminalStatus = async (isActive) => {
//     try {
//       const validTerminals = selectedTerminals.filter((id) => {
//         const terminal = terminals.find(
//           (terminal) => terminal.terminalID === id
//         )
//         return terminal.terminalName
//       })
//       const selectedTerminal = {
//         terminalID: selectedTerminals,
//         isActive: isActive,
//       }
//       const response = await fetch(`${API_BASE_URL}/api/Terminal/BulkActive`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: token.authToken,
//         },
//         body: JSON.stringify(selectedTerminal),
//       })

//       if (response.ok) {
//         setTerminals((prevTerminals) =>
//           prevTerminals.map((terminal) =>
//             validTerminals.includes(terminal.terminalID)
//               ? { ...terminal, isActive }
//               : terminal
//           )
//         )
//         toast.success(`Selected Terminals updated`, {
//           position: 'top-right',
//           autoClose: false,
//           closeOnClick: true,
//           draggable: true,
//           progress: true,
//           theme: 'light',
//           transition: Slide,
//         })

//         setSelectedTerminals([])
//         setIsActiveButtonEnabled(false)
//       }
//     } catch (error) {
//       toast.error(`Error updating Terminal: ${error}`, {
//         position: 'top-right',
//         autoClose: false,
//         closeOnClick: true,
//         draggable: true,
//         progress: true,
//         theme: 'light',
//         transition: Slide,
//       })
//     }
//   }

//   // Handle search term change
//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value)
//   }

//   const filteredTerminals = terminals.filter((terminal) => {
//     const matchesSearchTerm =
//       terminal.terminalName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       terminal.terminalType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       terminal.terminalAddress
//         ?.toLowerCase()
//         .includes(searchTerm.toLowerCase()) ||
//       terminal.terminalCountry
//         ?.toLowerCase()
//         .includes(searchTerm.toLowerCase()) ||
//       terminal.terminalState
//         ?.toLowerCase()
//         .includes(searchTerm.toLowerCase()) ||
//       terminal.terminalCity?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       terminal.terminalZipCode?.toLowerCase().includes(searchTerm.toLowerCase())

//     const terminalDate = new Date(terminal.createdDate)

//     const matchesDateRange = (() => {
//       const fromDateObj = fromDate ? new Date(fromDate) : null
//       const toDateObj = toDate ? new Date(toDate) : null

//       // Helper function to format date to 'dd/mm/yyyy'
//       const formatDate = (date) => {
//         if (!date) return null
//         const day = date.getDate().toString().padStart(2, '0') // Ensure two-digit day
//         const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month
//         const year = date.getFullYear()
//         return `${day}/${month}/${year}`
//       }

//       const formattedTerminalDate = formatDate(terminalDate)
//       const formattedFromDate = formatDate(fromDateObj)
//       const formattedToDate = formatDate(toDateObj)

//       if (fromDateObj && toDateObj) {
//         return (
//           formattedTerminalDate >= formattedFromDate &&
//           formattedTerminalDate <= formattedToDate
//         ) // Inclusive range
//       } else if (fromDateObj) {
//         return formattedTerminalDate === formattedFromDate // Exact match for fromDate
//       }

//       return true // If no fromDate or toDate, show all data
//     })()

//     return matchesSearchTerm && matchesDateRange
//   })

//   const handleResetFilter = () => {
//     setFromDate('')
//     setToDate('')
//   }

//   const handleCheckboxChange = (terminalId) => {
//     setSelectedTerminals((prevSelected) => {
//       let newSelection

//       newSelection = prevSelected.includes(terminalId)
//         ? prevSelected.filter((id) => id !== terminalId)
//         : [...prevSelected, terminalId]

//       const hasTerminalWithBoth = newSelection.some((id) => {
//         const selectedTerminal = terminals.find(
//           (terminal) => terminal.terminalID === id
//         )
//         return selectedTerminal.terminalName && selectedTerminal.terminalCode
//       })

//       setIsActiveButtonEnabled(hasTerminalWithBoth)
//       return newSelection
//     })
//   }

//   const handleSerialNumberChange = (index) => {
//     setCurrentPage(index + 1)
//     setSerialNumber(index * 15)
//   }

//   const totalPages = Math.ceil(filteredTerminals.length / itemsPerPage)
//   const startIndex = (currentPage - 1) * itemsPerPage
//   const endIndex = startIndex + itemsPerPage
//   const currentTerminals = filteredTerminals
//     .reverse()
//     .slice(startIndex, endIndex)

//   const calculateDisplayRange = () => {
//     return `${currentTerminals.length}/${totalTerminals}`
//   }

//   // Handle the "Previous" button
//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => {
//         const newPage = prevPage - 1
//         setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
//         return newPage
//       })
//     }
//   }

//   // Handle the "Next" button
//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage((prevPage) => {
//         const newPage = prevPage + 1
//         setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
//         return newPage
//       })
//     }
//   }
//   return (
//     <>
//       <div className="section-1">
//         <div className="row">
//           <div className="col-lg-4 col-md-12">
//             <div className="left">
//               <div className="d-flex flex-row flex-wrap">
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     title="Add New"
//                     className="btn btn-light details-btnn"
//                     onClick={handleBack}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Booking"
//                     >
//                       <i class="fa-solid fa-arrow-left"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     title="Add New"
//                     className="btn btn-light details-btnn"
//                     onClick={handleAddButtonClick}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Booking"
//                     >
//                       <i className="fa-solid fa-plus" type="button"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     onClick={handleEditButtonClick}
//                     title="Edit"
//                     disabled={selectedTerminals.length !== 1}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Edit"
//                     >
//                       <i className="fa-solid fa-pencil"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     onClick={handleDetailsTerminal}
//                     title="Details"
//                     disabled={selectedTerminals.length !== 1}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Details"
//                     >
//                       <i
//                         className="fa-solid fa-credit-card"
//                         title="Pay Now"
//                       ></i>
//                     </span>
//                   </button>

//                   <div
//                     id="DetailsCard"
//                     tabindex="-1"
//                     aria-labelledby="CardDetailsModalLabel"
//                     className={`modal fade ${
//                       isModalOpen ? 'show d-block' : ''
//                     }`}
//                     onClick={handleBackdropClick}
//                     aria-hidden={!isModalOpen}
//                     style={
//                       isModalOpen
//                         ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                         : {}
//                     }
//                   >
//                     <div
//                       className="modal-dialog modal-dialog-scrollable"
//                       onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
//                     >
//                       <div className="modal-content">
//                         <div className="modal-header">
//                           <h1
//                             className="modal-title fs-5"
//                             id="DetailsModalLabel"
//                           >
//                             Card Details
//                           </h1>
//                           <button
//                             type="button"
//                             className="btn-close"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           ></button>
//                         </div>
//                         <div className="modal-body">
//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Terminal Name: '}</p>
//                             <p className="ms-2 mb-0">{terminalName}</p>
//                           </div>

//                           <div className="personal-dtails mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Terminal Type: '}</p>
//                             <p className="ms-2 mb-0">{terminalType}</p>
//                           </div>

//                           <div className="personal-dtails  mb-2 d-flex">
//                             <p className="ms-2 mb-0">{'Terminal Country: '}</p>
//                             <p className="ms-2 mb-0">{terminalCode}</p>
//                             <div className="ms-2 mb-0"></div>
//                           </div>
//                         </div>

//                         <div className="modal-footer">
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           >
//                             Close
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     data-bs-toggle="modal"
//                     data-bs-target="#DeleteModal"
//                     title="Delete"
//                     disabled={selectedTerminals.length === 0}
//                     onClick={handleDeleteButtonClick}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-trash"></i>
//                     </span>
//                   </button>

//                   <div
//                     id="DeleteModal"
//                     tabindex="-1"
//                     aria-labelledby="DeleteModalLabel"
//                     className={`modal fade ${
//                       isModalOpen ? 'show d-block' : ''
//                     }`}
//                     onClick={handleBackdropClick}
//                     aria-hidden={!isModalOpen}
//                     style={
//                       isModalOpen
//                         ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                         : {}
//                     }
//                   >
//                     <div className="modal-dialog modal-dialog-scrollable">
//                       <div className="modal-content">
//                         <div className="modal-header">
//                           <h1
//                             className="modal-title fs-5"
//                             id="DeleteModalLabel"
//                           >
//                             Confirmation
//                           </h1>
//                           <button
//                             type="button"
//                             className="btn-close"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           ></button>
//                         </div>

//                         <div className="modal-body">
//                           <div className="mb-3">
//                             <label className="fs-5">
//                               Do you want to delete{' '}
//                               {selectedTerminals.length === 1
//                                 ? ` '${terminalName}' record?`
//                                 : `${selectedTerminals.length} records?`}{' '}
//                             </label>
//                             <br />
//                           </div>
//                         </div>

//                         <div className="modal-footer">
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={resetModal}
//                           >
//                             No
//                           </button>
//                           <button
//                             type="button"
//                             className="cancel-btn"
//                             data-bs-dismiss="modal"
//                             onClick={handleDeleteTerminal}
//                           >
//                             Yes
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     title="Mark as active"
//                     onClick={() => handleToggleTerminalStatus(true)}
//                     disabled={
//                       (selectedTerminals.length === 0 &&
//                         !isActiveButtonEnabled) ||
//                       terminals
//                         .filter((terminal) =>
//                           selectedTerminals.includes(terminal.terminalID)
//                         )
//                         .every((terminal) => terminal.isActive)
//                     }
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-toggle-on"></i>
//                     </span>
//                   </button>
//                 </div>
//                 <div className="p-1">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn me-2 me-2"
//                     title="Mark as inactive"
//                     onClick={() => handleToggleTerminalStatus(false)}
//                     disabled={
//                       (selectedTerminals.length === 0 &&
//                         !isActiveButtonEnabled) ||
//                       terminals
//                         .filter((terminal) =>
//                           selectedTerminals.includes(terminal.terminalID)
//                         )
//                         .every((terminal) => !terminal.isActive)
//                     }
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Delete"
//                     >
//                       <i className="fa-solid fa-toggle-off"></i>
//                     </span>
//                   </button>
//                 </div>
//               </div>

//               <div
//                 id="TerminalmodelMake"
//                 tabIndex="-1"
//                 aria-labelledby="TerminalModalLabel"
//                 className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
//                 onClick={handleBackdropClick}
//                 aria-hidden={!isModalOpen}
//                 style={
//                   isModalOpen
//                     ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
//                     : {}
//                 }
//               >
//                 <div
//                   className="modal-dialog modal-dialog-scrollable"
//                   onClick={(e) => e.stopPropagation()}
//                 >
//                   <div className="modal-content">
//                     <form
//                       id="add-new-user-form"
//                       onSubmit={
//                         isEditMode ? handleEditTerminal : handleCreateTerminal
//                       }
//                     >
//                       <div className="modal-header">
//                         <h5 className="modal-title" id="TerminalModalLabel">
//                           {isEditMode ? 'Edit Terminal' : 'Add New Terminal'}
//                         </h5>
//                         <button
//                           type="button"
//                           className="btn-close"
//                           onClick={resetModal}
//                         ></button>
//                       </div>
//                       <div className="modal-body">
//                         <div className="mb-3">
//                           <label htmlFor="txtMake" className="form-label">
//                             Terminal Name
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={terminalName}
//                             onChange={(e) => setTerminalName(e.target.value)}
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Terminal Type
//                           </label>
//                           {/* <input
//                             type="text"
//                             className="form-control"
//                             value={terminalType}
//                             onChange={(e) => setTerminalType(e.target.value)}
//                             required
//                           /> */}
//                           <select
//                             name="terminalType"
//                             value={terminalType}
//                             onChange={handleSelectChange}
//                             className="form-control"
//                           >
//                             <option value="" disabled>
//                               Select Terminal
//                             </option>
//                             <option value="InBound">Inbound Terminal</option>
//                             <option value="OutBound">Outbound Terminal</option>
//                           </select>
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Terminal Description
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={terminalDescription}
//                             onChange={(e) =>
//                               setTerminalDescription(e.target.value)
//                             }
//                             required
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label
//                             htmlFor="txtDescription"
//                             className="form-label"
//                           >
//                             Terminal Code
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             value={terminalCode}
//                             onChange={(e) => setTerminalCode(e.target.value)}
//                             required
//                           />
//                         </div>

//                         <div className="mb-3 active-inactive-all">
//                           <div>
//                             <input
//                               type="radio"
//                               id="active"
//                               name="terminalStatus"
//                               value="true"
//                               onChange={handleStatusChange}
//                               checked={terminalStatus === true}
//                             />
//                             <label htmlFor="active">Active</label>
//                           </div>
//                           <div>
//                             <input
//                               type="radio"
//                               id="inactive"
//                               name="terminalStatus"
//                               value="false"
//                               onChange={handleStatusChange}
//                               checked={terminalStatus === false}
//                             />
//                             <label htmlFor="inactive">Inactive</label>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="modal-footer">
//                         <button
//                           type="button"
//                           className="cancel-btn"
//                           onClick={resetModal}
//                         >
//                           Close
//                         </button>
//                         <button type="submit" className="btn site-btn">
//                           {isEditMode ? 'Update Terminal' : 'Create Terminal'}
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-2 col-md-12 mt-2">
//             <div className="input-box ">
//               <input
//                 type="search"
//                 className="form-control"
//                 placeholder="Search..."
//                 value={searchTerm}
//                 onChange={handleSearchChange}
//               />
//             </div>
//           </div>

//           <div className="col-lg-6 col-md-12">
//             <div className="right mb-3 d-flex justify-content-between align-items-center">
//               <label className="mx-1">From: </label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={fromDate}
//                 onChange={(e) => {
//                   const newFromDate = e.target.value
//                   setFromDate(newFromDate)

//                   // Check if toDate is less than the new fromDate
//                   if (toDate && new Date(toDate) < new Date(newFromDate)) {
//                     setToDate('') // Clear toDate if it's less than fromDate
//                   }
//                 }}
//               />
//               <label className="mx-1">To: </label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={toDate}
//                 onChange={(e) => setToDate(e.target.value)}
//                 disabled={!fromDate}
//                 min={fromDate}
//               />
//               <div>
//                 <div className="p-2" title="Reset Date">
//                   <button
//                     type="button"
//                     className="btn btn-light details-btnn"
//                     onClick={handleResetFilter}
//                   >
//                     <span
//                       data-bs-toggle="tooltip"
//                       data-bs-placement="top"
//                       data-bs-title="Booking"
//                     >
//                       <i className="fa-solid fa-filter-circle-xmark"></i>
//                     </span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="table-responsive table-scroll">
//           <table className="table-faq">
//             <thead>
//               <tr>
//                 <th className="faqs-table-row-d1">
//                   <input
//                     type="checkbox"
//                     checked={
//                       selectedTerminals.length === terminals.length &&
//                       terminals.length >= 1
//                     }
//                     onChange={() =>
//                       setSelectedTerminals(
//                         selectedTerminals.length === terminals.length
//                           ? []
//                           : terminals.map((terminal) => terminal.terminalID)
//                       )
//                     }
//                   />
//                 </th>
//                 <th scope="col" className="font faqs-table-row-d2">
//                   Sr No.
//                 </th>
//                 <th scope="col" className="faqs-table-row-d3">
//                   Terminal Name
//                 </th>
//                 <th scope="col" className="faqs-table-row-d4">
//                   Terminal Type
//                 </th>{' '}
//                 <th scope="col" className="faqs-table-row-d4">
//                   Terminal Address
//                 </th>{' '}
//                 <th scope="col" className="faqs-table-row-d4">
//                   Terminal Country
//                 </th>{' '}
//                 <th scope="col" className="faqs-table-row-d4">
//                   Created Date
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTerminals.map((terminal, index) => (
//                 <tr
//                   className="faqs-table-row"
//                   title={`${
//                     terminal.isActive ? 'Active Item' : 'Inactive Item'
//                   }`}
//                   key={terminal.terminalID}
//                   style={
//                     terminal.isActive ? { backgroundColor: '#D3D3D3' } : {}
//                   }
//                 >
//                   <td>
//                     <input
//                       type="checkbox"
//                       checked={selectedTerminals.includes(terminal.terminalID)}
//                       onChange={(e) =>
//                         handleCheckboxChange(terminal.terminalID, index, e)
//                       }
//                     />
//                   </td>
//                   <td>{serialNumber + index + 1}</td>
//                   <td
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     className="table-btn"
//                     onClick={() => handleDetailTerminal(terminal.terminalID)}
//                   >
//                     {terminal.terminalName}
//                   </td>
//                   <td
//                     data-bs-toggle="modal"
//                     data-bs-target="#DetailsCard"
//                     className="table-btn"
//                     onClick={() => handleDetailTerminal(terminal.terminalID)}
//                   >
//                     {terminal.terminalType}
//                   </td>
//                   <td>{terminal.terminalDescription}</td>
//                   <td>{terminal.terminalCode}</td>
//                   <td>{terminal.terminalState}</td>
//                   <td>{terminal.terminalCity}</td>
//                   <td>{terminal.terminalZipCode}</td>
//                   <td>{terminal.createdDate}</td>
//                   {/* <td>{terminal.createdDate}</td> */}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <nav aria-label="Page navigation example">
//             <div className="pagination-container">
//               <div className="pagination-left">
//                 <div className="items-per-page-label">Items per page:</div>
//                 <select
//                   name="faqPerPage"
//                   onChange={(e) => setItemPerPage(e.target.value)}
//                   className="items-per-page-select form-control"
//                 >
//                   <option value="15">15</option>
//                   <option value="30">30</option>
//                   <option value="45">45</option>
//                   <option value="60">60</option>
//                   <option value="75">75</option>
//                   <option value="90">90</option>
//                 </select>
//               </div>

//               <div className="pagination-right">
//                 <div className="faq-display-range">
//                   Showing Terminals: {calculateDisplayRange()}
//                 </div>
//                 <ul className="pagination-list pagination ">
//                   <li
//                     className={`page-item ${
//                       currentPage === 1 ? 'disabled' : ''
//                     }`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={handlePrevious}
//                       disabled={currentPage === 1}
//                     >
//                       Previous
//                     </button>
//                   </li>

//                   {[...Array(totalPages)].map((_, index) => (
//                     <li
//                       className={`page-item ${
//                         currentPage === index + 1 ? 'active' : ''
//                       }`}
//                       key={index}
//                     >
//                       <button
//                         className="page-link"
//                         onClick={() => handleSerialNumberChange(index)}
//                       >
//                         {index + 1}
//                       </button>
//                     </li>
//                   ))}

//                   <li
//                     className={`page-item ${
//                       currentPage === totalPages ? 'disabled' : ''
//                     }`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={handleNext}
//                       disabled={currentPage === totalPages}
//                     >
//                       Next
//                     </button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </nav>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Terminals

import { useEffect, useState } from "react";
import useFetch from "../../../CustomHooks/useFetch";
import { API_BASE_URL } from "../../../Config/Config";
import { verifyToken } from "../../..";
import CustomLoading from "../../../Components/CustomHandling/LoadingAndError";
import { Slide, toast } from "react-toastify";
import PageHeaderAndFooter from "../../../Components/PageHeaderAndFooter/PageHeaderAndFooter";
import { Link } from "react-router-dom";

const Terminals = () => {
  const showToast = (message, type = "info", options = {}) => {
    const baseOptions = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: "light",
      transition: Slide,
      ...options,
    };

    switch (type) {
      case "success":
        toast.success(message, baseOptions);
        break;
      case "warn":
        toast.warn(message, baseOptions);
        break;
      case "error":
        toast.error(message, baseOptions);
        break;
      case "info":
      default:
        toast.info(message, baseOptions);
        break;
    }
  };

  const terminalsURL = `${API_BASE_URL}/api/Terminal/GetAllDashTerminalDetails`;
  const rolesURL = `${API_BASE_URL}/api/UserRoles/GetAllRoles`;
  const [token, setToken] = useState("");

  const [userData, setUserData] = useState({
    terminalName: "",
    userId: null,
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    // promotion: '',
    roleId: null,
  });
  // const [roles, setRoles] = useState([])
  const [refreshDependency, setRefreshDependency] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createState, setCreateState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [blockState, setBlockState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken();
      setToken(authToken);
    };
    fetchToken();
  }, []);

  const {
    data: terminals,
    loading,
    error,
  } = useFetch(terminalsURL, refreshDependency);

  const { data: roles } = useFetch(rolesURL, refreshDependency);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleValidateUser = () => {
    if (userData.password === userData.confirmPassword) {
      return true;
    } else {
      showToast(`Password does not match`, "error");
      return false;
    }
  };
  const handleCreateOrEditTerminal = async (e) => {
    e.preventDefault();

    const newTerminal = {
      usersID: userData.userId || null,
      userName: userData.terminalName || null,
      password: editState ? "dummydata1234" : userData.password,
      firstName: userData.firstName || null,
      lastName: userData.lastName || null,
      email: userData.email || null,
      phoneNo: userData.phoneNo || null,
      userTypeID: 0,
      companyName: userData.companyName || null,
      promotion: true,
      createdDate: null,
      createdBy: null,
      modifiedDate: null,
      modifiedBy: null,
      deletedDate: null,
      deletedBy: null,
      discountCode: null,
      roleId: userData.roleId || null,
    };

    const url = `${API_BASE_URL}/api/user/CreateUser`;
    if (editState || handleValidateUser()) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(newTerminal),
        });

        const result = await response.json();

        if (response.ok) {
          showToast(
            createState
              ? "User successfully created."
              : "User successfully updated.",
            "success"
          );

          // Reset states and refresh
          setRefreshDependency((prev) => prev + 1);
          setIsModalOpen(false);
          setCreateState(false);
          setEditState(false);
        } else {
          // Warning toasts for errors
          Object.keys(result.errors).forEach((key) => {
            result.errors[key].forEach((message) => {
              showToast(`${key}: ${message}`, "warn");
            });
          });
        }
      } catch (error) {
        // Error toast for network issues or unexpected errors
        showToast(`${error.message}`, "error");
      }
    }
  };

  const handleUpdateTerminal = async (e) => {
    e.preventDefault();
    const url = `${API_BASE_URL}/api/user/UpdateUserStatus`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          userID: userData.userId,
          isActive: null,
          isDeleted: deleteState ? true : null,
          isBlocked: blockState ? true : null,
        }),
      });

      const updateTerminal = await response.json();
      console.log(updateTerminal);
      if (response.ok) {
        console.log(updateTerminal);

        const action = deleteState ? "deleted" : "blocked";
        showToast(`User '${userData.firstName}' ${action}`, "success");
        setRefreshDependency((prev) => prev + 1);
        setIsModalOpen(false);
        setDeleteState(false);
        setBlockState(false);
        setExpandedRows([]);
      } else {
        showToast("Failed to Delete User.", "warn");
      }
    } catch (error) {
      showToast(`${error.message}`, "error");
    }
  };
  // console.log(terminals)

  // const handleOpenModal = (actionType, userID) => {
  //   const selectedTerminal = terminals.find((mod) => mod.userID === userID)
  //   console.log(selectedTerminal)

  //   const resetUserData = () => ({
  //     terminalName: '',
  //     userId: null,
  //     password: '',
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     phoneNo: '',
  //     companyName: '',
  //     roleId: null,
  //   })

  //   const populateUserData = (user) => ({
  //     terminalName: user.userName,
  //     userId: user.userID,
  //     password: '',
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     email: user.email,
  //     phoneNo: user.phoneNo.trim(),
  //     companyName: user.companyName,
  //     roleId: user.roleID,
  //   })

  //   // Reset modal states before setting new ones
  //   setCreateState(false)
  //   setEditState(false)
  //   setDeleteState(false)
  //   setIsModalOpen(false)

  //   switch (actionType) {
  //     case 'create':
  //       setUserData(resetUserData())
  //       setCreateState(true)
  //       setIsModalOpen(true)
  //       break

  //     case 'edit':
  //       if (selectedTerminal) {
  //         setUserData(populateUserData(selectedTerminal))
  //         setEditState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for editing')
  //       }
  //       break

  //     case 'block':
  //       if (selectedTerminal) {
  //         setUserData(populateUserData(selectedTerminal))
  //         setBlockState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for editing')
  //       }
  //       break

  //     case 'delete':
  //       if (selectedTerminal) {
  //         setUserData(populateUserData(selectedTerminal))
  //         setDeleteState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for deletion')
  //       }
  //       break

  //     default:
  //       console.error('Invalid action type')
  //       setCreateState(false)
  //       setEditState(false)
  //       setDeleteState(false)
  //       setIsModalOpen(false)
  //       break
  //   }
  // }
  const handleOpenModal = (actionType, userID) => {
    const selectedTerminal = terminals.find((mod) => mod.userID === userID);

    const resetUserData = () => ({
      terminalName: "",
      userId: null,
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      companyName: "",
      roleId: null,
    });

    const populateUserData = (user) => ({
      terminalName: user.userName,
      userId: user.userID,
      password: "",
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNo: user.phoneNo.trim(),
      companyName: user.companyName,
      roleId: user.roleID,
    });

    // Reset modal states
    setCreateState(false);
    setEditState(false);
    setBlockState(false);
    setDeleteState(false);
    setIsModalOpen(false);

    const handleAction = (stateSetter) => {
      if (selectedTerminal) {
        setUserData(populateUserData(selectedTerminal));
        stateSetter(true);
        setIsModalOpen(true);
      }
    };

    switch (actionType) {
      case "create":
        setUserData(resetUserData());
        setCreateState(true);
        setIsModalOpen(true);
        break;

      case "edit":
        handleAction(setEditState);
        break;

      case "block":
        handleAction(setBlockState);
        break;

      case "delete":
        handleAction(setDeleteState);
        break;

      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCreateState(false);
    setEditState(false);
    setBlockState(false);
    setDeleteState(false);
  };

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]));
  };

  if (loading) return <CustomLoading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New Terminal"
        handleOpenModal={() => handleOpenModal("create", null)}
        data={terminals}
        filterFields={["terminalName", "companyName", "userName"]}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Terminal Name</th>
                  <th scope="col">Terminal Type</th>
                  <th scope="col">Terminal Description</th>
                  <th scope="col">Created Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <>
                    <tr
                      key={data.userID}
                      className={`${
                        expandedRows.includes(index) ? "table-info" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{startIndex + index + 1}</td>
                      <td>{data.terminalName || "N/A"}</td>
                      <td>{data.terminalType || "N/A"}</td>
                      <td>{data.terminalDescription || "N/A"}</td>
                      <td>{data.createdDate || "N/A"}</td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr className="bg-light">
                        <td colSpan="9">
                          <div className="row g-2 p-3">
                            <div className="col-md-6">
                              <strong>Terminal Code: </strong>{" "}
                              {data.terminalCode || "N/A"}
                            </div>
                            <div className="col-md-6">
                              <strong>Terminal Cost: </strong>
                              {data.terminalCost || "N/A"}
                            </div>{" "}
                            <div className="col-md-6"></div>
                            <td className="d-flex gap-2">
                              <button
                                className="btn btn_modal"
                                onClick={() =>
                                  handleOpenModal("edit", data.userID)
                                }
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn_modal_sec"
                                onClick={() =>
                                  handleOpenModal("delete", data.userID)
                                }
                              >
                                Delete
                              </button>
                              <button
                                className="btn btn_modal_sec "
                                onClick={() =>
                                  handleOpenModal("block", data.userID)
                                }
                              >
                                Block
                              </button>
                            </td>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? "show" : ""}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? "block" : "none",
              background: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={handleCloseModal}
          >
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  createState || editState
                    ? handleCreateOrEditTerminal
                    : deleteState || blockState
                    ? handleUpdateTerminal
                    : ""
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {/* {userID ? 'Update terminal name' : 'Create terminal name'} */}
                      {createState
                        ? "Create"
                        : editState
                        ? "Update"
                        : deleteState
                        ? "Delete"
                        : blockState
                        ? "Block"
                        : ""}{" "}
                      User
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body row">
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="terminalName" className="form-label">
                        User Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="terminalName"
                        value={userData.terminalName}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>

                    {createState ? (
                      <>
                        <div className="mb-3 col-lg-6">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>

                        <div className="mb-3 col-lg-6">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={userData.firstName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={userData.lastName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="phoneNo" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNo"
                        value={userData.phoneNo}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="roleId" className="form-label">
                        User Types
                      </label>
                      <select
                        className="form-control"
                        name="roleId"
                        value={userData.roleId || ""}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      >
                        <option value="" disabled>
                          Select User Type
                        </option>
                        {roles.map((role) => (
                          <option key={role.roleId} value={role.roleId}>
                            {role.roleName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="companyName" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        value={userData.companyName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        createState
                          ? "btn btn_modal_sec"
                          : editState
                          ? "btn btn_modal_sec"
                          : blockState || deleteState
                          ? "btn btn_modal"
                          : ""
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        createState
                          ? "btn btn_modal"
                          : editState
                          ? "btn btn_modal"
                          : blockState || deleteState
                          ? "btn btn_modal_sec"
                          : ""
                      }
                      type="submit"
                    >
                      {createState
                        ? "Create"
                        : editState
                        ? "Update"
                        : blockState
                        ? "Block"
                        : deleteState
                        ? "Delete"
                        : ""}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Terminals;
