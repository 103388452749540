import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../Components/Navbar/Logo'
import NavbarLink from '../Components/NabarLink/NavbarLink'

function BookingNavbar() {
  return (
    <div className="container-fluid">
      <div className="row h-100">
        <div className="col-lg-12 col-md-12 p-0">
          <>
            <div
              id="bdSidebar"
              className="for-width sidebar-bg-color d-flex flex-column text-white offcanvas-md offcanvas-start"
            >
              <Logo />
              <ul className="mynav nav nav-pills flex-column mb-auto p-3">
                {/* Back Link */}
                <NavbarLink
                  name="Go Back"
                  logo={<i className="fa-solid fa-arrow-left"></i>}
                  to="/"
                />
                {/* Reused NavbarLink Component */}
                <NavbarLink
                  name="Quotes"
                  logo={<i className="fa-solid fa-bars"></i>}
                  to="/bookings/quotes"
                />
                <NavbarLink
                  name="Deleted Quotes"
                  logo={<i className="fa-solid fa-undo"></i>}
                  to="/bookings/deleted-quotes"
                />
                <NavbarLink
                  name="Active Bookings"
                  logo={<i className="fa-solid fa-bar-chart"></i>}
                  to="/bookings/active-bookings"
                />
                <NavbarLink
                  name="Meet Unassigned Bookings"
                  logo={<i className="fa-solid fa-unlock"></i>}
                  to="/bookings/meet-unassigned-bookings"
                />
                <NavbarLink
                  name="Meet Assigned Bookings"
                  logo={<i className="fa-solid fa-thumb-tack"></i>}
                  to="/bookings/meet-assigned-bookings"
                />
                <NavbarLink
                  name="Land Unassigned Bookings"
                  logo={<i className="fa-solid fa-unlock"></i>}
                  to="/bookings/dispatched-unassigned-bookings"
                />
                <NavbarLink
                  name="Land Assigned Bookings"
                  logo={<i className="fa-solid fa-thumb-tack"></i>}
                  to="/bookings/dispatched-assigned-bookings"
                />
                <NavbarLink
                  name="Cancelled Bookings"
                  logo={<i className="fa-solid fa-ban"></i>}
                  to="/bookings/cancelled-bookings"
                />
                <NavbarLink
                  name="Completed Bookings"
                  logo={<i className="fa-solid fa-list"></i>}
                  to="/bookings/completed-bookings"
                />
                <NavbarLink
                  name="Discount Codes"
                  logo={<i className="fa-solid fa-briefcase"></i>}
                  to="/bookings/discountcode"
                />
              </ul>

              <hr />
              <div className="Privacy-terms d-flex align-items-center p-3">
                <i className="fa-solid fa-shield-halved me-2"></i>
                <p className="mb-0">
                  {/* <a href="#" className="text-white text-decoration-none">
              Terms of Service
            </a> */}
                  <NavLink
                    to="news-letter/terms-of-services"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    {' '}
                    - Terms Of Services
                  </NavLink>
                  {/* <a href="#" className="text-white text-decoration-none">
              - Privacy Policy
            </a> */}
                  {/* <li> */}
                  <NavLink
                    to="news-letter/privacy-policy"
                    className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                  >
                    - Privacy Policy
                  </NavLink>
                  {/* </li> */}
                </p>
              </div>
            </div>

            <div className="right-bg-contant">
              <div className="p-2 d-md-none d-flex text-white sidebar-bg-color forsmallscreen">
                <a
                  href="#"
                  className="text-white"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#bdSidebar"
                >
                  <i className="fa-solid fa-bars"></i>
                </a>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default BookingNavbar
