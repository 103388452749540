import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import { API_BASE_URL } from '../../../Config/Config'
import { verifyToken } from '../../../index.js'
import useFetch from '../../../CustomHooks/useFetch'
function VehicleDetails() {
  const [token, setToken] = useState('')
  const apiURLVehicleDetails = `${API_BASE_URL}/api/Vehicle/GetAllVehicleDetails`
  const apiURLVehicleMakes = `${API_BASE_URL}/api/Vehicle/allVehicles`
  const apiURLVehicleColors = `${API_BASE_URL}/api/Vehicle/GetAllColors`
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken()
      setToken(authToken)
    }
    fetchToken()
  }, [])

  const data = useLoaderData()
  const [vehicleDetails, setVehicleDetails] = useState(data)
  const [vehicleMakes, setVehicleMakes] = useState([])
  const [vehicleModels, setVehicleModels] = useState([])
  const [vehicleColors, setVehicleColors] = useState([])
  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState([])

  const [vehicleName, setVehicleName] = useState('')
  const [registrationNumber, setRegistrationNumber] = useState('')
  const [vehicleVersion, setVehicleVersion] = useState('')
  const [vehicleDetailStatus, setVehicleDetailStatus] = useState(true)
  const [vehicleMakeName, setVehicleMakeName] = useState()
  const [vehicleMakeID, setVehicleMakeID] = useState()
  const [vehicleModelName, setVehicleModelName] = useState()
  const [vehicleModelID, setVehicleModelID] = useState()
  const [vehicleColorName, setVehicleColorName] = useState()
  const [vehicleColorID, setVehicleColorID] = useState()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemPerPage] = useState(15)
  const [serialNumber, setSerialNumber] = useState(
    (currentPage - 1) * itemsPerPage
  )
  const totalVehicleDetails = vehicleDetails.length

  const { triggerData: triggerDataVehicleDetails } = useFetch(
    apiURLVehicleDetails,
    token,
    selectedVehicleDetails
  )
  console.log(vehicleDetails)

  useEffect(() => {
    if (triggerDataVehicleDetails) {
      setVehicleDetails(triggerDataVehicleDetails)
    }
  }, [vehicleDetails, selectedVehicleDetails, triggerDataVehicleDetails])

  // Get all active Vehicle Make
  useEffect(() => {
    const fetchTriggerData = async () => {
      try {
        const response = await fetch(apiURLVehicleMakes, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })

        if (!response.ok) {
          throw new Error('Failed to fetch triggerData')
        }

        const result = await response.json()
        setVehicleMakes(result)
      } catch (err) {
        // setError(err.message)
      } finally {
        // setLoading(false)
      }
    }

    if (apiURLVehicleMakes) {
      fetchTriggerData()
    }
  }, [apiURLVehicleMakes, token])

  // Get all active Vehicle Colors
  useEffect(() => {
    const GetAllColors = async () => {
      try {
        const response = await fetch(apiURLVehicleColors, {
          headers: {
            'ngrok-skip-browser-warning': 'true',
          },
        })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setVehicleColors(data)
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    GetAllColors()
  }, [])
  const handleMakeChange = (e) => {
    const selectedId = e.target.value

    if (!(selectedId == 0)) {
      const selecteMake = vehicleMakes.find(
        (make) => make.vehicleMakeID === parseInt(selectedId)
      )
      setVehicleModels(selecteMake?.vehicleModels)
      setVehicleMakeName(selecteMake?.vehicleMakeName)
      setVehicleMakeID(selecteMake?.vehicleMakeID)
    }
    if (selectedId == 0) {
      setVehicleMakeName('Other')
      setVehicleMakeID(null)
    }
  }

  const handleModelChange = (e) => {
    const selectedId = e.target.value
    const selectedModel = vehicleModels?.find(
      (make) => make.vehicleModelID === parseInt(selectedId)
    )
    setVehicleModelName(selectedModel?.vehicleModelName)
    setVehicleModelID(selectedModel?.vehicleModelID)
  }

  const handleColourChange = (e) => {
    const selectedId = e.target.value
    const selectedColour = vehicleColors?.find(
      (colour) => colour.vehicleColorID === parseInt(selectedId)
    )
    setVehicleColorName(selectedColour?.vehicleColorName)
    setVehicleColorID(selectedColour?.vehicleColorID)
  }

  function handleStatusChange(e) {
    setVehicleDetailStatus(e.target.value === 'true')
  }

  const handleCreateVehicleDetail = async (e) => {
    e.preventDefault()
    const newVehicleDetail = {
      vehicleName: vehicleName,
      registrationNumber: registrationNumber,
      vehicleVersion: vehicleVersion,
    }

    console.log(newVehicleDetail)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Vehicle/CreateVehicleDetails`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(newVehicleDetail),
        }
      )

      const createdVehicleDetail = await response.json()
      console.log(createdVehicleDetail)
      if (response.ok) {
        toast.success(
          `VehicleDetail '${createdVehicleDetail.vehicleName}' created`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to create VehicleDetail.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleAddButtonClick = () => {
    setIsModalOpen(true)
  }

  const resetModal = () => {
    setIsModalOpen(false)
    setIsEditMode(false)
    setSelectedVehicleDetails([])
    setVehicleName('')
    setRegistrationNumber('')
    setVehicleVersion('')
    setVehicleDetailStatus(true)
  }

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      resetModal()
    }
  }

  const handleEditVehicleDetail = async (e) => {
    e.preventDefault()
    const selectedVehicleDetail = vehicleDetails.find(
      (vehicleDetail) =>
        vehicleDetail.vehicleDetailsID === selectedVehicleDetails[0]
    )

    const updatedVehicleDetail = {
      ...selectedVehicleDetail,
      vehicleName,
      registrationNumber,
      vehicleVersion,
      isActive: vehicleDetailStatus,
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/VehicleController/UpdateColorDetails/${selectedVehicleDetail.vehicleDetailsID}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(updatedVehicleDetail),
        }
      )

      if (response.ok) {
        console.log(updatedVehicleDetail)
        console.log(vehicleDetails)
        toast.success(
          `VehicleDetail '${selectedVehicleDetail.vehicleName}' updated`,
          {
            position: 'top-right',
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            progress: true,
            theme: 'light',
            transition: Slide,
          }
        )
        resetModal()
      } else {
        toast.warn(`Failed to update VehicleDetail.`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })
      }
    } catch (error) {
      toast.error(`Error updating VehicleDetail: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleEditButtonClick = () => {
    const selectedVehicleDetail = vehicleDetails.find(
      (vehicleDetail) =>
        vehicleDetail.vehicleDetailsID === selectedVehicleDetails[0]
    )
    setVehicleName(selectedVehicleDetail.vehicleName)
    setRegistrationNumber(selectedVehicleDetail.registrationNumber)
    setVehicleVersion(selectedVehicleDetail.vehicleVersion)
    setVehicleDetailStatus(selectedVehicleDetail.isActive)

    setIsEditMode(true)
    setIsModalOpen(true)
  }

  const handleDetailsVehicleDetail = () => {
    const selectedVehicleDetail = vehicleDetails.find(
      (vehicleDetail) =>
        vehicleDetail.vehicleDetailsID === selectedVehicleDetails[0]
    )
    setVehicleName(selectedVehicleDetail.vehicleName)
    setRegistrationNumber(selectedVehicleDetail.registrationNumber)
    setVehicleVersion(selectedVehicleDetails.vehicleVersion)
    setVehicleDetailStatus(selectedVehicleDetail.isActive)
  }

  const handleDetailVehicleDetail = (singleVehicleDetail) => {
    const selectedVehicleDetail = vehicleDetails.find(
      (vehicleDetail) => vehicleDetail.vehicleDetailsID === singleVehicleDetail
    )
    setVehicleName(selectedVehicleDetail.vehicleName)
    setRegistrationNumber(selectedVehicleDetail.registrationNumber)
    setVehicleVersion(selectedVehicleDetails.vehicleVersion)
    setVehicleDetailStatus(selectedVehicleDetail.isActive)
  }

  const handleDeleteVehicleDetail = async () => {
    const selectedVehicleDetail = { vehicleDetailsID: selectedVehicleDetails }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Vehicle/BulkDeleteVehicleDetail`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(selectedVehicleDetail),
        }
      )

      if (response.ok) {
        toast.success(`Selected VehicleDetails deleted`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
        })
        setSelectedVehicleDetails([])
      }
    } catch (error) {
      toast.error(`Error deleting VehicleDetails: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
      })
    }
    setIsModalOpen(false)
    setVehicleName('')
    setRegistrationNumber('')
    setVehicleVersion('')
  }

  const handleDeleteButtonClick = () => {
    const selectedVehicleDetail = vehicleDetails.find(
      (vehicleDetail) =>
        vehicleDetail.vehicleDetailsID === selectedVehicleDetails[0]
    )
    setVehicleName(selectedVehicleDetail.vehicleName)
  }

  const handleToggleVehicleDetailStatus = async (isActive) => {
    try {
      const selectedVehicleDetail = {
        vehicleDetailsID: selectedVehicleDetails,
        isActive: isActive,
      }
      const response = await fetch(
        `${API_BASE_URL}/api/Vehicle/BulkActiveVehicleDetail`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(selectedVehicleDetail),
        }
      )

      if (response.ok) {
        toast.success(`Selected VehicleDetails updated`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: true,
          theme: 'light',
          transition: Slide,
        })

        setSelectedVehicleDetails([])
        setIsActiveButtonEnabled(false)
      }
    } catch (error) {
      toast.error(`Error updating VehicleDetail: ${error}`, {
        position: 'top-right',
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        progress: true,
        theme: 'light',
        transition: Slide,
      })
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredVehicleDetails = vehicleDetails.filter((vehicleDetail) => {
    const matchesSearchTerm =
      vehicleDetail.vehicleName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      vehicleDetail.vehicleVersion
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      vehicleDetail.registrationNumber
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())

    const vehicleDetailDate = new Date(vehicleDetail.createdDate)

    const matchesDateRange = (() => {
      const fromDateObj = fromDate ? new Date(fromDate) : null
      const toDateObj = toDate ? new Date(toDate) : null

      const formatDate = (date) => {
        if (!date) return null
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      }

      const formattedVehicleDetailDate = formatDate(vehicleDetailDate)
      const formattedFromDate = formatDate(fromDateObj)
      const formattedToDate = formatDate(toDateObj)

      if (fromDateObj && toDateObj) {
        return (
          formattedVehicleDetailDate >= formattedFromDate &&
          formattedVehicleDetailDate <= formattedToDate
        ) // Inclusive range
      } else if (fromDateObj) {
        return formattedVehicleDetailDate === formattedFromDate
      }

      return true
    })()

    return matchesSearchTerm && matchesDateRange
  })

  const handleResetFilter = () => {
    setFromDate('')
    setToDate('')
  }

  const handleCheckboxChange = (vehicleDetailsID) => {
    setSelectedVehicleDetails((prevSelected) => {
      let newSelection
      newSelection = prevSelected.includes(vehicleDetailsID)
        ? prevSelected.filter((id) => id !== vehicleDetailsID)
        : [...prevSelected, vehicleDetailsID]
      return newSelection
    })
  }

  const totalPages = Math.ceil(
    filteredVehicleDetails.length / Number(itemsPerPage)
  )
  const startIndex = (currentPage - 1) * Number(itemsPerPage)

  const endIndex = startIndex + Number(itemsPerPage)

  const currentVehicleDetails = filteredVehicleDetails
    .reverse()
    .slice(startIndex, endIndex)

  const handleSerialNumberChange = (index) => {
    setCurrentPage(index + 1)
    setSerialNumber(index * Number(itemsPerPage))
  }

  const calculateDisplayRange = () => {
    return `${currentVehicleDetails.length}/${totalVehicleDetails}`
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1
        setSerialNumber((newPage - 1) * itemsPerPage) // Continue numbering on each page
        return newPage
      })
    }
  }

  return (
    <>
      <div className="section-1">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="left">
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    type="button"
                    title="Add New"
                    className="btn btn-light details-btnn"
                    onClick={handleAddButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-plus" type="button"></i>
                    </span>
                  </button>
                </div>{' '}
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    onClick={handleEditButtonClick}
                    title="Edit"
                    disabled={selectedVehicleDetails.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Edit"
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    onClick={handleDetailsVehicleDetail}
                    title="Details"
                    disabled={selectedVehicleDetails.length !== 1}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Details"
                    >
                      <i
                        className="fa-solid fa-credit-card"
                        title="Pay Now"
                      ></i>
                    </span>
                  </button>

                  <div
                    id="DetailsCard"
                    tabindex="-1"
                    aria-labelledby="CardDetailsModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div
                      className="modal-dialog modal-dialog-scrollable"
                      onClick={(e) => e.stopPropagation()} // Prevent click inside modal content from closing
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DetailsModalLabel"
                          >
                            Card Details
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Vehicle Name: '}</p>
                            <p className="ms-2 mb-0">{vehicleName}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Vehicle Description: '}
                            </p>
                            <p className="ms-2 mb-0">{registrationNumber}</p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Vehicle Version: '}</p>
                            <p className="ms-2 mb-0">{vehicleVersion}</p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Vehicle Make: '}</p>
                            <p className="ms-2 mb-0">{vehicleMakeID}</p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Vehicle Model: '}</p>
                            <p className="ms-2 mb-0">{vehicleModelID}</p>
                          </div>
                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">{'Vehicle Color: '}</p>
                            <p className="ms-2 mb-0">{vehicleColorID}</p>
                          </div>

                          <div className="personal-dtails mb-2 d-flex">
                            <p className="ms-2 mb-0">
                              {'Vehicle Color Status: '}
                            </p>
                            <p className="ms-2 mb-0">
                              {vehicleDetailStatus ? 'True' : 'False'}
                            </p>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    title="Delete"
                    disabled={selectedVehicleDetails.length === 0}
                    onClick={handleDeleteButtonClick}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </span>
                  </button>

                  <div
                    id="DeleteModal"
                    tabindex="-1"
                    aria-labelledby="DeleteModalLabel"
                    className={`modal fade ${
                      isModalOpen ? 'show d-block' : ''
                    }`}
                    onClick={handleBackdropClick}
                    aria-hidden={!isModalOpen}
                    style={
                      isModalOpen
                        ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="DeleteModalLabel"
                          >
                            Confirmation
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="mb-3">
                            <label className="fs-5">
                              Do you want to delete{' '}
                              {selectedVehicleDetails.length === 1
                                ? ` '${vehicleName}' record?`
                                : `${selectedVehicleDetails.length} records?`}{' '}
                            </label>
                            <br />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={resetModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="cancel-btn"
                            data-bs-dismiss="modal"
                            onClick={handleDeleteVehicleDetail}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as active"
                    onClick={() => handleToggleVehicleDetailStatus(true)}
                    disabled={
                      (selectedVehicleDetails.length === 0 &&
                        !isActiveButtonEnabled) ||
                      vehicleDetails
                        .filter((vehicleDetail) =>
                          selectedVehicleDetails.includes(
                            vehicleDetail.vehicleDetailsID
                          )
                        )
                        .every((vehicleDetail) => vehicleDetail.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-on"></i>
                    </span>
                  </button>
                </div>
                <div className="p-1">
                  <button
                    type="button"
                    className="btn btn-light details-btnn me-2 me-2"
                    title="Mark as inactive"
                    onClick={() => handleToggleVehicleDetailStatus(false)}
                    disabled={
                      (selectedVehicleDetails.length === 0 &&
                        !isActiveButtonEnabled) ||
                      vehicleDetails
                        .filter((vehicleDetail) =>
                          selectedVehicleDetails.includes(
                            vehicleDetail.vehicleDetailsID
                          )
                        )
                        .every((vehicleDetail) => !vehicleDetail.isActive)
                    }
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Delete"
                    >
                      <i className="fa-solid fa-toggle-off"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div
                id="VehicleDetailmodelMake"
                tabIndex="-1"
                aria-labelledby="VehicleDetailModalLabel"
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                onClick={handleBackdropClick}
                aria-hidden={!isModalOpen}
                style={
                  isModalOpen
                    ? { display: 'block', background: 'rgba(0, 0, 0, 0.5)' }
                    : {}
                }
              >
                <div
                  className="modal-dialog modal-dialog-scrollable"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="modal-content">
                    <form
                      id="add-new-user-form"
                      onSubmit={
                        isEditMode
                          ? handleEditVehicleDetail
                          : handleCreateVehicleDetail
                      }
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          id="VehicleDetailModalLabel"
                        >
                          {isEditMode
                            ? 'Edit Vehicle Details'
                            : 'Add New Vehicle Details'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={resetModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="txtMake" className="form-label">
                            Vehicle Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={vehicleName}
                            onChange={(e) => setVehicleName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Vehicle Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={registrationNumber}
                            onChange={(e) =>
                              setRegistrationNumber(e.target.value)
                            }
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Vehicle Version
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={vehicleVersion}
                            onChange={(e) => setVehicleVersion(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Vehicle Make
                          </label>
                          <select
                            onChange={handleMakeChange}
                            value={vehicleMakeID}
                            className="form-control"
                          >
                            <option value="0">Other</option>
                            {vehicleMakes.map((make) => (
                              <>
                                <option
                                  key={make.vehicleMakeID}
                                  value={make.vehicleMakeID}
                                >
                                  {make.vehicleMakeName}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>{' '}
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Vehicle Model
                          </label>
                          <select
                            className="form-control"
                            onChange={handleModelChange}
                            value={vehicleModelID || ''}
                          >
                            <option value="">Other</option>
                            {vehicleModels?.map((model) => (
                              <option
                                key={model.vehicleModelID}
                                value={model.vehicleModelID}
                              >
                                {model?.vehicleModelName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="txtDescription"
                            className="form-label"
                          >
                            Vehicle Colour
                          </label>
                          <select
                            className="form-control"
                            onChange={handleColourChange}
                            value={vehicleColorID}
                          >
                            <option>Other</option>
                            {vehicleColors?.map((colour) => (
                              <option
                                key={colour.vehicleColorID}
                                value={colour.vehicleColorID}
                              >
                                {colour?.vehicleColorName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3 active-inactive-all">
                          <div>
                            <input
                              type="radio"
                              id="active"
                              name="vehicleDetailStatus"
                              value="true"
                              onChange={handleStatusChange}
                              checked={vehicleDetailStatus === true}
                            />
                            <label htmlFor="active">Active</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="inactive"
                              name="vehicleDetailStatus"
                              value="false"
                              onChange={handleStatusChange}
                              checked={vehicleDetailStatus === false}
                            />
                            <label htmlFor="inactive">Inactive</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={resetModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn site-btn">
                          {isEditMode
                            ? 'Update Vehicle Color'
                            : 'Create Vehicle Color'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 mt-2">
            <div className="input-box ">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="right mb-3 d-flex justify-content-between align-items-center">
              <label className="mx-1">From: </label>
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value
                  setFromDate(newFromDate)

                  // Check if toDate is less than the new fromDate
                  if (toDate && new Date(toDate) < new Date(newFromDate)) {
                    setToDate('') // Clear toDate if it's less than fromDate
                  }
                }}
              />
              <label className="mx-1">To: </label>
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                disabled={!fromDate}
                min={fromDate}
              />
              <div>
                <div className="p-2" title="Reset Date">
                  <button
                    type="button"
                    className="btn btn-light details-btnn"
                    onClick={handleResetFilter}
                  >
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Booking"
                    >
                      <i className="fa-solid fa-filter-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive table-scroll">
          <table className="table-faq">
            <thead>
              <tr>
                <th className="faqs-table-row-d1">
                  <input
                    type="checkbox"
                    checked={
                      selectedVehicleDetails.length === vehicleDetails.length
                    }
                    onChange={() =>
                      setSelectedVehicleDetails(
                        selectedVehicleDetails.length === vehicleDetails.length
                          ? []
                          : vehicleDetails.map(
                              (vehicleDetail) => vehicleDetail.vehicleDetailsID
                            )
                      )
                    }
                  />
                </th>
                <th scope="col" className="font faqs-table-row-d2">
                  Sr No.
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Vehicle Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Vehicle Description
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Vehicle Version
                </th>
                <th scope="col" className="faqs-table-row-d3">
                  Vehicle Make Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Vehicle Model Name
                </th>
                <th scope="col" className="faqs-table-row-d4">
                  Vehicle Color
                </th>{' '}
                <th scope="col" className="faqs-table-row-d4">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentVehicleDetails.map((vehicleDetail, index) => (
                <tr
                  className="faqs-table-row"
                  title={`${
                    vehicleDetail.isActive ? 'Active Item' : 'Inactive Item'
                  }`}
                  key={vehicleDetail.vehicleDetailsID}
                  style={
                    vehicleDetail.isActive ? { backgroundColor: '#D3D3D3' } : {}
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedVehicleDetails.includes(
                        vehicleDetail.vehicleDetailsID
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(
                          vehicleDetail.vehicleDetailsID,
                          index,
                          e
                        )
                      }
                    />
                  </td>
                  <td>{serialNumber + index + 1}</td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailVehicleDetail(vehicleDetail.vehicleDetailsID)
                    }
                  >
                    {vehicleDetail.vehicleName}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsCard"
                    className="table-btn"
                    onClick={() =>
                      handleDetailVehicleDetail(vehicleDetail.vehicleDetailsID)
                    }
                  >
                    {vehicleDetail.registrationNumber}
                  </td>
                  <td>{vehicleDetail.vehicleVersion}</td>
                  <td> {vehicleDetail.vehicleMakeID}</td>
                  <td> {vehicleDetail.vehcileModelID}</td>
                  <td> {vehicleDetail.vehicleColorID}</td>
                  <td>{vehicleDetail.createdDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <div className="pagination-container">
            <div className="pagination-left">
              <div className="items-per-page-label">Items per page:</div>
              <select
                name="faqPerPage"
                onChange={(e) => setItemPerPage(e.target.value)}
                className="items-per-page-select form-control"
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="75">75</option>
                <option value="90">90</option>
              </select>
            </div>

            <div className="pagination-right">
              <div className="faq-display-range">
                Showing VehicleDetails: {calculateDisplayRange()}
              </div>
              <ul className="pagination-list pagination ">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      currentPage === index + 1 ? 'active' : ''
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handleSerialNumberChange(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default VehicleDetails
export const getAllVehicleDetails = async () => {
  const response = await fetch(`${API_BASE_URL}`)

  return response.json()
}
