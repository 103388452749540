// import React from 'react'

// const VehicleModel = () => {
//   return (
//     <>
//       {/* <div className="section-1">

// <div className="row">
//   <div className="col-lg-4 col-md-4">
//     <div className="left">
//       <button type="button" className="btn site-btn" data-bs-toggle="modal" data-bs-target="#vehicle-ModelmodalModel">
//         Add New Model
//       </button>
//       <div className="modal fade" id="vehicle-ModelmodalModel" tabindex="-1" aria-labelledby="vehicle-ModelModalLabel"
//         aria-hidden="true">
//         <div className="modal-dialog modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="vehicle-ModelModalLabel">Add New Model</h5>
//               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//             </div>
//             <div className="modal-body">
//               <form>
//                 <div className="mb-3">
//                   <select className="form-select" aria-label="Default select Make">
//                     <option selected>Select Make</option>
//                     <option value="1">Honda</option>
//                     <option value="2">Toyota</option>
//                     <option value="3">Mercediez</option>
//                   </select>
//                 </div>
//                 <div className="mb-3">
//                   <label for="txtMake" className="form-label">Model Name</label>
//                   <input type="text" className="form-control" id="txtMake" placeholder="Vehicle Make"/>
//                 </div>

//                 <div className="mb-3">
//                   <label for="txtMakeType" className="form-label">Created On</label>
//                   <input type="text" className="form-control" id="txtMakeType" placeholder="Created On"/>
//                 </div>

//                 <div className="mb-3">
//                   <label for="txtDescription" className="form-label">Description</label>
//                   <textarea className="form-control" id="txtDescription" rows="3"></textarea>
//                 </div>
//               </form>
//             </div>
//             <div className="modal-footer">
//               <button type="button" className="cancel-btn" data-bs-dismiss="modal">Close</button>
//               <button type="button" className="btn site-btn">Save changes</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-8 col-md-8 mb-3">
//     <div className="right d-flex justify-content-between align-items-center">
//       <label className="mx-1">From: </label>
//       <input type="date" className="form-control" id="exampleFormControlInput1" placeholder="date"/>
//       <label className="mx-1">To: </label>
//       <input type="date" className="form-control" id="exampleFormControlInput1" placeholder="date"/>
//     </div>
//   </div>
// </div> */}
//            <div className="section-1">
//         <div className="row">
//           <div className="d-flex flex-row flex-wrap">
//             <div className="p-2">
//               {' '}
//               <button
//                 type="button"
//                 title="Add New"
//                 className="btn btn-light details-btnn"
//                 data-bs-toggle="modal"
//                 data-bs-target="#quotesmodelMake"
//               >
//                 <span
//                   data-bs-toggle="tooltip"
//                   data-bs-placement="top"
//                   data-bs-title="Booking"
//                 >
//                   <i className="fa-solid fa-plus" type="button"></i>
//                 </span>
//               </button>
//             </div>
//             <div
//               className="modal fade"
//               id="quotesmodelMake"
//               tabindex="-1"
//               aria-labelledby="quotesModalLabel"
//               aria-hidden="true"
//             >
//               <div className="modal-dialog modal-dialog-scrollable">
//                 <div className="modal-content ">
//                   <div className="modal-header">
//                     <h5 className="modal-title" id="quotesModalLabel">
//                       Add New Quote
//                     </h5>
//                     <button
//                       type="button"
//                       className="btn-close"
//                       data-bs-dismiss="modal"
//                       aria-label="Close"
//                     ></button>
//                   </div>
//                   <div className="modal-body ">
//                     <form>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Full Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Full Name"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Phone Number
//                         </label>
//                         <input
//                           type="tel"
//                           className="form-control"
//                           placeholder="Phone Number"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Customer Email
//                         </label>
//                         <input
//                           type="email"
//                           className="form-control"
//                           placeholder="Customer Email"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Discount Code
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Discount Code"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Start Date Time
//                         </label>
//                         <input
//                           type="datetime-local"
//                           className="form-control"
//                           placeholder="Start Date Time"
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           End Date Time
//                         </label>
//                         <input
//                           type="datetime-local"
//                           className="form-control"
//                           placeholder="End Date Time"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Total Cost
//                         </label>
//                         <input
//                           type="number"
//                           className="form-control"
//                           placeholder="Total Cost"
//                         />
//                       </div>

//                       <select
//                         className="form-select"
//                         aria-label="Default select example"
//                       >
//                         <option selected>Flying From</option>
//                         <option value="1">Afghanistan</option>
//                         <option value="2">Albania</option>
//                         <option value="3">Pakistan</option>
//                       </select>

//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           {' '}
//                           Inbound Flight Number
//                         </label>
//                         <input
//                           type="number"
//                           className="form-control"
//                           placeholder="Inbound Flight Number"
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Outbound Flight Number{' '}
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Outbound Flight Number"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           {' '}
//                           Outbound VehicleModel ID
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Outbound VehicleModel ID"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Inbound VehicleModel ID
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Inbound VehicleModel ID"
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Location
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Location"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Campany Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Campany Name"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Source
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Source"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Airport Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Airport Name"
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Discount Code
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Discount Code"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Product Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder=" Product Name"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Product Descrption
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Product Descrption"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Product Price
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Product Descrption"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMake" className="form-label">
//                           Airport ID
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Airport ID"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           FlightTo{' '}
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="FlightTo"
//                         />
//                       </div>
//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           InBoundFlight
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="InBoundFlight"
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           OutBoundFlight
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="OutBoundFlight"
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label>IsRigister</label>
//                         <br />
//                         <input
//                           type="radio"
//                           id="yes"
//                           name="IsRigister"
//                           value="yes"
//                         />
//                         <label for="yes">Yes</label>
//                         <input
//                           type="radio"
//                           id="no"
//                           name="IsRigister"
//                           value="no"
//                         />
//                         <label for="no">No</label>
//                       </div>
//                       <div className="mb-3">
//                         <label>IsPromotion</label>
//                         <br />
//                         <input
//                           type="radio"
//                           id="yes"
//                           name="IsPromotion"
//                           value="yes"
//                         />
//                         <label for="yes">Yes</label>
//                         <input
//                           type="radio"
//                           id="no"
//                           name="IsPromotion"
//                           value="no"
//                         />
//                         <label for="no">No</label>
//                       </div>
//                       <div className="mb-3">
//                         <label>Is Flyn from Difrent</label>
//                         <br />
//                         <input
//                           type="radio"
//                           id="yes"
//                           name="Difrent"
//                           value="yes"
//                         />
//                         <label for="yes">Yes</label>
//                         <input type="radio" id="no" name="Difrent" value="no" />
//                         <label for="no">No</label>
//                       </div>
//                       {/* <div className="mb-3">
//                         <label>Car Clean?</label>
//                         <br />
//                         <input
//                           type="radio"
//                           id="yes"
//                           name="option"
//                           value="yes"
//                         />
//                         <label for="yes">Yes</label>
//                         <input type="radio" id="no" name="option" value="no" />
//                         <label for="no">No</label>
//                       </div> */}

//                       {/* <div className="mb-3">
//                         <label>E Charge?</label>
//                         <br />
//                         <input
//                           type="radio"
//                           id="yes"
//                           name="option"
//                           value="yes"
//                         />
//                         <label for="yes">Yes</label>
//                         <input type="radio" id="no" name="option" value="no" />
//                         <label for="no">No</label>
//                       </div> */}

//                       <div className="mb-3">
//                         <label>Services</label>
//                         <br />
//                         <input
//                           type="radio"
//                           id="yes"
//                           name="option"
//                           value="yes"
//                         />
//                         <label for="yes">Yes</label>
//                         <input type="radio" id="no" name="option" value="no" />
//                         <label for="no">No</label>
//                       </div>

//                       <div className="mb-3">
//                         <label for="txtMakeType" className="form-label">
//                           Airport Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           placeholder="Airport Name"
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label>DifferentFromAirport?</label>
//                         <br />
//                         <input
//                           type="radio"
//                           id="yes"
//                           name="option"
//                           value="yes"
//                         />
//                         <label for="yes">Yes</label>
//                         <input type="radio" id="no" name="option" value="no" />
//                         <label for="no">No</label>
//                       </div>
//                     </form>
//                   </div>
//                   <div className="modal-footer">
//                     <button
//                       type="button"
//                       className="cancel-btn"
//                       data-bs-dismiss="modal"
//                     >
//                       Close
//                     </button>
//                     <button type="button" className="btn site-btn">
//                       Save changes
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="p-2">
//               <button
//                 type="button"
//                 className="btn btn-light details-btnn me-2 me-2"
//                 data-bs-toggle="modal"
//                 data-bs-target="#BookingModal"
//               >
//                 <span
//                   data-bs-toggle="tooltip"
//                   data-bs-placement="top"
//                   data-bs-title="Booking"
//                 >
//                   <i
//                     className="fa-regular fa-calendar-check"
//                     title="Calender"
//                   ></i>
//                 </span>
//               </button>

//               <div
//                 className="modal fade"
//                 id="BookingModal"
//                 tabindex="-1"
//                 aria-labelledby="DeleteModalLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog modal-dialog-scrollable">
//                   <div className="modal-content">
//                     <div className="modal-header">
//                       <h1 className="modal-title fs-5" id="DeleteModalLabel">
//                         Confirmation
//                       </h1>
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       ></button>
//                     </div>

//                     <div className="modal-body">
//                       <div className="mb-3">
//                         <label className="fs-5">Do you want to Booking?</label>
//                         <br />
//                       </div>

//                       <div className="form-check">
//                         <input
//                           className="form-check-input fs-7"
//                           type="checkbox"
//                           value=""
//                           id="flexCheckDefault"
//                         />
//                         <label
//                           className="form-check-label fs-7"
//                           for="flexCheckDefault"
//                         >
//                           Never ask again
//                         </label>
//                       </div>
//                     </div>

//                     <div className="modal-footer">
//                       <button
//                         type="button"
//                         className="cancel-btn"
//                         data-bs-dismiss="modal"
//                       >
//                         No
//                       </button>
//                       <button type="button" className="site-btn">
//                         Yes
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="p-2">
//               <button
//                 type="button"
//                 className="btn btn-light details-btnn me-2 me-2"
//                 data-bs-toggle="modal"
//                 data-bs-target="#EditModal"
//               >
//                 <span
//                   data-bs-toggle="tooltip"
//                   data-bs-placement="top"
//                   data-bs-title="Edit"
//                 >
//                   <i className="fa-solid fa-pencil" title="Edit"></i>
//                 </span>
//               </button>

//               {/* <div
//                 className="modal fade"
//                 id="EditModal"
//                 tabindex="-1"
//                 aria-labelledby="EditModalLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog modal-dialog-scrollable">
//                   <div className="modal-content">
//                     <div className="modal-header">
//                       <h1 className="modal-title fs-5" id="EditModalLabel">
//                         Do you want to Edit?
//                       </h1>
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       ></button>
//                     </div>
//                     <div className="modal-body">
//                       <form>
//                         <div className="mb-3">
//                           <label for="txtMake" className="form-label">
//                             Full Name
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Full Name"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMake" className="form-label">
//                             Phone Number
//                           </label>
//                           <input
//                             type="tel"
//                             className="form-control"
//                             placeholder="Phone Number"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMake" className="form-label">
//                             Customer Email
//                           </label>
//                           <input
//                             type="email"
//                             className="form-control"
//                             placeholder="Customer Email"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMake" className="form-label">
//                             Quote Code
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Quote Code"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMake" className="form-label">
//                             Start Date Time
//                           </label>
//                           <input
//                             type="datetime-local"
//                             className="form-control"
//                             placeholder="Start Date Time"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             End Date Time
//                           </label>
//                           <input
//                             type="datetime-local"
//                             className="form-control"
//                             placeholder="End Date Time"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             Total Cost
//                           </label>
//                           <input
//                             type="number"
//                             className="form-control"
//                             placeholder="Total Cost"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             Airport Name
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Airport Name"
//                           />
//                         </div>

//                         <select
//                           className="form-select"
//                           aria-label="Default select example"
//                         >
//                           <option selected>Flying From</option>
//                           <option value="1">Afghanistan</option>
//                           <option value="2">Albania</option>
//                           <option value="3">Pakistan</option>
//                         </select>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             {' '}
//                             Inbound Flight Number
//                           </label>
//                           <input
//                             type="number"
//                             className="form-control"
//                             placeholder="Inbound Flight Number"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             Outbound Flight Number{' '}
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Outbound Flight Number"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             {' '}
//                             Outbound VehicleModel ID
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Outbound VehicleModel ID"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             Inbound VehicleModel ID
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Inbound VehicleModel ID"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label>Car Clean?</label>
//                           <br />
//                           <input
//                             type="radio"
//                             id="yes"
//                             name="option"
//                             value="yes"
//                           />
//                           <label for="yes">Yes</label>
//                           <input
//                             type="radio"
//                             id="no"
//                             name="option"
//                             value="no"
//                           />
//                           <label for="no">No</label>
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             Location
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Location"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label>E Charge?</label>
//                           <br />
//                           <input
//                             type="radio"
//                             id="yes"
//                             name="option"
//                             value="yes"
//                           />
//                           <label for="yes">Yes</label>
//                           <input
//                             type="radio"
//                             id="no"
//                             name="option"
//                             value="no"
//                           />
//                           <label for="no">No</label>
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             Campany Name
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Campany Name"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             Source
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Source"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             FlightTo{' '}
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="FlightTo"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             InBoundFlight
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="InBoundFlight"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label for="txtMakeType" className="form-label">
//                             OutBoundFlight
//                           </label>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="OutBoundFlight"
//                           />
//                         </div>

//                         <div className="mb-3">
//                           <label>DifferentFromAirport?</label>
//                           <br />
//                           <input
//                             type="radio"
//                             id="yes"
//                             name="option"
//                             value="yes"
//                           />
//                           <label for="yes">Yes</label>
//                           <input
//                             type="radio"
//                             id="no"
//                             name="option"
//                             value="no"
//                           />
//                           <label for="no">No</label>
//                         </div>
//                       </form>
//                     </div>

//                     <div className="modal-footer">
//                       <button
//                         type="button"
//                         className="cancel-btn"
//                         data-bs-dismiss="modal"
//                       >
//                         Close
//                       </button>
//                       <button type="button" className="site-btn">
//                         Update changes
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div> */}
//             </div>
//             <div className="p-2">
//               <button
//                 type="button"
//                 className="btn btn-light details-btnn me-2 me-2"
//                 data-bs-toggle="modal"
//                 data-bs-target="#DetailsModal"
//               >
//                 <span
//                   data-bs-toggle="tooltip"
//                   data-bs-placement="top"
//                   data-bs-title="Details"
//                 >
//                   <i className="fa-solid fa-clipboard" title="Details"></i>
//                 </span>
//               </button>

//               <div
//                 className="modal fade"
//                 id="DetailsModal"
//                 tabindex="-1"
//                 aria-labelledby="DetailsModalLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog modal-dialog-scrollable">
//                   <div className="modal-content">
//                     <div className="modal-header">
//                       <h1 className="modal-title fs-5" id="DetailsModalLabel">
//                         Personal Details
//                       </h1>
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       ></button>
//                     </div>

//                     <div className="modal-body">
//                       <div className="personal-dtails mb-2 d-flex">
//                         <h6 className="mb-0">Full Name:</h6>
//                         <p className="ms-2 mb-0">Anna Adame</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Phone Number:</h6>
//                         <div className="ms-2 mb-0">
//                           <p className="ms-2 mb-0">+923188233285</p>
//                         </div>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Customer Email:</h6>
//                         <p className="ms-2 mb-0">zoe7@gmail.com</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Quote Code:</h6>
//                         <p className="ms-2 mb-0">3456</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Start Date Time:</h6>
//                         <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">End Date Time:</h6>
//                         <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Total Cost:</h6>
//                         <p className="ms-2 mb-0">4.5 $</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Airport Name:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Flying From:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Flying To:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Inbound Flight Number:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Outbound Flight Number:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Inbound VehicleModel ID:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Outbound VehicleModel ID:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Inbound VehicleModel:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Outbound VehicleModel:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Location:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Car Clean:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> E-Charge:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Campany Name:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Source:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>
//                     </div>

//                     <div className="modal-footer">
//                       <button type="button" className="site-btn invisible">
//                         {' '}
//                         Update changes{' '}
//                       </button>
//                       <button
//                         type="button"
//                         className="cancel-btn"
//                         data-bs-dismiss="modal"
//                       >
//                         Close
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="p-2">
//               <button
//                 type="button"
//                 className="btn btn-light details-btnn me-2 me-2"
//                 data-bs-toggle="modal"
//                 data-bs-target="#DetailsCard"
//               >
//                 <span
//                   data-bs-toggle="tooltip"
//                   data-bs-placement="top"
//                   data-bs-title="Details"
//                 >
//                   {/* <i className="fa-solid fa-clipboard"></i> */}
//                   <i className="fa-solid fa-credit-card" title="Pay Now"></i>
//                 </span>
//               </button>

//               <div
//                 className="modal fade"
//                 id="DetailsCard"
//                 tabindex="-1"
//                 aria-labelledby="CardDetailsModalLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog modal-dialog-scrollable">
//                   <div className="modal-content">
//                     <div className="modal-header">
//                       <h1 className="modal-title fs-5" id="DetailsModalLabel">
//                         Card Details
//                       </h1>
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       ></button>
//                     </div>

//                     <div className="modal-body">
//                       <div className="personal-dtails mb-2 d-flex">
//                         <h6 className="mb-0">Full Name:</h6>
//                         <p className="ms-2 mb-0">Anna Adame</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Phone Number:</h6>
//                         <div className="ms-2 mb-0">
//                           <p className="ms-2 mb-0">+923188233285</p>
//                         </div>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Customer Email:</h6>
//                         <p className="ms-2 mb-0">zoe7@gmail.com</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Quote Code:</h6>
//                         <p className="ms-2 mb-0">3456</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Start Date Time:</h6>
//                         <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">End Date Time:</h6>
//                         <p className="ms-2 mb-0">L12/4/14 T 56:89</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Total Cost:</h6>
//                         <p className="ms-2 mb-0">4.5 $</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Airport Name:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Flying From:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Flying To:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Inbound Flight Number:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Outbound Flight Number:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Inbound VehicleModel ID:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Outbound VehicleModel ID:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Inbound VehicleModel:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Outbound VehicleModel:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0">Location:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Car Clean:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> E-Charge:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Campany Name:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>

//                       <div className="personal-dtails  mb-2 d-flex">
//                         <h6 className="mb-0"> Source:</h6>
//                         <p className="ms-2 mb-0">ABC</p>
//                       </div>
//                     </div>

//                     <div className="modal-footer">
//                       <button type="button" className="site-btn invisible">
//                         {' '}
//                         Update changes{' '}
//                       </button>
//                       <button
//                         type="button"
//                         className="cancel-btn"
//                         data-bs-dismiss="modal"
//                       >
//                         Close
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="p-2">
//               <button
//                 type="button"
//                 className="btn btn-light details-btnn me-2 me-2"
//                 data-bs-toggle="modal"
//                 data-bs-target="#DeleteModal"
//               >
//                 <span
//                   data-bs-toggle="tooltip"
//                   data-bs-placement="top"
//                   data-bs-title="Delete"
//                 >
//                   <i className="fa-solid fa-trash" title="Delete"></i>
//                 </span>
//               </button>

//               <div
//                 className="modal fade"
//                 id="DeleteModal"
//                 tabindex="-1"
//                 aria-labelledby="DeleteModalLabel"
//                 aria-hidden="true"
//               >
//                 <div className="modal-dialog modal-dialog-scrollable">
//                   <div className="modal-content">
//                     <div className="modal-header">
//                       <h1 className="modal-title fs-5" id="DeleteModalLabel">
//                         Confirmation
//                       </h1>
//                       <button
//                         type="button"
//                         className="btn-close"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       ></button>
//                     </div>

//                     <div className="modal-body">
//                       <div className="mb-3">
//                         <label className="fs-5">Do you want to Delete? </label>
//                         <br />
//                       </div>

//                       <div className="form-check">
//                         <input
//                           className="form-check-input fs-7"
//                           type="checkbox"
//                           value=""
//                           id="flexCheckDefault"
//                         />
//                         <label
//                           className="form-check-label fs-7"
//                           for="flexCheckDefault"
//                         >
//                           Never ask again
//                         </label>
//                       </div>
//                     </div>

//                     <div className="modal-footer">
//                       <button type="button" className="site-btn invisible">
//                         Update changes
//                       </button>
//                       <button
//                         type="button"
//                         className="cancel-btn"
//                         data-bs-dismiss="modal"
//                       >
//                         Yes
//                       </button>
//                       <button
//                         type="button"
//                         className="cancel-btn"
//                         data-bs-dismiss="modal"
//                       >
//                         No
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="p-2">
//               <div className="toggal-button-box">
//                 <label className="switchh">
//                   <input type="checkbox" />
//                   <span className="sliderr round"></span>
//                 </label>
//               </div>
//             </div>
//             <div className="p-2">
//               <div className="col-lg-3 col-md-6">
//                 <div className="input-box mb-3">
//                   <input
//                     type="search"
//                     className="form-control search-bar"
//                     placeholder="Search..."
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="p-2">
//               <div className="col-lg-5 col-md-3 ">
//                 <div className="right mb-3 d-flex justify-content-between align-items-center col-md-12">
//                   <label className="mx-1">From: </label>
//                   <input
//                     type="date"
//                     className="form-control col-md-5"
//                     id="exampleFormControlInput1"
//                     placeholder="date"
//                   />
//                   <label className="mx-1">To: </label>
//                   <input
//                     type="date"
//                     className="form-control col-md-5"
//                     id="exampleFormControlInput2"
//                     placeholder="date"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//           </div>

// <div className="table-responsive">
//   <table className="table">
//     <thead>
//       <tr>
//         <th scope="col" className="font">Sr No.</th>
//         <th scope="col">Model Name</th>
//         <th scope="col">Description</th>
//         <th scope="col">Created On</th>
//         <th scope="col">Actions</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <td>1</td>
//         <td className="table-btn" data-bs-toggle="modal" data-bs-target="#vehicle-ModelmodalModel" >Honda City</td>
//         <td>Honda City is the car</td>
//         <td>2024-06-10</td>
//         <td>
//           <button type="button" className="details-btn" data-bs-toggle="tooltip" data-bs-placement="top"
//             data-bs-title="Edit">
//             <i className="fa-solid fa-pencil" title='Edit'></i>
//           </button>

//           <button type="button" className="details-btn" data-bs-toggle="tooltip" data-bs-placement="top"
//             data-bs-title="Delete">
//             <i className="fa-solid fa-trash" title='Delete'></i>
//           </button>
//         </td>
//       </tr>

//     </tbody>
//   </table>
// </div>

// <nav aria-label="Page navigation example">
//   <ul className="pagination justify-content-end me-1">
//     <li className="page-item disabled">
//       <a className="page-link">Previous</a>
//     </li>
//     <li className="page-item"><a className="page-link" href="#">1</a></li>
//     <li className="page-item"><a className="page-link" href="#">2</a></li>
//     <li className="page-item"><a className="page-link" href="#">3</a></li>
//     <li className="page-item">
//       <a className="page-link" href="#">Next</a>
//     </li>
//   </ul>
// </nav>

// </div>
//     </>
//   )
// }

// export default VehicleModel

import { useEffect, useState } from "react";
import useFetch from "../../../CustomHooks/useFetch";
import { API_BASE_URL } from "../../../Config/Config";
import { verifyToken } from "../../..";
import CustomLoading from "../../../Components/CustomHandling/LoadingAndError";
import { Slide, toast } from "react-toastify";
import PageHeaderAndFooter from "../../../Components/PageHeaderAndFooter/PageHeaderAndFooter";
import { Link } from "react-router-dom";

const VehicleModels = () => {
  const showToast = (message, type = "info", options = {}) => {
    const baseOptions = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: "light",
      transition: Slide,
      ...options,
    };

    switch (type) {
      case "success":
        toast.success(message, baseOptions);
        break;
      case "warn":
        toast.warn(message, baseOptions);
        break;
      case "error":
        toast.error(message, baseOptions);
        break;
      case "info":
      default:
        toast.info(message, baseOptions);
        break;
    }
  };

  const vehicleModelsURL = `${API_BASE_URL}/api/Vehicle/GetAllModels`;
  const rolesURL = `${API_BASE_URL}/api/UserRoles/GetAllRoles`;
  const [token, setToken] = useState("");

  const [userData, setUserData] = useState({
    vehicleModelName: "",
    userId: null,
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    // promotion: '',
    roleId: null,
  });
  // const [roles, setRoles] = useState([])
  const [refreshDependency, setRefreshDependency] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createState, setCreateState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [blockState, setBlockState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  // Fetch token on mount
  useEffect(() => {
    const fetchToken = async () => {
      const authToken = await verifyToken();
      setToken(authToken);
    };
    fetchToken();
  }, []);

  const {
    data: vehicleModels,
    loading,
    error,
  } = useFetch(vehicleModelsURL, refreshDependency);

  const { data: roles } = useFetch(rolesURL, refreshDependency);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleValidateUser = () => {
    if (userData.password === userData.confirmPassword) {
      return true;
    } else {
      showToast(`Password does not match`, "error");
      return false;
    }
  };
  const handleCreateOrEditVehicleModel = async (e) => {
    e.preventDefault();

    const newVehicleModel = {
      usersID: userData.userId || null,
      userName: userData.vehicleModelName || null,
      password: editState ? "dummydata1234" : userData.password,
      firstName: userData.firstName || null,
      lastName: userData.lastName || null,
      email: userData.email || null,
      phoneNo: userData.phoneNo || null,
      userTypeID: 0,
      companyName: userData.companyName || null,
      promotion: true,
      createdDate: null,
      createdBy: null,
      modifiedDate: null,
      modifiedBy: null,
      deletedDate: null,
      deletedBy: null,
      discountCode: null,
      roleId: userData.roleId || null,
    };

    const url = `${API_BASE_URL}/api/user/CreateUser`;
    if (editState || handleValidateUser()) {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(newVehicleModel),
        });

        const result = await response.json();

        if (response.ok) {
          showToast(
            createState
              ? "User successfully created."
              : "User successfully updated.",
            "success"
          );

          // Reset states and refresh
          setRefreshDependency((prev) => prev + 1);
          setIsModalOpen(false);
          setCreateState(false);
          setEditState(false);
        } else {
          // Warning toasts for errors
          Object.keys(result.errors).forEach((key) => {
            result.errors[key].forEach((message) => {
              showToast(`${key}: ${message}`, "warn");
            });
          });
        }
      } catch (error) {
        // Error toast for network issues or unexpected errors
        showToast(`${error.message}`, "error");
      }
    }
  };

  const handleUpdateVehicleModel = async (e) => {
    e.preventDefault();
    const url = `${API_BASE_URL}/api/user/UpdateUserStatus`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          userID: userData.userId,
          isActive: null,
          isDeleted: deleteState ? true : null,
          isBlocked: blockState ? true : null,
        }),
      });

      const updateVehicleModel = await response.json();
      console.log(updateVehicleModel);
      if (response.ok) {
        console.log(updateVehicleModel);

        const action = deleteState ? "deleted" : "blocked";
        showToast(`User '${userData.firstName}' ${action}`, "success");
        setRefreshDependency((prev) => prev + 1);
        setIsModalOpen(false);
        setDeleteState(false);
        setBlockState(false);
        setExpandedRows([]);
      } else {
        showToast("Failed to Delete User.", "warn");
      }
    } catch (error) {
      showToast(`${error.message}`, "error");
    }
  };
  // console.log(vehicleModels)

  // const handleOpenModal = (actionType, userID) => {
  //   const selectedVehicleModel = vehicleModels.find((mod) => mod.userID === userID)
  //   console.log(selectedVehicleModel)

  //   const resetUserData = () => ({
  //     vehicleModelName: '',
  //     userId: null,
  //     password: '',
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     phoneNo: '',
  //     companyName: '',
  //     roleId: null,
  //   })

  //   const populateUserData = (user) => ({
  //     vehicleModelName: user.userName,
  //     userId: user.userID,
  //     password: '',
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     email: user.email,
  //     phoneNo: user.phoneNo.trim(),
  //     companyName: user.companyName,
  //     roleId: user.roleID,
  //   })

  //   // Reset modal states before setting new ones
  //   setCreateState(false)
  //   setEditState(false)
  //   setDeleteState(false)
  //   setIsModalOpen(false)

  //   switch (actionType) {
  //     case 'create':
  //       setUserData(resetUserData())
  //       setCreateState(true)
  //       setIsModalOpen(true)
  //       break

  //     case 'edit':
  //       if (selectedVehicleModel) {
  //         setUserData(populateUserData(selectedVehicleModel))
  //         setEditState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for editing')
  //       }
  //       break

  //     case 'block':
  //       if (selectedVehicleModel) {
  //         setUserData(populateUserData(selectedVehicleModel))
  //         setBlockState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for editing')
  //       }
  //       break

  //     case 'delete':
  //       if (selectedVehicleModel) {
  //         setUserData(populateUserData(selectedVehicleModel))
  //         setDeleteState(true)
  //         setIsModalOpen(true)
  //       } else {
  //         console.error('User not found for deletion')
  //       }
  //       break

  //     default:
  //       console.error('Invalid action type')
  //       setCreateState(false)
  //       setEditState(false)
  //       setDeleteState(false)
  //       setIsModalOpen(false)
  //       break
  //   }
  // }
  const handleOpenModal = (actionType, userID) => {
    const selectedVehicleModel = vehicleModels.find(
      (mod) => mod.userID === userID
    );

    const resetUserData = () => ({
      vehicleModelName: "",
      userId: null,
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      companyName: "",
      roleId: null,
    });

    const populateUserData = (user) => ({
      vehicleModelName: user.userName,
      userId: user.userID,
      password: "",
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNo: user.phoneNo.trim(),
      companyName: user.companyName,
      roleId: user.roleID,
    });

    // Reset modal states
    setCreateState(false);
    setEditState(false);
    setBlockState(false);
    setDeleteState(false);
    setIsModalOpen(false);

    const handleAction = (stateSetter) => {
      if (selectedVehicleModel) {
        setUserData(populateUserData(selectedVehicleModel));
        stateSetter(true);
        setIsModalOpen(true);
      }
    };

    switch (actionType) {
      case "create":
        setUserData(resetUserData());
        setCreateState(true);
        setIsModalOpen(true);
        break;

      case "edit":
        handleAction(setEditState);
        break;

      case "block":
        handleAction(setBlockState);
        break;

      case "delete":
        handleAction(setDeleteState);
        break;

      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCreateState(false);
    setEditState(false);
    setBlockState(false);
    setDeleteState(false);
  };

  const handleRowClick = (index) => {
    setExpandedRows((prevRows) => (prevRows.includes(index) ? [] : [index]));
  };

  if (loading) return <CustomLoading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="section-1">
      <PageHeaderAndFooter
        btn_text="Add New VehicleModel"
        handleOpenModal={() => handleOpenModal("create", null)}
        data={vehicleModels}
        filterFields={["vehicleModelName", "companyName", "userName"]}
        itemsPerPage={15}
        renderContent={(paginatedData, startIndex) => (
          <div className="table-responsive table-scroll">
            <table className="table table-hover table-bordered table-sm align-middle table_main">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">VehicleModel Name</th>
                  <th scope="col">VehicleModel Type</th>
                  <th scope="col">VehicleModel Description</th>
                  <th scope="col">Created Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <>
                    <tr
                      key={data.userID}
                      className={`${
                        expandedRows.includes(index) ? "table-info" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(index)}
                    >
                      <td>{startIndex + index + 1}</td>
                      <td>{data.vehicleModelName || "N/A"}</td>
                      <td>{data.vehicleModelType || "N/A"}</td>
                      <td>{data.vehicleModelDescription || "N/A"}</td>
                      <td>{data.createdDate || "N/A"}</td>
                    </tr>
                    {expandedRows.includes(index) && (
                      <tr className="bg-light">
                        <td colSpan="9">
                          <div className="row g-2 p-3">
                            <div className="col-md-6">
                              <strong>VehicleModel Code: </strong>{" "}
                              {data.vehicleModelCode || "N/A"}
                            </div>
                            <div className="col-md-6">
                              <strong>VehicleModel Cost: </strong>
                              {data.vehicleModelCost || "N/A"}
                            </div>{" "}
                            <div className="col-md-6"></div>
                            <td className="d-flex gap-2">
                              <button
                                className="btn btn_modal"
                                onClick={() =>
                                  handleOpenModal("edit", data.userID)
                                }
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn_modal_sec"
                                onClick={() =>
                                  handleOpenModal("delete", data.userID)
                                }
                              >
                                Delete
                              </button>
                              <button
                                className="btn btn_modal_sec "
                                onClick={() =>
                                  handleOpenModal("block", data.userID)
                                }
                              >
                                Block
                              </button>
                            </td>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />

      {/* Modal */}
      {isModalOpen && (
        <>
          {/* Modal Overlay (Backdrop) */}
          <div
            className={`modal fade ${isModalOpen ? "show" : ""}`}
            tabIndex="-1"
            style={{
              display: isModalOpen ? "block" : "none",
              background: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={handleCloseModal}
          >
            <div
              className="modal-dialog modal-dialog-start"
              onClick={(e) => e.stopPropagation()}
            >
              <form
                onSubmit={
                  createState || editState
                    ? handleCreateOrEditVehicleModel
                    : deleteState || blockState
                    ? handleUpdateVehicleModel
                    : ""
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {/* {userID ? 'Update vehicleModel name' : 'Create vehicleModel name'} */}
                      {createState
                        ? "Create"
                        : editState
                        ? "Update"
                        : deleteState
                        ? "Delete"
                        : blockState
                        ? "Block"
                        : ""}{" "}
                      User
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCloseModal}
                    />
                  </div>
                  <div className="modal-body row">
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="vehicleModelName" className="form-label">
                        User Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="vehicleModelName"
                        value={userData.vehicleModelName}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        required
                        disabled={editState || deleteState || blockState}
                      />
                    </div>

                    {createState ? (
                      <>
                        <div className="mb-3 col-lg-6">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={userData.password}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>

                        <div className="mb-3 col-lg-6">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleChange}
                            required
                            disabled={deleteState || blockState}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={userData.firstName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={userData.lastName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="phoneNo" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNo"
                        value={userData.phoneNo}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label htmlFor="roleId" className="form-label">
                        User Types
                      </label>
                      <select
                        className="form-control"
                        name="roleId"
                        value={userData.roleId || ""}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      >
                        <option value="" disabled>
                          Select User Type
                        </option>
                        {roles.map((role) => (
                          <option key={role.roleId} value={role.roleId}>
                            {role.roleName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label htmlFor="companyName" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        value={userData.companyName}
                        onChange={handleChange}
                        required
                        disabled={deleteState || blockState}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className={
                        createState
                          ? "btn btn_modal_sec"
                          : editState
                          ? "btn btn_modal_sec"
                          : blockState || deleteState
                          ? "btn btn_modal"
                          : ""
                      }
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      className={
                        createState
                          ? "btn btn_modal"
                          : editState
                          ? "btn btn_modal"
                          : blockState || deleteState
                          ? "btn btn_modal_sec"
                          : ""
                      }
                      type="submit"
                    >
                      {createState
                        ? "Create"
                        : editState
                        ? "Update"
                        : blockState
                        ? "Block"
                        : deleteState
                        ? "Delete"
                        : ""}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VehicleModels;
